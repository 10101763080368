<template>
  <div class="mc-main">
    <div class="public_background"></div>
    <div class="mc-sub">

      <!-- 头部 -->
      <div class="mc-sub-header">
        <UserInfoHearder title="消息中心"></UserInfoHearder>
      </div>

      <div class="mc-sub-body">
        <div class="mc-sub-body-inner">

          <div style="margin-top: 1vw;margin-bottom: 1vw;">

            <span style="margin-right: 2vw;">
              状态：
              <el-select v-model="messageData.is_read" placeholder="请选择" @change="readChange">
                <el-option v-for="item in messageData.readOption" :key="item.value" :label="item.label"
                  :value="item.value">
                </el-option>
              </el-select>
            </span>

            <span>
              类型：
              <el-select v-model="messageData.type" placeholder="请选择"  @change="typeChange">
                <el-option v-for="item in messageData.typeOption" :key="item.value" :label="item.label"
                  :value="item.value">
                </el-option>
              </el-select>
            </span>

          </div>

          <div>
            <el-table :data="messageData.list" style="width: 100%">

              <el-table-column prop="content" label="消息" show-overflow-tooltip>
              </el-table-column>

              <el-table-column label="类型" width="180" align="center" show-overflow-tooltip>
                <template #default="scope">
                  <div>
                    {{ $basic.messageType[scope.row.message_type] }}
                  </div>
                </template>
              </el-table-column>
              <el-table-column label="日期" width="180" align="center">
                <template #default="scope">
                  <div>
                    {{ $basic.conversionTime(new Date(scope.row.time)) }}
                  </div>
                </template>
              </el-table-column>
              <el-table-column label="状态" width="180" align="centers">
                <template #default="scope">
                  <div :class="scope.row.is_read==true?'read':'unread'">
                    {{ $basic.readType[scope.row.is_read] }}
                  </div>
                </template>
              </el-table-column>
              <!-- <el-table-column label="时间" width="180">
              <template #default="scope">
                <el-popover effect="light" trigger="hover" placement="top">
                  <template #default>
                    <p>姓名: {{ scope.row.name }}</p>
                    <p>住址: {{ scope.row.address }}</p>
                  </template>
                  <template #reference>
                    <div class="name-wrapper">
                      <el-tag size="medium">{{ $basic.conversionTime(new Date(scope.row.time)) }}</el-tag>
                    </div>
                  </template>
                </el-popover>
              </template>
            </el-table-column> -->
              <el-table-column label="" align="right">
                <template #default="scope">
                  <!-- <el-button size="mini" @click="setRead(scope.row)" v-show="scope.row.is_read==false">标为已读</el-button> -->
                  <div @click="setRead(scope.row)" style="cursor: pointer;color: #409EFF;" v-show="scope.row.is_read==false">
                    标为已读
                  </div>

                  <!-- <el-button size="mini" type="danger" @click="handleDelete(scope.$index, scope.row)">删除</el-button> -->
                </template>
              </el-table-column>
            </el-table>
          </div>

          <div style="text-align: center;margin-top: 2vw;height: 3vw;">
            <el-pagination class="my-page" :total="messageData.allCount" @current-change="pageChange"
              v-model:current-page="messagePage" layout="prev, pager, next">
            </el-pagination>
          </div>

        </div>

      </div>

    </div>




  </div>

</template>

<script setup>
  import UserInfoHearder from '../../components/public_modular/UserInfoHearder.vue'
  // import VuePictureCropper, { cropper } from 'vue-picture-cropper'
  // import { VuePictureCropper, cropper } from 'vue-picture-cropper'


  // ref方法
  // reactive可传对象数组
  import {
    reactive,
    ref,
    getCurrentInstance,
    onMounted,
    watch,
  } from 'vue'



  // router、接口引入
  const {
    proxy
  } = getCurrentInstance()


  onMounted(() => {
    getMessageList()
  })

  const messageData = reactive({
    list: [],
    offset: 0,
    getCount: 10,
    page: 1,
    type: 'all',
    typeArr:['activity','warning_user','warning_device','notify_device','notify_device_group'],
    is_read: 'all',
    allCount: 0,

    readOption: [{
        value: 'all',
        label: '全部'
      },
      {
        value: 'false',
        label: '未读'
      },
      {
        value: 'true',
        label: '已读'
      },
    ],

    typeOption: [
      {
        value: 'all',
        label: '全部'
      },
      {
        value: 'activity',
        label: '活动信息'
      },
      {
        value: 'warning_user',
        label: '用户报警'
      },
      {
        value: 'warning_device',
        label: '设备报警'
      },
      {
        value: 'notify_device',
        label: '设备通知'
      },
      {
        value: 'notify_device_group',
        label: '设备组通知'
      },
    ]

  })

  function typeChange()
  {
    messageData.offset = 0
    messageData.allCount = 0
    messageData.page = 1
    getMessageList()
  }

  function readChange()
  {
    messageData.offset = 0
    messageData.allCount = 0
    messageData.page = 1
    getMessageList()
  }

  function pageChange(val) {
    messageData.page = val;
    messageData.offset = (val - 1) * messageData.getCount;
    getMessageList();
  }

  function getMessageList() {
    proxy.$instance.request_private.get('/mqttapi/v2/user/message/message_list', {
      params: {
        type: messageData.type,
        offset: messageData.offset,
        count: messageData.getCount,
        is_read: messageData.is_read,
      }
    }).then(res => {
      if (res.data.code == 1) {
        let data = proxy.$basic.dataDecode(res.data.data)
        messageData.list = data.message_list
        messageData.allCount = data.total_message
        // data.message_list.forEach(item=>{
        //   console.log('time',proxy.$basic.conversionTime(new Date(item.time)))
        // })
      } else {
        proxy.$message.error('消息列表获取失败|' + res.data.code)
      }
    })
  }

  function setRead(row) {
    proxy.$instance.request_private.post('/mqttapi/v2/user/message/read', {
      message_id: row.id
    }).then(res => {
      if (res.data.code == 1) {
        getMessageList()
      } else {
        proxy.$message.error(proxy.$errorCode[res.data.code])
      }
    })
  }


  // ----------------------------------------------------------------------------------------
</script>

<style scoped>
  .mc-main {
    position: absolute;
    /* background-color: rgba(240, 242, 251, 1); */
    /* background-image: url(../../assets/image/login/background.png); */
    background-size: 100% 100%;
    background-repeat: no-repeat;
    background-position: center;
    width: 100%;
    min-height: 100vh;
  }

  .mc-sub {
    background-color: rgba(235, 240, 249, 1);
    box-shadow: 0 0.1vw 0.2vw rgba(0, 0, 0, 0.24), 0 0 0.3vw rgba(0, 0, 0, 0.08);
    position: relative;
    width: 94vw;
    height: auto;
    /* height: 43vw; */
    min-height: 43vw;
    margin-left: 3vw;
    margin-top: 3vw;
    padding-bottom: 1vw;
    border-radius: 0.9vw;
  }

  .mc-sub-header {
    position: relative;
    /* left: 6vw; */
    /* width: 88vw; */
    width: 94vw;
    height: 5vw;
    /* background-color: #333333; */
    /* border-radius: 0 0.9vw 0 0; */
  }

  .mc-sub-body {
    position: relative;
    width: 92vw;
    /* height: 36vw; */
    min-height: 37vw;
    margin-left: 1vw;
    margin-top: 1vw;
    /* left: 1vw; */
    /* top: 6vw; */
    background-color: rgba(255, 255, 255, 1);
    border-radius: 0.7vw;
    display: flex;
    justify-content: center;
    align-items: center;

  }

  .mc-sub-body-inner
  {
    width: 90vw;
    min-height: 35vw;
  }

</style>
