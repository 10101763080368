import $store from '../../store/index.js'
import instance from './http.js'
import router from '../../router/index.js'
import mqttGlobalData from './mqttGlobalData.js'

import {
  Base64
} from 'js-base64'
import {
  pathToBase64,
  base64ToPath
} from './imageTools.js'
import {
  ElMessage
} from 'element-plus'


const $base64 = Base64
const $mqttGlobalData = mqttGlobalData


const config_js = {
  countDown: 121,
}

// 权级类型
const permissionType = {
  'owner': '所有者',
  'admin': '管理员',
  'user': '普通用户',
}

// 消息类型
const messageType = {
  'all': '全部',
  'activity': '活动信息',
  'warning_user': '用户报警',
  'warning_device': '设备报警',
  'notify_device': '设备通知',
  'notify_device_group': '设备组消息'
}

// 阅读状态
const readType = {
  'all': '全部',
  'false': '未读',
  'true': '已读',
}

const wxType = {
  0: '未绑定',
  1: '已绑定'
}

const saType = {
  0: '未绑定微信',
  1: '未关注', //已绑定微信未关注公众号
  2: '已关注', //已绑定微信并关注公众号
}

// data数据解码
export function dataDecode(data) {
  // const decodeData = JSON.parse($base64.decode(data))
  // return decodeData
  return data
}

// data数据编码
export function dataEncode(data) {
  // const encodeData = $base64.encode(JSON.stringify(data))
  // return encodeData
  return data
}

// 图片解码
export function imgDecode(base64data) {
  return new Promise((resolve, reject) => {
    base64ToPath(base64data)
      .then(path => {
        resolve(path)
      })
      .catch(error => {
        reject(error)
      })
  })
}

// 图片编码
export function imgEncode(path) {
  return new Promise((resolve, reject) => {
    pathToBase64(path)
      .then(base64 => {
        resolve(base64)
      })
      .catch(error => {
        reject(error)
      })
  })
}

// 获取时间
export function conversionTime(date) {
  var y = date.getFullYear().toString(),
    m = (date.getMonth() + 1).toString(),
    d = date.getDate().toString(),
    H = date.getHours().toString(),
    M = date.getMinutes().toString(),
    S = date.getSeconds().toString();
  m = m > 9 ? m : '0' + m;
  d = d > 9 ? d : '0' + d;
  H = H > 9 ? H : '0' + H;
  M = M > 9 ? M : "0" + M; //如果分钟小于10,则在前面加0补充为两位数字
  S = S > 9 ? S : "0" + S
  date = y + '-' + m + '-' + d + ' ' + H + ':' + M + ':' + S;
  // date = y + '/' + m + '/' + d
  return date;
}

export function conversionTime_NotDate(date) {
  var y = date.getFullYear().toString(),
    m = (date.getMonth() + 1).toString(),
    d = date.getDate().toString(),
    H = date.getHours().toString(),
    M = date.getMinutes().toString(),
    S = date.getSeconds().toString();
  m = m > 9 ? m : '0' + m;
  d = d > 9 ? d : '0' + d;
  H = H > 9 ? H : '0' + H;
  M = M > 9 ? M : "0" + M; //如果分钟小于10,则在前面加0补充为两位数字
  S = S > 9 ? S : "0" + S
  date = H + ':' + M + ':' + S;
  // date = y + '/' + m + '/' + d
  return date;
}

export function conversionTime_ISO8601(date) {
  date.setHours(date.getHours(), date.getMinutes() - date.getTimezoneOffset())
  return date.toISOString()
}

export function getCurrentTime()
{
  var data = new Date()
  return data
}

export function getCurrentTime_ISO8601()
{
  var data = new Date()
  data.setHours(data.getHours(), data.getMinutes() - data.getTimezoneOffset())
  return data.toISOString()
}

export function getLessTime()
{
  var data = new Date()
  var less = new Date(data.setHours(data.getHours() - 1))
  return less
}

export function getLessTime_ISO8601()
{
  var data = new Date()
  var less = new Date(data.setHours(data.getHours() - 1))
  less.setHours(less.getHours(), less.getMinutes() - less.getTimezoneOffset())
  return less.toISOString()
}


// 通用数据效验

// 判断vuex数据是否存在
export function isVuexHava(data)
{
  if(data!=null&&data!='null'&&data!=undefined&&data!='undefined'&&data!='')
  {
    return true
  }
  else
  {
    return false
  }
}

// 是否含有特殊字符【除下划线】
export function isSpecial(val) {
  let containSpecial = RegExp(
    /[(\ )(\~)(\!)(\@)(\#)(\$)(\%)(\^)(\&)(\*)(\()(\))(\-)(\+)(\=)(\[)(\])(\{)(\})(\|)(\\)(\;)(\:)(\')(\")(\,)(\.)(\/)(\<)(\>)(\?)(\)]+/
  );
  return containSpecial.test(val);
}

// 是否含有中文
export function isChinese(val) {
  let re = /.*[\u4e00-\u9fa5]+.*$/
  return re.test(val)
}

// 是否存数字
export function isNumber(val) {
  let re = /^\d+$/
  return re.test(val);
}

export function isPhoneNumber(val) {
  if (isNumber(val) && val.length == 11 && val.substr(0, 1) == '1') {
    return true
  } else {
    return false
  }
}

export function isEmail(val) {
  let re = /^(\w-*\.*)+@(\w-?)+(\.\w{2,})+$/
  return re.test(val)
}

export function isVerificationCode(val) {
  if (isNumber(val) && val.length == 6 && val.substr(0, 1) != '0') {
    return true
  } else {
    return false
  }
}

export function isUserName(val) {
  if (!isSpecial(val) && !isChinese(val) && val.length >= 4 && val.length <= 32) {
    return true
  } else {
    return false
  }
}

export function isNickName(val) {
  if (val != null && !isSpecial(val) && val.length >= 2 && val.length <= 32) {
    return true
  } else {
    return false
  }
}




// EL表单验证自定义规则
// 确认是否为手机号
const isPhoneNumber_el = (rule, value, callback) => {
  setTimeout(() => {
    if (isNumber(value) && value.length == 11 && value.substr(0, 1) == '1') {
      callback();
    } else {
      callback(new Error('请输入正确的手机号'));
    }
  }, 100)
}

const isVerificationCode_el = (rule, value, callback) => {
  setTimeout(() => {
    if (isNumber(value) && value.length == 6) {
      if (value.substr(0, 1) == '0') {
        callback(new Error('验证码首位不为0'));
      } else {
        callback();
      }
    } else {
      callback(new Error('验证码为6为数字'));
    }
  }, 100)
}

const isUserName_el = (rule, value, callback) => {
  setTimeout(() => {
    if (isSpecial(value)) {
      callback(new Error('用户名不能使用特殊字符'));
    } else {
      if (isChinese(value)) {
        callback(new Error('用户名不能使用中文'));
      } else {
        if (value.length < 4 || value.length > 32) {
          callback(new Error('用户名必须在4~32个字符'));
        } else {
          callback();
        }
      }
    }
  }, 100)
}

const isNickName_el = (rule, value, callback) => {
  setTimeout(() => {
    if (isSpecial(value)) {
      callback(new Error('昵称不能使用特殊字符'));
    } else {
      if (value.length < 2 || value.length > 32) {
        callback(new Error('昵称必须在2~32个字符'));
      } else {
        callback();
      }
    }
  }, 100)
}




// 获取用户基础信息
export function getCurrentUserInfo(data) {
  return new Promise((resolve, reject) => {
    instance.request_private.get('/mqttapi/v2/user/profile').then(res => {
      if (res.data.code == 1) {
        let userInfoData = dataDecode(res.data.data)

        $store.commit('setUcUserName', userInfoData.username)
        $store.commit('setUcNickName', userInfoData.nickname)
        $store.commit('setUcUid', userInfoData.uid)
        $store.commit('setUcStatus', userInfoData.status)
        $store.commit('setUcPhoneNumber', userInfoData.phone_number)
        $store.commit('setUcEmail', userInfoData.email)
        $store.commit('setUcAvatar', userInfoData.avatar)
        // if (userInfoData.avatar != null) {
        // 	imgDecode($store.getters.ucAvatar).then(resImg => {
        // 		$store.commit('setUcAvatarImg', resImg)
        // 	})
        // } else {
        // 	$store.commit('setUcAvatarImg', null)
        // }
        resolve('success')

      } else {
        resolve('error')
        // ElMessage.error('获取用户信息失败');
      }
    }).catch(err => {
      reject(err)
      ElMessage.error('获取用户信息失败');
    })
  })
}

// const hideUcPhoneNumber = $store.getters.ucPhoneNumber



export function loginOut() {
  // if($mqttGlobalData.client!=null)
  // 	{
  // 		$mqttGlobalData.client.end()
  //     // $mqttGlobalData.client=null
  // 	}


  $store.commit('setJwt', null)
  $store.commit('setRefreshToken', null)
  $store.commit('setUcUserName', null)
  $store.commit('setUcNickName', null)
  $store.commit('setUcUid', null)
  $store.commit('setUcPhoneNumber', null)
  $store.commit('setUcStatus', null)
  $store.commit('setUcAvatar', null)
  $store.commit('setUcAvatarImg', null)
  $store.commit('setUcEmail', null)
  $store.commit('setUcEmail', null)
  $store.commit('setUcEmail', null)

  $store.commit('setUcWxStatus', null)
  $store.commit('setUcSaStatus', null)


  $store.commit('setMqttDeviceKeyObject', null)
  $store.commit('setMqttSubscribeList', null)


  localStorage.clear() //这个要写在commit后面才能清除

  router.push({
    path: '/login',
  })


}





const basic = {
  // 配置相关
  config_js,
  permissionType,
  messageType,
  readType,
  wxType,
  saType,

  // 获取vuex数据相关

  // 编码相关
  dataDecode,
  dataEncode,
  imgDecode,
  imgEncode,
  conversionTime,
  conversionTime_NotDate,
  conversionTime_ISO8601,

  // 获取相关
  getCurrentTime,
  getLessTime,
  getCurrentTime_ISO8601,
  getLessTime_ISO8601,

  // 验证相关
  isVuexHava,
  isSpecial,
  isChinese,
  isNumber,
  isPhoneNumber,
  isEmail,
  isVerificationCode,
  isUserName,
  isNickName,

  isPhoneNumber_el,
  isVerificationCode_el,
  isUserName_el,
  isNickName_el,

  // 请求相关
  getCurrentUserInfo,
  loginOut,


}

export default basic
