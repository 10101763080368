<template>
  <div class="pc-main">
    <div class="public_background"></div>
    <div class="pc-sub">

      <!-- 头部 -->
      <div class="pc-sub-header">
        <UserInfoHearder title="个人中心"></UserInfoHearder>
      </div>

      <div class="pc-sub-body">
        <div class="pc-sub-body-inner">

          <!-- <el-upload class="upload-demo" action="" :on-preview="handlePreview" :on-remove="handleRemove"
            :show-file-list="false" :on-change="avatarChange" :before-remove="beforeRemove" multiple :limit="1"
            :on-exceed="handleExceed" :file-list="avatarData.list" :auto-upload="false" :on-success="handleChange">
          </el-upload> -->

            <el-avatar class="pc_avatar" :shape="$basic.config_js.avatarStyle" v-if="$store.getters.ucAvatar==null"
              @click="openSetAvatarDialog()">{{$store.getters.ucNickName.substr(0, 1)}}</el-avatar>
            <el-avatar class="pc_avatar" :shape="$basic.config_js.avatarStyle" v-if="$store.getters.ucAvatar!=null"
              :src="$store.getters.ucAvatar" @click="openSetAvatarDialog()"></el-avatar>




          <div class="pc-userinfo-block">
            <div class="pc-userinfo-title">
              基础信息
            </div>
            <div class="pc-userinfo-item">
              <div class="pc-userinfo-value">用户名</div>
              <div class="pc-userinfo-punctuation">:</div>
              <div class="pc-userinfo-content">
                {{$store.getters.ucUserName}}
              </div>
              <!-- <div class="pc-userinfo-click">修改</div> -->
            </div>

            <div class="pc-userinfo-item">
              <div class="pc-userinfo-value">昵称</div>
              <div class="pc-userinfo-punctuation">:</div>
              <div class="pc-userinfo-content">
                {{$store.getters.ucNickName}}
              </div>
              <div class="pc-userinfo-click" @click="openSetNickNameDialog()">修改</div>
            </div>

            <div class="pc-userinfo-item">
              <div class="pc-userinfo-value">密码</div>
              <div class="pc-userinfo-punctuation">:</div>
              <!-- <div class="pc-userinfo-content"></div> -->
              <div class="pc-userinfo-click" @click="openSetPasswordDialog()">修改</div>
            </div>

          </div>


          <div class="pc-userinfo-block">
            <div class="pc-userinfo-title">
              账号绑定
            </div>

            <div class="pc-userinfo-item">
              <div class="pc-userinfo-value">手机号</div>
              <div class="pc-userinfo-punctuation">:</div>
              <div class="pc-userinfo-content">
                {{$store.getters.ucPhoneNumber.replace(/^(\w{3})\w{4}(.*)$/,'$1****$2')}}
              </div>
              <div class="pc-userinfo-click" @click="openSetPhoneNumberDialog()">修改</div>
            </div>

            <div class="pc-userinfo-item">
              <div class="pc-userinfo-value">邮箱</div>
              <div class="pc-userinfo-punctuation">:</div>
              <div :class="'pc-userinfo-content' + ' ' + ($store.getters.ucEmail==null?'pc-off':'')">
                {{$store.getters.ucEmail==null?'未绑定':$store.getters.ucEmail}}
              </div>
              <div class="pc-userinfo-click" @click="openSetEmailDialog()">
                {{$store.getters.ucEmail==null?'绑定':'修改'}}
              </div>
            </div>

            <div class="pc-userinfo-item">
              <div class="pc-userinfo-value">微信</div>
              <div class="pc-userinfo-punctuation">:</div>
              <div
                :class="'pc-userinfo-content' + ' ' +($store.getters.ucWxStatus==0?'pc-off':($store.getters.ucWxStatus==1?'pc-on':''))">
                {{$store.getters.ucWxStatus==null?'获取中...':($store.getters.ucWxStatus==0?'未绑定':$store.getters.ucWxName)}}
              </div>
              <div class="pc-userinfo-click" @click="openBinDingWxDialog()" v-if="$store.getters.ucWxStatus==0">绑定</div>
              <div class="pc-userinfo-click" @click="openRemoveWxDialog()" v-if="$store.getters.ucWxStatus==1">解绑</div>
            </div>

            <div class="pc-userinfo-item">
              <div class="pc-userinfo-value">公众号</div>
              <div class="pc-userinfo-punctuation">:</div>
              <div
                :class="'pc-userinfo-content' + ' ' +($store.getters.ucSaStatus==2?'pc-on':($store.getters.ucSaStatus==1?'pc-off':($store.getters.ucSaStatus==0?'pc-off':'')))">
                {{$store.getters.ucSaStatus==null?'获取中...':$basic.saType[$store.getters.ucSaStatus]}}
              </div>
              <div class="pc-userinfo-click" @click="openFollowDialog()">如何关注？</div>
            </div>

          </div>



        </div>
      </div>

    </div>

    <!-- 弹窗 -->
    <div class="my-userDialog">


      <el-dialog title="修改昵称" v-model="nickNameData.dialog" @closed="closeSetNickNameDialog('nickNameForm')">

        <div>
          <el-form class="my-form" :model="nickNameData.nickNameForm" :rules="nickNameData.nickNameFormRule"
            ref="nickNameRef" @submit.prevent>
            <el-form-item prop="nickName">
              <el-input class="my-input" v-model.trim="nickNameData.nickNameForm.nickName"></el-input>
            </el-form-item>
          </el-form>
        </div>

        <template #footer>
          <span class="dialog-footer">
            <!-- <el-button @click="nickNameData.dialog = false">取 消</el-button> -->
            <el-button class="my-btn" type="primary" @click="setNickName()" :disabled="nickNameData.disabled"
              v-loading.fullscreen.lock="nickNameData.loading">保 存
            </el-button>
          </span>
        </template>
      </el-dialog>


      <el-dialog title="修改密码" v-model="passwordData.dialog" @closed="closeSetPasswordDialog">

        <div>
          <el-form class="my-form" label-position="top" :model="passwordData.passwordForm"
            :rules="passwordData.passwordFormRule" ref="passwordRef" @submit.prevent>
            <el-form-item label="旧密码" prop="oldPassword">
              <el-input class="my-input" type="password" v-model.trim="passwordData.passwordForm.oldPassword"
                show-password>
              </el-input>
            </el-form-item>
            <el-form-item label="新密码" prop="newPassword">
              <el-input class="my-input" type="password" v-model.trim="passwordData.passwordForm.newPassword"
                show-password>
              </el-input>
            </el-form-item>
            <el-form-item label="重复新密码" prop="checkPassword">
              <el-input class="my-input" type="password" v-model.trim="passwordData.passwordForm.checkPassword"
                show-password>
              </el-input>
            </el-form-item>

          </el-form>
        </div>

        <template #footer>
          <span class="dialog-footer">
            <!-- <el-button @click="nickNameData.dialog = false">取 消</el-button> -->
            <el-button class="my-btn" type="primary" @click="setPassword()" style="width: 100%;"
              :disabled="passwordData.disabled" v-loading.fullscreen.lock="passwordData.loading">确 定
            </el-button>
          </span>
        </template>
      </el-dialog>


      <el-dialog title="修改绑定手机" v-model="PhoneNumberData.dialog" @closed="closeSetPhoneNumberDialog">

        <div v-show="safetyDetectionData.token==null">
          <div style="text-align: center;margin-bottom: 1vw;">
            为确保是您本人操作，请通过
          </div>
          <div style="text-align: center;margin-bottom: 1vw;">
            <b>{{safetyDetectionData.phoneNumber.replace(/^(\w{3})\w{4}(.*)$/,'$1****$2')}}</b>
            手机号获取验证码进行验证
          </div>
          <el-form :model="safetyDetectionData.safetyDetectionForm" :rules="safetyDetectionData.safetyDetectionFormRule"
            ref="safetyDetectionRef" @submit.prevent>
            <el-form-item prop="code">
              <div style="display: flex;justify-content: space-between;">
                <el-input class="my-input" v-model.trim="safetyDetectionData.safetyDetectionForm.code"
                  style="width: 55%;">
                </el-input>
                <el-button id="getSafetyCodeBtn" type="primary" class="my-formbtn" style=""
                  v-loading.fullscreen.lock="safetyDetectionData.getCodeLoading">
                  {{safetyDetectionData.time}}{{safetyDetectionData.suffix}}
                </el-button>
              </div>
            </el-form-item>
          </el-form>

          <div style="text-align: right;color: rgba(174,174,174,1);">
            当前绑定手机号如有异常情况请联系客服
          </div>
          <div style="text-align: right;color: rgba(174,174,174,1);">
            iot@neodawn.cn
          </div>

        </div>

        <div v-show="safetyDetectionData.token!=null">
          <el-form class="my-form" :model="newPhoneNumberData.newPhoneNumberForm" label-position="top"
            :rules="newPhoneNumberData.newPhoneNumberFormRule" ref="newPhoneNumberRef" @submit.prevent>

            <el-form-item label="新手机号" prop="phoneNumber">
              <el-input class="my-input" v-model.trim="newPhoneNumberData.newPhoneNumberForm.phoneNumber">
              </el-input>
            </el-form-item>

            <el-form-item label="验证码" prop="code">
              <div style="display: flex;justify-content: space-between;">
                <el-input class="my-input" v-model.trim="newPhoneNumberData.newPhoneNumberForm.code"
                  style="width: 55%;">
                </el-input>
                <el-button id="getNewPhoneNumberCodeBtn" type="primary" class="my-formbtn" style=""
                  v-loading.fullscreen.lock="newPhoneNumberData.getCodeLoading">
                  {{newPhoneNumberData.time}}{{newPhoneNumberData.suffix}}
                </el-button>
              </div>
            </el-form-item>
          </el-form>
        </div>

        <template #footer>
          <span class="dialog-footer">
            <!-- <el-button @click="nickNameData.dialog = false">取 消</el-button> -->
            <el-button class="my-btn" type="primary" @click="setSafetyDetection()"
              :disabled="safetyDetectionData.disabled" v-loading.fullscreen.lock="safetyDetectionData.setLoading"
              v-if="safetyDetectionData.token==null">验 证
            </el-button>

            <el-button class="my-btn" type="primary" @click="setNewPhoneNumber()"
              :disabled="newPhoneNumberData.disabled" v-loading.fullscreen.lock="newPhoneNumberData.setLoading"
              v-if="safetyDetectionData.token!=null">绑 定
            </el-button>


          </span>
        </template>
      </el-dialog>


      <el-dialog :title="$store.getters.ucEmail==null?'绑定邮箱':'修改绑定邮箱'" v-model="emailData.dialog"
        @closed="closeSetEmailDialog">


        <div>
          <el-form class="my-form" :model="emailData.form" label-position="top" :rules="emailFormRule" ref="emailRef"
            @submit.prevent>

            <el-form-item label="新邮箱" prop="email">
              <el-input class="my-input" v-model.trim="emailData.form.email">
              </el-input>
            </el-form-item>

            <el-form-item label="验证码" prop="code">
              <div style="display: flex;justify-content: space-between;">
                <el-input class="my-input" v-model.trim="emailData.form.code" style="width: 55%;">
                </el-input>
                <el-button id="getEmailCodeBtn" type="primary" class="my-formbtn" style=""
                  v-loading.fullscreen.lock="emailData.getCodeLoading">
                  {{emailData.time}}{{emailData.suffix}}
                </el-button>
              </div>
            </el-form-item>
          </el-form>
        </div>

        <template #footer>
          <span class="dialog-footer">
            <!-- <el-button @click="nickNameData.dialog = false">取 消</el-button> -->
            <el-button class="my-btn" type="primary" @click="setEmail()" :disabled="emailData.disabled"
              v-loading.fullscreen.lock="emailData.setLoading">确 定
            </el-button>

          </span>
        </template>
      </el-dialog>

      <el-dialog title="修改头像" v-model="avatarData.dialog" @closed="closeCropperDialog" destroy-on-close>
        <div>
          <vue-picture-cropper ref="cutRef" :boxStyle="{
                  width: '100%',
                  height: '100%',
                  backgroundColor: '#f8f8f8',
                  margin: 'auto'
                }" :img="avatarData.url" :options="{
                  width:200,
                  height:200,
                  viewMode: 1,
                  dragMode: 'crop',
                  aspectRatio: 1 / 1,
                  preview: '.preview',
                }" />
          <!-- <vue-picture-cropper
					    :boxStyle="{
					      width: '100%',
					      height: '100%',
					      backgroundColor: '#f8f8f8',
					      margin: 'auto'
					    }"
					    :img="avatarData.url"
					    :options="{
					      viewMode: 1,
					      dragMode: 'crop',
					      aspectRatio: 16 / 9,
					      preview: preview,
					    }"
					    @ready="ready"
					  /> -->
        </div>
        <div>
          <el-button @click="getCutImg()">clear</el-button>
        </div>
        <div>
          <img :src="avatarData.cutUrl" >
        </div>
      </el-dialog>

      <el-dialog title="微信绑定" v-model="binDingWxData.dialog">
        <div class="bwx-qrc">

          <div id="wxQrcCodeForBinding" style="text-align: center;">

          </div>

          <div style="font-size: 1vw;font-weight: bold;text-align: center;">
            请使用微信扫码登录进行绑定
          </div>

        </div>
      </el-dialog>

      <el-dialog title="微信解绑" v-model="binDingWxData.removeDialog">

        <div style="font-size: 1vw;font-weight: bold;text-align: center;">
          确定要解除微信绑定吗？
        </div>

        <template #footer>
          <span class="dialog-footer">
            <el-button class="my-btn" @click="binDingWxData.removeDialog = false" style="width: 20%;">取 消</el-button>
            <el-button class="my-btn" type="primary" @click="removeWx()"
              v-loading.fullscreen.lock="binDingWxData.removeLoading" style="width: 20%;">确 定
            </el-button>

          </span>
        </template>

      </el-dialog>

      <el-dialog title="如何关注公众号" v-model="binDingWxData.followDialog">

        <div style="font-size: 1vw;font-weight: bold;text-align: center;">
          第一步：打开微信“扫一扫”
        </div>
        <div style="font-size: 1vw;text-align: center;margin-top: 1vw;margin-bottom: 1vw;">
          👇
        </div>
        <div style="text-align: center;">
          <img src="../../assets/image/followSa/2@2x.png" style="width: 90%;">
        </div>

        <div style="font-size: 1vw;font-weight: bold;text-align: center;margin-top: 2vw;">
          第二步：扫描下方二维码
        </div>
        <div style="font-size: 1vw;text-align: center;margin-top: 1vw;margin-bottom: 1vw;">
          👇
        </div>
        <div style="text-align: center;">
          <img src="../../assets/image/followSa/1@2x.png" style="width: 50%;">
        </div>

        <div style="font-size: 1vw;font-weight: bold;text-align: center;margin-top: 2vw;">
          第三步：关注neodawn公众号
        </div>
        <div style="font-size: 1vw;text-align: center;margin-top: 1vw;margin-bottom: 1vw;">
          👇
        </div>
        <div style="text-align: center;">
          <img src="../../assets/image/followSa/6@2x.png" style="width: 50%;">
        </div>

      </el-dialog>



    </div>



  </div>

</template>

<script setup>
  import UserInfoHearder from '../../components/public_modular/UserInfoHearder.vue'
  import VuePictureCropper, {cropper} from 'vue-picture-cropper'
  // import { VuePictureCropper, cropper } from 'vue-picture-cropper'


  // ref方法
  // reactive可传对象数组
  import {
    reactive,
    ref,
    getCurrentInstance,
    onMounted,
    watch,
  } from 'vue'



  // router、接口引入
  const {
    proxy
  } = getCurrentInstance()


  onMounted(() => {
    // proxy.$basic.getCurrentUserInfo()
    getWxStatus()
    getWxSaStatus()
    // safetyDetectionGeetestInitialize()

    // console.log('av',proxy.$basic.hideUcPhoneNumber(),null)
  })



  const aboutdata = reactive({
    url: '',

  })


  // 修改头像

  const avatarData = reactive({
    dialog: false,
    list: [],
    url: '',
    cutUrl:'',
  })

  function openSetAvatarDialog() {
    // avatarData.dialog = true
  }

  function closeCropperDialog() {
    // console.log('1111111111111111111111111')
    avatarData.url = ''
    avatarData.list = []
  }

  function clear() {
    avatarData.url = ''
    // console.log('url', avatarData.url)
    // console.log('cropper', cropper)
    // cropper.clear();
  }

  // const getCutImg = (): void => {
  //   let img_b64 = cropper.getDataURL()
  //   let img_blob = cropper.getBlob()

  //   console.log('img_b64', img_b64)
  //   console.log('img_blob', img_blob)
  // }
  const cutRef = ref(null)

  function getCutImg()
  {
    // console.log('cutRef',cutRef.value.cropper.getDataURL())
    let img_b64 = cutRef.value.cropper.getDataURL()
    let img_blob = cutRef.value.cropper.getBlob()

    // console.log('img_b64',img_b64)
    // console.log('img_blob',img_blob)

  }


  function avatarChange(file, fileList) {
    // console.log('fileList', fileList)
    let event = event || window.event
    let file_e = event.target.files[0]
    let reader = new FileReader()
    reader.onload = function(e) {
      avatarData.url = e.target.result
    }
    reader.readAsDataURL(file_e)
    // console.log('e', event)

    // cropper.reset()
    avatarData.url = URL.createObjectURL(file.raw)
    avatarData.dialog = true

  }

  function handleChange(res, file) {

  }

  function handleRemove(file, fileList) {
    // console.log(file, fileList);
  }

  function handlePreview(file) {
    // console.log(file);
  }

  function handleExceed(files, fileList) {
    this.$message.warning(`当前限制选择 3 个文件，本次选择了 ${files.length} 个文件，共选择了 ${files.length + fileList.length} 个文件`);
  }

  function beforeRemove(file, fileList) {
    return this.$confirm(`确定移除 ${ file.name }？`);
  }


  // 昵称修改

  const nickNameData = reactive({
    dialog: false,
    disabled: true,
    loading: false,

    nickNameForm: {
      nickName: null,
    },

    nickNameFormRule: {
      nickName: [{
        required: true,
        message: '昵称不能为空',
        trigger: 'change'
      }, {
        validator: proxy.$basic.isNickName_el,
        trigger: 'change'
      }],
    },
  })

  const nickNameRef = ref(null)

  function openSetNickNameDialog() {
    // console.log('proxy.$refs[formName].clearValidate()',proxy.$refs['nickNameForm'])
    // proxy.$nextTick(function() {
    //   proxy.$refs['nickNameForm'].clearValidate()
    // });
    nickNameData.nickNameForm.nickName = proxy.$store.getters.ucNickName
    nickNameData.dialog = true
  }


  function closeSetNickNameDialog(formName) {
    nickNameData.nickNameForm.nickName = null
    nickNameRef.value.clearValidate()
    // console.log('proxy.$refs[formName].clearValidate()',proxy.$refs['nickNameForm'])
    // proxy.$refs[formName].clearValidate()

    // proxy.$refs[nickNameForm].clearValidate()
    // proxy.$nextTick(function() {
    //   proxy.$refs[nickNameForm].clearValidate()
    // });

    // proxy.$refs['nickNameForm'].clearValidate()
    // this.$nextTick(function() {
    //   this.$refs['editGroupForm'].clearValidate()
    // });
  }

  function checkSetNickBtn() {
    if (
      proxy.$basic.isNickName(nickNameData.nickNameForm.nickName) &&
      nickNameData.nickNameForm.nickName != proxy.$store.getters.ucNickName
    ) {
      nickNameData.disabled = false
    } else {
      nickNameData.disabled = true
    }
  }

  function setNickName() {
    // proxy.$refs[''].validate((valid) => {
    // 	if(valid)
    // 	{

    // 	}
    // })
    nickNameRef.value.validate((valid) => {
      if (valid) {
        // nickNameData.loading = proxy.$loading({
        // 	lock: true,
        // 	text: '正在修改...',
        // 	spinner: 'el-icon-loading',
        // 	background: 'rgba(0, 0, 0, 0.7)'
        // })
        nickNameData.loading = true
        proxy.$instance.request_private.post('/mqttapi/v2/user/change_nickname', {
          nickname: nickNameData.nickNameForm.nickName
        }).then(res => {
          if (res.data.code == 1) {
            nickNameData.loading = false
            proxy.$store.commit('setUcNickName', nickNameData.nickNameForm.nickName)
            nickNameData.dialog = false
            proxy.$message.success('保存成功')
            proxy.$basic.getCurrentUserInfo()
          } else {
            nickNameData.loading = false
            proxy.$message.error(proxy.$errorCode[res.data.code])
          }
        })
      }
    })

  }

  // 修改密码

  // 表单用验证码规则
  const isCheckPassword = (rule, value, callback) => {
    if (value != passwordData.passwordForm.newPassword) {
      callback(new Error('两次输入密码不一致'));
    } else {
      callback();
    }
  }

  const passwordData = reactive({
    dialog: false,
    disabled: true,
    loading: false,


    passwordForm: {
      oldPassword: null,
      newPassword: null,
      checkPassword: null,
    },

    passwordFormRule: {
      oldPassword: [{
        required: true,
        message: '请输入旧密码',
        trigger: 'change'
      }],
      newPassword: [{
          required: true,
          message: '请输入新密码',
          trigger: 'change'
        },
        {
          min: 8,
          max: 16,
          message: '密码必须在8~16个字符',
          trigger: 'change'
        }
      ],
      checkPassword: [{
          required: true,
          message: '请重复新密码',
          trigger: 'change'
        },
        {
          validator: isCheckPassword,
          trigger: 'change'
        },
      ],
    }

  })

  const passwordRef = ref(null)

  function openSetPasswordDialog() {
    passwordData.dialog = true
  }

  function closeSetPasswordDialog() {
    passwordData.passwordForm.oldPassword = null
    passwordData.passwordForm.newPassword = null
    passwordData.passwordForm.checkPassword = null


    passwordRef.value.clearValidate()
  }

  function checkSetPasswordBtn() {
    if (
      passwordData.passwordForm.oldPassword != null &&
      passwordData.passwordForm.newPassword != null &&
      passwordData.passwordForm.newPassword.length >= 8 &&
      passwordData.passwordForm.newPassword.length <= 16 &&
      passwordData.passwordForm.newPassword == passwordData.passwordForm.checkPassword
    ) {
      passwordData.disabled = false
    } else {
      passwordData.disabled = true
    }
  }

  function setPassword() {
    passwordRef.value.validate((valid) => {
      if (valid) {
        passwordData.loading = true
        proxy.$instance.request_private.post('/mqttapi/v2/user/change_password', {
          new_password: proxy.$sha256(passwordData.passwordForm.newPassword),
          old_password: proxy.$sha256(passwordData.passwordForm.oldPassword)
        }).then(res => {
          if (res.data.code == 1) {
            passwordData.loading = false
            passwordData.dialog = false
            proxy.$message.success('密码修改成功，请重新登录')
            setTimeout(() => {
              proxy.$basic.loginOut()
            }, 1000)
          } else {
            passwordData.loading = false
            proxy.$message.error(proxy.$errorCode[res.data.code])
          }
        })
      }
    })
  }


  // 修改绑定手机号
  const PhoneNumberData = reactive({
    dialog: false,
    initialization: false,
  })

  const safetyDetectionData = reactive({

    disabled: true,
    setLoading: false,
    getCodeLoading: false,

    // 验证码按钮相关
    time: "获取验证码",
    currentTime: 0,
    suffix: null,

    PhoneNumber: null,
    token: null,


    safetyDetectionForm: {
      code: null,
    },

    safetyDetectionFormRule: {
      code: [{
          required: true,
          message: '请输入验证码',
          trigger: 'change'
        },
        {
          validator: proxy.$basic.isVerificationCode_el,
          trigger: 'change'
        }
      ]
    }

  })

  const safetyDetectionRef = ref(null)

  function safetyDetectionGeetestInitialize() {
    proxy.$instance.request_public.get('/mqttapi/v2/geetest/initialize').then(res => {
      if (res.data.code == 1) {
        PhoneNumberData.initialization = true
        let gtdata = proxy.$basic.dataDecode(res.data.data)
        initGeetest({
          gt: gtdata.gt,
          challenge: gtdata.challenge,
          offline: !gtdata.success,
          new_captcha: gtdata.new_captcha,
          width: '100%',
          product: 'bind',
        }, function(captchaObj) {
          captchaObj.onReady(function() {

          }).onSuccess(function() {
            let result = captchaObj.getValidate()
            // console.log('result', result)
            if (!result) {
              proxy.$message.error('人机验证失败')
              // console.log('error')
            } else {

              safetyDetectionData.getCodeLoading = true
              // 验证通过后进行获取验证码
              proxy.$instance.request_private.post('/mqttapi/v2/user/sms/send', {
                phone_number: safetyDetectionData.phoneNumber,
                type: 2,
                validate: result.geetest_validate,
                seccode: result.geetest_seccode,
                challenge: result.geetest_challenge
              }).then(res => {
                if (res.data.code == 1) {
                  let data = proxy.$basic.dataDecode(res.data.data)
                  safetyDetectionCheckTask(data.task_id, 0)


                  // console.log('data',data)
                  // loginLoading.close();
                } else {
                  safetyDetectionData.getCodeLoading = false
                  proxy.$message.error(proxy.$errorCode[res.data.code])
                }
              })

              // console.log('ok')
            }
          }).onError(function() {
            // console.log('error2')
            proxy.$message.error('人机验证未通过')
          }).onClose(function() {
            // console.log('close')
          })

          // document.getElementById('button1')

          document.getElementById('getSafetyCodeBtn').addEventListener('click', function() {
            // console.log('click',click)
            // console.log('loginbtn', loginbtn)
            if (getSafetyCodeBtn) { // 检查是否可以进行提交
              if (safetyDetectionData.currentTime <= 0) {
                captchaObj.verify();
              } else {
                proxy.$message.error('倒计时结束后再请求')
              }
              // captchaObj.verify();
            }
          });

        })
      } else {
        proxy.$message.error('人机验证发生错误')
      }
    })
  }

  function safetyDetectionCheckTask(task_id, die) {
    proxy.$instance.request_public.get('/mqttapi/v2/user/sms/task', {
      params: {
        task_id: task_id
      }
    }).then(res => {
      if (res.data.code == 1) {
        safetyDetectionData.getCodeLoading = false
        proxy.$message.success('验证码已发送')
        safetyDetectionCodeStart()
      } else if (res.data.code == 2000) {
        if (die < 20) {
          setTimeout(() => {
            safetyDetectionCheckTask(task_id, die++)
          }, 1000)
        } else {
          safetyDetectionData.getCodeLoading = false
          proxy.$message.error('验证码发送失败')
        }
      } else {
        safetyDetectionData.getCodeLoading = false
        proxy.$message.error(proxy.$errorCode[res.data.code])
      }
    })
  }

  function safetyDetectionCodeStart() {
    safetyDetectionData.currentTime = proxy.$basic.config_js.countDown
    let interval = null;
    interval = setInterval(function() {
      safetyDetectionData.currentTime--;
      safetyDetectionData.time = safetyDetectionData.currentTime
      safetyDetectionData.suffix = '秒'
      if (safetyDetectionData.currentTime <= 0) {
        clearInterval(interval)
        safetyDetectionData.currentTime = proxy.$basic.config_js.countDown
        safetyDetectionData.suffix = null
        safetyDetectionData.time = '重新发送'

      }
    }, 1000)
  }

  function checkSafetyDetectionBtn() {
    if (proxy.$basic.isVerificationCode(safetyDetectionData.safetyDetectionForm.code)) {
      safetyDetectionData.disabled = false
    } else {
      safetyDetectionData.disabled = true
    }
  }

  function setSafetyDetection() {
    safetyDetectionRef.value.validate((valid) => {
      if (valid) {
        safetyDetectionData.setLoading = true
        proxy.$instance.request_private.post('/mqttapi/v2/user/verify_phone_number', {
          phone_number: safetyDetectionData.phoneNumber,
          sms_code: parseInt(safetyDetectionData.safetyDetectionForm.code)
        }).then(res => {
          if (res.data.code == 1) {
            let data = proxy.$basic.dataDecode(res.data.data)
            safetyDetectionData.setLoading = false
            safetyDetectionData.token = data.token
            proxy.$message.success('验证成功')

          } else {
            safetyDetectionData.setLoading = false
            proxy.$message.error(proxy.$errorCode[res.data.code])
          }
        })
      }
    })
  }

  const newPhoneNumberData = reactive({
    disabled: false,
    setLoading: false,
    getCodeLoading: false,


    // 验证码按钮相关
    time: "获取验证码",
    currentTime: 0,
    suffix: null,

    newPhoneNumberForm: {
      PhoneNumber: null,
      code: null,
    },

    newPhoneNumberFormRule: {
      phoneNumber: [{
        required: true,
        message: '请输入手机号',
        trigger: 'change'
      }, {
        validator: proxy.$basic.isPhoneNumber_el,
        trigger: 'change'
      }],
      code: [{
          required: true,
          message: '请输入验证码',
          trigger: 'change'
        },
        {
          validator: proxy.$basic.isVerificationCode_el,
          trigger: 'change'
        }
      ],
    }
  })

  const newPhoneNumberRef = ref(null)

  function newPhoneNumberGeetestInitialize() {
    proxy.$instance.request_public.get('/mqttapi/v2/geetest/initialize').then(res => {
      if (res.data.code == 1) {
        PhoneNumberData.initialization = true
        let gtdata = proxy.$basic.dataDecode(res.data.data)
        initGeetest({
          gt: gtdata.gt,
          challenge: gtdata.challenge,
          offline: !gtdata.success,
          new_captcha: gtdata.new_captcha,
          width: '100%',
          product: 'bind',
        }, function(captchaObj) {
          captchaObj.onReady(function() {

          }).onSuccess(function() {
            let result = captchaObj.getValidate()
            // console.log('result', result)
            if (!result) {
              proxy.$message.error('人机验证失败')
              // console.log('error')
            } else {

              newPhoneNumberData.getCodeLoading = true
              // 验证通过后进行获取验证码
              proxy.$instance.request_private.post('/mqttapi/v2/user/sms/send', {
                phone_number: newPhoneNumberData.newPhoneNumberForm
                  .phoneNumber,
                type: 3,
                validate: result.geetest_validate,
                seccode: result.geetest_seccode,
                challenge: result.geetest_challenge
              }).then(res => {
                if (res.data.code == 1) {
                  let data = proxy.$basic.dataDecode(res.data.data)
                  newPhoneNumberCheckTask(data.task_id, 0)


                  // console.log('data',data)
                  // loginLoading.close();
                } else {
                  newPhoneNumberData.getCodeLoading = false
                  proxy.$message.error(proxy.$errorCode[res.data.code])
                }
              })

              // console.log('ok')
            }
          }).onError(function() {
            // console.log('error2')
            proxy.$message.error('人机验证未通过')
          }).onClose(function() {
            // console.log('close')
          })

          // document.getElementById('button1')

          document.getElementById('getNewPhoneNumberCodeBtn').addEventListener('click',
            function() {
              // console.log('click',click)
              // console.log('loginbtn', loginbtn)
              if (getNewPhoneNumberCodeBtn) { // 检查是否可以进行提交
                if (newPhoneNumberData.currentTime <= 0) {
                  if (proxy.$basic.isPhoneNumber(newPhoneNumberData
                      .newPhoneNumberForm.phoneNumber)) {
                    captchaObj.verify();
                  } else {
                    proxy.$message.error('请输入正确的手机号')
                  }
                } else {
                  proxy.$message.error('倒计时结束后再请求')
                }
              }
            });

        })
      } else {
        proxy.$message.error('人机验证发生错误')
      }
    })
  }

  function newPhoneNumberCheckTask(task_id, die) {
    proxy.$instance.request_public.get('/mqttapi/v2/user/sms/task', {
      params: {
        task_id: task_id
      }
    }).then(res => {
      if (res.data.code == 1) {
        newPhoneNumberData.getCodeLoading = false
        proxy.$message.success('验证码已发送')
        newPhoneNumberCodeStart()
      } else if (res.data.code == 2000) {
        if (die < 20) {
          setTimeout(() => {
            newPhoneNumberCheckTask(task_id, die++)
          }, 1000)
        } else {
          newPhoneNumberData.getCodeLoading = false
          proxy.$message.error('验证码发送失败')
        }
      } else {
        newPhoneNumberData.getCodeLoading = false
        proxy.$message.error(proxy.$errorCode[res.data.code])
      }
    })
  }

  function newPhoneNumberCodeStart() {
    newPhoneNumberData.currentTime = proxy.$basic.config_js.countDown
    let interval = null;
    interval = setInterval(function() {
      newPhoneNumberData.currentTime--;
      newPhoneNumberData.time = newPhoneNumberData.currentTime
      newPhoneNumberData.suffix = '秒'
      if (newPhoneNumberData.currentTime <= 0) {
        clearInterval(interval)
        newPhoneNumberData.currentTime = proxy.$basic.config_js.countDown
        newPhoneNumberData.suffix = null
        newPhoneNumberData.time = '重新发送'

      }
    }, 1000)
  }

  function checkSetNewPhoneNumberBtn() {
    if (
      proxy.$basic.isPhoneNumber(newPhoneNumberData.newPhoneNumberForm.phoneNumber) &&
      proxy.$basic.isVerificationCode(newPhoneNumberData.newPhoneNumberForm.code)) {
      newPhoneNumberData.disabled = false
    } else {
      newPhoneNumberData.disabled = true
    }
  }

  function setNewPhoneNumber() {
    newPhoneNumberRef.value.validate((valid) => {
      if (valid) {
        newPhoneNumberData.setLoading = true
        proxy.$instance.request_private.post('/mqttapi/v2/user/change_phone_number', {
          phone_number: newPhoneNumberData.newPhoneNumberForm.phoneNumber,
          sms_code: parseInt(newPhoneNumberData.newPhoneNumberForm.code),
          token: safetyDetectionData.token,
        }).then(res => {
          if (res.data.code == 1) {
            newPhoneNumberData.setLoading = false
            proxy.$store.commit('setUcPhoneNumber', newPhoneNumberData.newPhoneNumberForm
              .phoneNumber)
            PhoneNumberData.dialog = false
            proxy.$message.success('修改成功,请重新登录')
            proxy.$basic.loginOut()
          } else {
            newPhoneNumberData.setLoading = false
            proxy.$message.error(proxy.$errorCode[res.data.code])
          }
        })
      }
    })
  }



  function openSetPhoneNumberDialog() {
    safetyDetectionData.phoneNumber = proxy.$store.getters.ucPhoneNumber
    if (PhoneNumberData.initialization == false) {
      safetyDetectionGeetestInitialize()
      newPhoneNumberGeetestInitialize()
    }
    PhoneNumberData.dialog = true
  }

  function closeSetPhoneNumberDialog() {
    safetyDetectionData.token = null
    safetyDetectionData.safetyDetectionForm.code = null
    newPhoneNumberData.newPhoneNumberForm.phoneNumber = null
    newPhoneNumberData.newPhoneNumberForm.code = null
    safetyDetectionRef.value.clearValidate()
    newPhoneNumberRef.value.clearValidate()
  }


  // 修改邮箱
  const emailRef = ref(null)

  const emailData = reactive({
    dialog: false,
    disabled: false,
    setLoading: false,
    getCodeLoading: false,
    initialization: false,

    // 验证码按钮相关
    time: "获取验证码",
    currentTime: 0,
    suffix: null,

    form: {
      email: null,
      code: null,
    }

  })

  const emailFormRule = reactive({
    email: [{
        required: true,
        message: '请输入新邮箱地址',
        trigger: 'change'
      },
      {
        type: 'email',
        message: '请输入正确的邮箱地址',
        trigger: 'change'
      }
    ],
    code: [{
        required: true,
        message: '请输入验证码',
        trigger: 'change'
      },
      {
        validator: proxy.$basic.isVerificationCode_el,
        trigger: 'change'
      }
    ],
  })


  function emailGeetestInitialize() {
    proxy.$instance.request_public.get('/mqttapi/v2/geetest/initialize').then(res => {
      if (res.data.code == 1) {
        emailData.initialization = true
        let gtdata = proxy.$basic.dataDecode(res.data.data)
        initGeetest({
          gt: gtdata.gt,
          challenge: gtdata.challenge,
          offline: !gtdata.success,
          new_captcha: gtdata.new_captcha,
          width: '100%',
          product: 'bind',
        }, function(captchaObj) {
          captchaObj.onReady(function() {

          }).onSuccess(function() {
            let result = captchaObj.getValidate()
            // console.log('result', result)
            if (!result) {
              proxy.$message.error('人机验证失败')
              // console.log('error')
            } else {

              emailData.getCodeLoading = true
              // 验证通过后进行获取验证码
              proxy.$instance.request_private.post('/mqttapi/v2/user/email/send', {
                email: emailData.form.email,
                type: 0,
                validate: result.geetest_validate,
                seccode: result.geetest_seccode,
                challenge: result.geetest_challenge,
              }).then(res => {
                if (res.data.code == 1) {
                  let data = proxy.$basic.dataDecode(res.data.data)
                  emailCheckTask(data.task_id, 0)


                  // console.log('data',data)
                  // loginLoading.close();
                } else {
                  emailData.getCodeLoading = false
                  proxy.$message.error(proxy.$errorCode[res.data.code])
                }
              })

              // console.log('ok')
            }
          }).onError(function() {
            // console.log('error2')
            proxy.$message.error('人机验证未通过')
          }).onClose(function() {
            // console.log('close')
          })

          // document.getElementById('button1')

          document.getElementById('getEmailCodeBtn').addEventListener('click',
            function() {
              // console.log('click',click)
              // console.log('loginbtn', loginbtn)
              if (getEmailCodeBtn) { // 检查是否可以进行提交
                if (emailData.currentTime <= 0) {
                  if (proxy.$basic.isEmail(emailData.form.email)) {
                    captchaObj.verify();
                  } else {
                    proxy.$message.error('请输入正确格式的邮箱')
                  }
                } else {
                  proxy.$message.error('倒计时结束后再请求')
                }
              }
            });

        })
      } else {
        proxy.$message.error('人机验证发生错误')
      }
    })
  }

  function emailCheckTask(task_id, die) {
    proxy.$instance.request_public.get('/mqttapi/v2/user/email/task', {
      params: {
        task_id: task_id
      }
    }).then(res => {
      if (res.data.code == 1) {
        emailData.getCodeLoading = false
        proxy.$message.success('验证码已发送')
        emailCodeStart()
      } else if (res.data.code == 2000) {
        if (die < 20) {
          setTimeout(() => {
            emailCheckTask(task_id, die++)
          }, 1000)
        } else {
          emailData.getCodeLoading = false
          proxy.$message.error('验证码发送失败')
        }
      } else {
        emailData.getCodeLoading = false
        proxy.$message.error(proxy.$errorCode[res.data.code])
      }
    })
  }

  function emailCodeStart() {
    emailData.currentTime = proxy.$basic.config_js.countDown
    let interval = null;
    interval = setInterval(function() {
      emailData.currentTime--;
      emailData.time = emailData.currentTime
      emailData.suffix = '秒'
      if (emailData.currentTime <= 0) {
        clearInterval(interval)
        emailData.currentTime = proxy.$basic.config_js.countDown
        emailData.suffix = null
        emailData.time = '重新发送'

      }
    }, 1000)
  }

  function checkSetEmailBtn() {
    if (
      proxy.$basic.isEmail(emailData.form.email) &&
      proxy.$basic.isVerificationCode(emailData.form.code)) {
      emailData.disabled = false
    } else {
      emailData.disabled = true
    }
  }

  function setEmail() {
    emailRef.value.validate((valid) => {
      if (valid) {
        emailData.setLoading = true
        proxy.$instance.request_private.post('/mqttapi/v2/user/change_email', {
          email: emailData.form.email,
          email_code: parseInt(emailData.form.code)
        }).then(res => {
          if (res.data.code == 1) {
            emailData.setLoading = false
            emailData.dialog = false
            if (proxy.$store.getters.ucEmail == null) {
              proxy.$message.success('绑定成功')
              proxy.$store.commit('setUcEmail', emailData.form.email)
              proxy.$basic.getCurrentUserInfo()
            } else {
              proxy.$message.success('改绑成功')
              proxy.$store.commit('setUcEmail', emailData.form.email)
              proxy.$basic.getCurrentUserInfo()
            }
          } else {
            emailData.setLoading = false
            proxy.$message.error(proxy.$errorCode[res.data.code])
          }
        })
      }
    })
  }


  function openSetEmailDialog() {
    if (emailData.initialization == false) {
      emailGeetestInitialize()
    }
    emailData.dialog = true
  }

  function closeSetEmailDialog() {
    emailData.form.email = null
    emailData.form.code = null
    emailRef.value.clearValidate()
  }

  // 微信相关

  function getWxStatus() {
    proxy.$instance.request_private.get('/mqttapi/v2/user/wechat/check_wechat').then(res => {
      if (res.data.code == 1) {
        let data = proxy.$basic.dataDecode(res.data.data)
        proxy.$store.commit('setUcWxName',data.wechat_username)
        proxy.$store.commit('setUcWxStatus', 1)
      } else {
        proxy.$store.commit('setUcWxName',null)
        proxy.$store.commit('setUcWxStatus', 0)
        // proxy.$message.error('微信绑定状态获取失败|' + res.data.code)
      }
    })
  }

  function getWxSaStatus() {
    // 公众号状态
    // 0:'未绑定微信'
    // 1:'已绑定微信未关注公众号'
    // 2:'已绑定微信并关注公众号'
    proxy.$instance.request_private.get('/mqttapi/v2/user/wechat/check_wechat_sa').then(res => {
      if (res.data.code == 1) {
        proxy.$store.commit('setUcSaStatus', 2)
      } else if (res.data.code == 6240) {
        proxy.$store.commit('setUcSaStatus', 0)
      } else if (res.data.code == 6250) {
        proxy.$store.commit('setUcSaStatus', 1)
      }
    })
  }

  const binDingWxData = reactive({
    dialog: false,
    removeDialog: false,
    removeLoading: false,
    followDialog: false,
  })


  function setWxQrc() {
    const s = document.createElement('script')
    s.type = 'text/javascript'
    s.src = 'https://res.wx.qq.com/connect/zh_CN/htmledition/js/wxLogin.js'
    const wxElement = document.body.appendChild(s)
    wxElement.onload = function() {
      var obj = new WxLogin({
        id: 'wxQrcCodeForBinding', // 需要显示的容器id

        appid: 'wxdd8d857472bfe814', // 公众号appid wx*******
        scope: 'snsapi_login', // 网页默认即可
        redirect_uri: encodeURIComponent('https://iot.neodawn.cn/wxbinding'), // 授权成功后回调的url
        response_type: 'code',
        state: Math.ceil(Math.random() * 1000), // 可设置为简单的随机数加session用来校验
        style: 'black', // 提供"black"、"white"可选。二维码的样式
        href: '' // 外部css文件url，需要https
      })
    }
  }

  function removeWx() {
    binDingWxData.removeLoading = true
    proxy.$instance.request_private.get('/mqttapi/v2/user/wechat/remove_wechat').then(res => {
      if (res.data.code == 1) {
        binDingWxData.removeLoading = false
        binDingWxData.removeDialog = false
        proxy.$message.success('解绑成功,请重新登录')
        proxy.$basic.loginOut()
        // getWxStatus()
        // getWxSaStatus()
      } else {
        proxy.$message.error('解绑失败')
        getWxStatus()
        getWxSaStatus()
      }
    })
  }

  function openBinDingWxDialog() {
    binDingWxData.dialog = true
    setWxQrc()
  }

  function closeBinDingWxDialog() {

  }

  function openRemoveWxDialog() {
    binDingWxData.removeDialog = true
  }

  function closeRemoveWxDialog() {

  }

  function openFollowDialog() {
    binDingWxData.followDialog = true
  }

  function closeFollowDialog() {

  }








  watch(() => [nickNameData, passwordData, safetyDetectionData, newPhoneNumberData, emailData],
    (oldData, newData) => {
      checkSetNickBtn()
      checkSetPasswordBtn()
      checkSafetyDetectionBtn()
      checkSetNewPhoneNumberBtn()
      checkSetEmailBtn()

    }, {
      immediate: true,
      deep: true,
    }
  )

  // ----------------------------------------------------------------------------------------
</script>

<style scoped>
  .pc-on {
    color: #67C23A;
  }

  .pc-off {
    color: #F56C6C;

  }



  .pc-main {
    position: absolute;
    /* background-color: rgba(240, 242, 251, 1); */
    /* background-image: url(../../assets/image/login/background.png); */
    background-size: 100% 100%;
    background-repeat: no-repeat;
    background-position: center;
    width: 100%;
    min-height: 100vh;
  }

  .pc-sub {
    background-color: rgba(235, 240, 249, 1);
    box-shadow: 0 0.1vw 0.2vw rgba(0, 0, 0, 0.24), 0 0 0.3vw rgba(0, 0, 0, 0.08);
    /* background-color: #DC143C; */
    position: relative;
    width: 94vw;
    height: auto;
    /* height: 43vw; */
    min-height: 43vw;
    margin-left: 3vw;
    margin-top: 3vw;
    padding-bottom: 1vw;
    border-radius: 0.9vw;
  }

  .pc-sub-header {
    position: relative;
    /* left: 6vw; */
    /* width: 88vw; */
    width: 94vw;
    height: 5vw;
    /* background-color: #333333; */
    /* border-radius: 0 0.9vw 0 0; */
  }

  .pc-sub-body {
    position: relative;
    width: 92vw;
    /* height: 36vw; */
    min-height: 37vw;
    margin-left: 1vw;
    margin-top: 1vw;
    /* left: 1vw; */
    /* top: 6vw; */
    /* background-color: #53A8FF; */
    background-color: rgba(225, 228, 235, 1);
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 0.5vw;

  }

  .pc-sub-body-inner {
    width: 90vw;
    /* height: calc(100% - 2vw); */
    min-height: 35vw;
    background-color: rgba(255, 255, 255, 1);
    border-radius: 0.5vw;
  }

  .pc_avatar.el-avatar {
    width: 7vw;
    height: 7vw;
    line-height: 7vw;
    /* border-radius: 0.5vw; */
    font-size: 3vw;
    margin-left: 10vw;
    margin-top: 2vw;
  }

  /* .pc_avatar::v-deep(.el-avatar--large)
	{
		width: 2vw;
		height: 2vw;
		line-height: 2vw;

	} */

  .pc-userinfo-block {
    margin-top: 1vw;
    margin-left: 10vw;
  }

  .pc-userinfo-title {
    font-size: 1vw;
    font-weight: bold;
  }

  .pc-userinfo-item {
    margin-top: 0.8vw;
    line-height: 0;
  }

  .pc-userinfo-value {
    /* background-color: #CF9236; */
    font-size: 0.8vw;
    text-align-last: justify;
    /* width: 10%; */
    min-width: 2.8vw;
    /* vertical-align: top; */
    /* vertical-align: middle; */
    display: inline-block;
    line-height: 1.2vw;
  }

  .pc-userinfo-punctuation {
    font-size: 0.8vw;
    line-height: 1.4vw;
    display: inline-block;
    text-align-last: justify;
    margin-left: 0.3vw;
    margin-right: 0.3vw;
  }

  .pc-userinfo-content {
    /* background-color: #15A8D6; */
    display: inline-block;
    /* width: 80%; */
    /* max-width: 10vw; */
    /* width: 50vw; */
    max-width: 50vw;
    word-break: break-all;
    font-size: 0.8vw;
    line-height: 1.4vw;
    /* min-height: 20px; */
    /* line-height: 20px; */
    margin-right: 0.8vw;
  }

  .pc-userinfo-click {
    display: inline-block;
    font-size: 0.8vw;
    line-height: 1.4vw;
    color: #409EFF;
    /* margin-left: 0.8vw; */
    cursor: pointer;
  }

  .pc-userinfo-click:hover {
    color: rgba(0, 42, 105, 1);
  }
</style>
