<template>
	<div class="lmh-main">
		<div class="lmh-main-box">
			<div class="lmh-main-box-left">
				<div class="lmh-main-box-left-system">Lab Dawn</div>
				<div class="lmh-main-box-left-title">{{title}}</div>
			</div>
			<div class="lmh-main-box-right">
				<div class="lmh-main-box-right-tips">已有账号？</div>
				<div class="lmh-main-box-right-jump" @click="headerGoLogin()">请登录></div>
			</div>
		</div>
	</div>

</template>

<script>
	// ref方法
	// reactive可传对象数组
	import {
		reactive,
		ref,
		getCurrentInstance,
		onMounted,
	} from 'vue'

	// const { proxy } = getCurrentInstance()
	// router、接口引入


	export default {

		name: 'LoginModularHeader',
		props: {
			title: String
		},
		setup(props) {
			const {
				proxy
			} = getCurrentInstance()

			const aboutdata = reactive({
				url: '',

			});

			function test() {
				// aboutdata.url = '111111111111111'
				// console.log('proxy.aboutdata.url', aboutdata.url)
			}

			function headerGoLogin() {
				proxy.$router.push({
					path: '/login',
				})
			}

			return {
				// aboutdata,
				test,
				headerGoLogin,
			};
		}

	}



	// ----------------------------------------------------------------------------------------
</script>

<style scoped>
	.lmh-main {
		width: 100%;
		height: 4vw;
		background-color: #FFFFFF;
		box-shadow: 0 0.1vw 0.2vw rgba(0, 0, 0, .12), 0 0 0.3vw rgba(0, 0, 0, .04)
	}

	.lmh-main-box {
		width: 60%;
		height: 100%;
		margin-left: 20%;
		/* background-color: #3A8EE6; */
		display: flex;
		justify-content: space-between;
		align-items: center;
	}

	.lmh-main-box-left {
		display: flex;
		align-items: center;
		/* background-color: #85CE61; */
	}

	.lmh-main-box-left-system {
		font-size: 1.6vw;
		font-weight: bold;
		color: rgba(62, 162, 249, 1);
	}

	.lmh-main-box-left-title {
		margin-left: 1vw;
		font-size: 1vw;
		font-weight: bold;
	}

	.lmh-main-box-right {
		display: flex;
		align-items: center;
		/* background-color: #DD6161; */
	}

	.lmh-main-box-right-tips {
		font-size: 0.9vw;
		color: rgba(143, 143, 143, 1);
	}

	.lmh-main-box-right-jump {
		font-size: 0.9vw;
		color: rgba(62, 162, 249, 1);
		cursor: pointer;
	}

	@media screen and (max-width:850px) {
		.lmh-main {
			height: 14vw;
			box-shadow: 0 0.4vw 0.8vw rgba(0, 0, 0, .12), 0 0 1.2vw rgba(0, 0, 0, .04)
		}
		
		.lmh-main-box {
			width: 96%;
			margin-left: 2%;
		}
		
		.lmh-main-box-left-system {
			font-size: 6vw;
		}
		
		.lmh-main-box-left-title {
			margin-left: 3vw;
			font-size: 4vw;
			font-weight: bold;
		}
		
		.lmh-main-box-right {
			display: flex;
			align-items: center;
			/* background-color: #DD6161; */
		}
		
		.lmh-main-box-right-tips {
			font-size: 4vw;
			color: rgba(143, 143, 143, 1);
		}
		
		.lmh-main-box-right-jump {
			font-size: 4vw;
			color: rgba(62, 162, 249, 1);
			cursor: pointer;
		}
		
	}
</style>
