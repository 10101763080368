

const client=null

// 内网用
// const mqttUrl = 'ws://10.0.0.46:18891/mqtt'
// 外网用
const mqttUrl = 'wss://iot.neodawn.cn:20004/mqtt'

const mqttGlobalData = {
	client,
	mqttUrl,
}

export default mqttGlobalData
