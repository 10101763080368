import { createApp } from 'vue'
import App from './App.vue'
import router from './router'
import axios from 'axios'
import VueAxios from 'vue-axios'
import store from './store'

// 剪裁插件




// UI组件导入
import ElementPlus from 'element-plus'
import 'element-plus/lib/theme-chalk/index.css'
// import zhCn from 'element-plus/es/locale/lang/zh-cn'



// js导入
import  instance  from './utils/js/http.js'
import { sha256 } from 'js-sha256'
import { Base64 } from 'js-base64'
import './utils/js/gt.js'
import errorCode from './utils/js/errorCode.js'
import basic  from './utils/js/basic.js'
// import './utils/js/mqtt.min.js'
import mqtt from 'mqtt'
import mqttGlobalData from './utils/js/mqttGlobalData.js'
import deviceConfig from './utils/js/deviceConfig.js'
import deviceHandler from './utils/js/deviceHandler.js'


// import gt from './utils/js/gt.js'

// import gt from './utils/gt.js'
// Vue.prototype.$gt = gt

// css导入
// import './index.css'
import './assets/css/common.css'
import './assets/css/deviceModular.css'




const app = createApp(App)
app.config.globalProperties.$axios = axios
app.config.globalProperties.$VueAxios = VueAxios
app.config.productionTip = false
app.config.globalProperties.$store = store

app.config.globalProperties.$instance = instance
app.config.globalProperties.$sha256 = sha256
app.config.globalProperties.$base64 = Base64
app.config.globalProperties.$errorCode = errorCode
app.config.globalProperties.$basic = basic
app.config.globalProperties.$mqtt = mqtt
app.config.globalProperties.$mqttGlobalData = mqttGlobalData
app.config.globalProperties.$deviceConfig = deviceConfig
app.config.globalProperties.$deviceHandler = deviceHandler

// app.config.globalProperties.$gt = gt

app.use(router)
app.use(store)

app.use(ElementPlus)

// app.use(ElementPlus,{
//   locale:zhCn
// })
// app.use(VueCropper)
app.mount('#app')
// createApp(App).use(router).mount('#app')
