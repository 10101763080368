<template>

  <!-- <img alt="Vue logo" src="./assets/logo.png" /> -->
  <!-- <HelloWorld msg="Hello Vue 3.0 + Vite" /> -->
  <el-config-provider :locale="globalData.locale">
    <div id="app">
      <!-- <div style="position: fixed;z-index: 10000;font-size: 2vw;">{{globalData.screenWidth}}px</div> -->
    </div>
  </el-config-provider>

  <router-view />
</template>

<script setup>
  import {
    onMounted,
    reactive,
    ref,
    getCurrentInstance,
    watch,
  } from 'vue'

  // import HelloWorld from './components/HelloWorld.vue'

  import { ElConfigProvider } from 'element-plus'
  import zhCn from 'element-plus/lib/locale/lang/zh-cn'


  const {
    proxy
  } = getCurrentInstance()

  const globalData = reactive({

    // screenWidth: document.body.clientWidth,

    locale:zhCn,
    mqttSubscribeList: proxy.$store.getters.mqttSubscribeList,
    mqttDeviceKeyObject:proxy.$store.getters.mqttDeviceKeyObject,
  });

  onMounted(() => {
    watchMqttPush()

    // window.addEventListener('resize', function() {
    //   window.screenWidth = document.body.clientWidth
    //   globalData.screenWidth = window.screenWidth
    // })

  })


  function alertGoDeviceDetails(did) {
    proxy.$router.push({
      path: '/device_details',
      query:{
        ZGlk:proxy.$base64.encode(did),
        }
    })
  }

  function watchMqttPush() {
    if (globalData.mqttSubscribeList != null && globalData.mqttSubscribeList != undefined && globalData.mqttDeviceKeyObject !=null && globalData.mqttDeviceKeyObject != undefined) {
      if (proxy.$mqttGlobalData.client != null) {
        // 获取推送
        mqttAlertPush()
      } else {
        // 获取mqtt连接信息
        proxy.$instance.request_private.get('/mqttapi/v2/user/mqtt').then(res => {
          if (res.data.code == 1) {
            proxy.$mqttGlobalData.client = null
            let mqttInfo = proxy.$basic.dataDecode(res.data.data)
            // MqttInitSocket(mqttInfo)

            // 生成实例
            proxy.$mqttGlobalData.client = proxy.$mqtt.connect(proxy.$mqttGlobalData.mqttUrl, {
              keepalive: 30,
              clientId: mqttInfo.client_id,
              protocolId: 'MQTT',
              protocolVersion: 4,
              username: proxy.$store.getters.ucUid,
              password: mqttInfo.password
            })

            // 遍历列表订阅
            let mqttSubscribeList = JSON.parse(proxy.$base64.decode(proxy.$store.getters.mqttSubscribeList))
            mqttSubscribeList.forEach(item => {
              proxy.$mqttGlobalData.client.subscribe('v2/' + item + '/get/#', {
                qos: 1
              }, () => {})
            })

            // 获取推送
            mqttAlertPush()


          } else {
            proxy.$mqttGlobalData.client = null
            // DLNI_basic_data.loading = false
            proxy.$message.error('MQTT信息获取失败|' + res.data.code)
          }
        })


      }
    }
  }

  function mqttAlertPush() {
    proxy.$mqttGlobalData.client.on('message', (topic, message, packet) => {

      let result = []
      let result_str = ''
      let result_obj = {}

      message.forEach(item => {
        let asciiCode = parseInt(item, 16)
        let charValue = String.fromCharCode(item)
        result.push(charValue)
      })

      result.forEach(ritem => {
        result_str = result_str + ritem
      })

      result_obj = JSON.parse(result_str)
      let reg = /v2[/\\](.*)[/\\]get/
      let topic_did = topic.match(reg)[1]

      // console.log('topic_did', topic_did)

      if (result_obj.emid == 'e10') {
        let alertType = {
          'below': '低温报警',
          'over': '高温报警',
          'off': '超温报警'
        }

        let alertText = {
          'below': '温度过低',
          'over': '温度过高',
          'off': '已超温关机'
        }

        let keyObj = JSON.parse(proxy.$base64.decode(proxy.$store.getters.mqttDeviceKeyObject))

        // proxy.$notify({
        //   title: alertType[result_obj.arguments[0]],
        //   // type: 'warning',
        //   dangerouslyUseHTMLString: true,
        //   message: '<div>'+ topic_did + alertText[result_obj.arguments[0]] +'</div> <div style="color:#409EFF;cursor: pointer;width: 100%;margin-top: 10px;">立即查看</div>',
        //   duration: 10000,
        // });

        proxy.$notify({
          title: alertType[result_obj.arguments[0]],
          message: keyObj[topic_did] + alertText[result_obj.arguments[0]],
          type: 'warning',
          duration:10000,

        });

      }

      // console.log('result_obj', result_obj)
      // console.log('topic', topic_did)
      // if (result_obj.emid == 'e10') {
      // 	let type = {
      // 		'below':'温度过低！！！',
      // 		'over':'温度过高！！！',
      // 		'off':'超温！！！已关机'
      // 	}
      // 	let color = {
      // 		'below':'primary',
      // 		'over':'error',
      // 		'off':'error'
      // 	}
      // 	this.$refs.uTips.show({
      // 		title: type[result_obj.arguments[0]],
      // 		type: color[result_obj.arguments[0]],
      // 		duration: '5000'
      // 	})

    })
  }





  watch(() => globalData,
    (oldData, newData) => {
      // watchMqttPush()
      // checkLoginBtnDisabled()

      // console.log('wxCode', loginData.wxCode)
    }, {
      immediate: true,
      deep: true,
    }
  )



  // export default {
  //   name: 'App',
  //   components: {
  //     HelloWorld
  //   }
  // }
</script>
