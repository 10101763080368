<template>
	<div class="forget-main">
		<LoginModularHeader title="找回密码"></LoginModularHeader>

		<div class="forget-main-inner">
			<div class="forget-main-inner-from">

				<el-form class="my-form" label-position="top" ref="forgetRef" :model="forgetData.forgetForm"
					:rules="forgetData.forgetFormRule">
					<el-form-item label="手机号" prop="phone_number">
						<el-input class="my-input" v-model.trim="forgetData.forgetForm.phone_number"></el-input>
					</el-form-item>
					<el-form-item label="验证码" prop="code">
						<div style="display: flex;justify-content: space-between;">
							<el-input class="my-input" v-model.trim="forgetData.forgetForm.code" style="width: 55%;">
							</el-input>
							<el-button id="getCodeBtn" class="my-formbtn" type="primary"
								v-loading.fullscreen.lock="forgetData.getCodeLoading">
								{{forgetData.time}}{{forgetData.suffix}}
							</el-button>
						</div>
					</el-form-item>
					<el-form-item label="密码" prop="password">
						<el-input class="my-input" v-model.trim="forgetData.forgetForm.password" type="password"
							show-password>
						</el-input>
					</el-form-item>
					<el-form-item label="确认密码" prop="checkpassword">
						<el-input class="my-input" v-model.trim="forgetData.forgetForm.checkpassword" type="password"
							show-password>
						</el-input>
					</el-form-item>
				</el-form>

				<div class="forget-main-inner-btn">
					<el-button class="my-btn" type="primary" @click="resetPassword()"
						:disabled="forgetData.forgetDisabled" v-loading.fullscreen.lock="forgetData.forgetLoading">
						确 定
					</el-button>
				</div>



			</div>
		</div>


	</div>

</template>

<script setup>
	// ref方法
	// reactive可传对象数组
	import {
		reactive,
		ref,
		getCurrentInstance,
		onMounted,
		watch
	} from 'vue'
	import LoginModularHeader from '../../components/login_modular/LoginModularHeader.vue'
	// router、接口引入
	const {
		proxy
	} = getCurrentInstance()

	onMounted(() => {
		geetestInitialize()
	})

	// 表单用验证码规则
	const isCheckPassword = (rule, value, callback) => {
		if (value != forgetData.forgetForm.password) {
			callback(new Error('两次输入密码不一致'));
		} else {
			callback();
		}
	}

	// 极验参数
	const geetestData = reactive({
		gt: '',
		challenge: '',
		offline: '',
		new_captcha: '',
	});

	// 找回密码参数
  const forgetRef = ref(null)
	const forgetData = reactive({

		// 提交
		forgetDisabled: true,
		forgetLoading: false,

		// 验证码
		time: "获取验证码",
		currentTime: 0,
		suffix: null,
		getCodeLoading: false,

		forgetForm: {
			phone_number: null,
			code: null,
			password: null,
			checkpassword: null
		},

		forgetFormRule: {
			phone_number: [{
					required: true,
					message: '请输入手机号',
					trigger: 'blur'
				},
				{
					validator: proxy.$basic.isPhoneNumber_el,
					trigger: 'blur'
				}

			],
			code: [{
					required: true,
					message: '请输入验证码',
					trigger: 'blur'
				},
				{
					validator: proxy.$basic.isVerificationCode_el,
					trigger: 'blur'
				}
			],

			password: [{
					required: true,
					message: '请输入新密码',
					trigger: 'blur'
				},
				{
					min: 8,
					max: 16,
					message: '密码必须在8~16个字符',
					trigger: 'blur'
				}
			],
			checkpassword: [{
					required: true,
					message: '请再次输入新密码',
					trigger: 'blur'
				},
				{
					validator: isCheckPassword,
					trigger: 'blur'
				},
			],
		}

	})


	function checkResetBtnDisabled() {
		if (
			proxy.$basic.isPhoneNumber(forgetData.forgetForm.phone_number) &&
			proxy.$basic.isVerificationCode(forgetData.forgetForm.code) &&
			forgetData.forgetForm.password != null &&
			forgetData.forgetForm.password.length >= 8 &&
			forgetData.forgetForm.password.length <= 16 &&
			forgetData.forgetForm.password == forgetData.forgetForm.checkpassword

		) {
			forgetData.forgetDisabled = false
		} else {
			forgetData.forgetDisabled = true
		}
	}

	watch(() => forgetData,
		(oldData, newData) => {
			checkResetBtnDisabled()
		}, {
			immediate: true,
			deep: true,
		}
	)

	// 极验初始化
	function geetestInitialize() {
		proxy.$instance.request_public.get('/mqttapi/v2/geetest/initialize').then(res => {
			if (res.data.code == 1) {
				let gtdata = proxy.$basic.dataDecode(res.data.data)
				initGeetest({
					gt: gtdata.gt,
					challenge: gtdata.challenge,
					offline: !gtdata.success,
					new_captcha: gtdata.new_captcha,
					width: '100%',
					product: 'bind',
				}, function(captchaObj) {
					captchaObj.onReady(function() {

					}).onSuccess(function() {
						let result = captchaObj.getValidate()
						// console.log('result', result)
						if (!result) {
							proxy.$message.error('人机验证失败')
							// console.log('error')
						} else {
							// forgetData.getCodeLoading = proxy.$loading({
							// 	lock: true,
							// 	text: '正在获取验证码...',
							// 	spinner: 'el-icon-loading',
							// 	background: 'rgba(0, 0, 0, 0.7)'
							// });
							forgetData.getCodeLoading = true
							// 验证通过后进行获取验证码
							proxy.$instance.request_public.post('/mqttapi/v2/user/sms/send', {
								phone_number: forgetData.forgetForm.phone_number,
								type: 1,
								validate: result.geetest_validate,
								seccode: result.geetest_seccode,
								challenge: result.geetest_challenge
							}).then(res => {
								if (res.data.code == 1) {
									let data = proxy.$basic.dataDecode(res.data.data)
									checkTask(data.task_id, 0)
									// console.log('data',data)
									// loginLoading.close();
								} else {
									forgetData.getCodeLoading = false
									// forgetData.getCodeLoading.close();
									proxy.$message.error(proxy.$errorCode[res.data.code])
								}
							})
							// console.log('ok')
						}
					}).onError(function() {
						// console.log('error2')
						proxy.$message.error('人机验证未通过')
					}).onClose(function() {
						// console.log('close')
					})
					// document.getElementById('button1')
					document.getElementById('getCodeBtn').addEventListener('click', function() {
						// console.log('click',click)
						// console.log('loginbtn', loginbtn)
						if (getCodeBtn) { // 检查是否可以进行提交
							if (forgetData.currentTime <= 0) {
								if (proxy.$basic.isPhoneNumber(forgetData.forgetForm
										.phone_number)) {
									captchaObj.verify();
								} else {
									proxy.$message.error('请输入正确的手机号')
								}
							} else {
								proxy.$message.error('倒计时结束后再请求')
							}

						}
					});

				})
			} else {
				proxy.$message.error('人机验证发生错误')
			}
		})
	}

	// 确认验证码发送情况
	function checkTask(task_id, die) {
		proxy.$instance.request_public.get('/mqttapi/v2/user/sms/task', {
			params: {
				task_id: task_id
			}
		}).then(res => {
			if (res.data.code == 1) {
				forgetData.getCodeLoading = false
				// forgetData.getCodeLoading.close();
				proxy.$message.success('验证码已发送')
				codeStart()
			} else if (res.data.code == 2000) {
				if (die < 20) {
					setTimeout(() => {
						checkTask(task_id, die++)
					}, 1000)
				} else {
					forgetData.getCodeLoading = false
					// forgetData.getCodeLoading.close();
					proxy.$message.error('验证码发送失败')
				}
			} else {
				forgetData.getCodeLoading = false
				// forgetData.getCodeLoading.close();
				proxy.$message.error(proxy.$errorCode[res.data.code])
			}
		})
	}

	// 倒计时
	function codeStart() {
		forgetData.currentTime = proxy.$basic.config_js.countDown
		let interval = null;
		interval = setInterval(function() {
			forgetData.currentTime--;
			forgetData.time = forgetData.currentTime
			forgetData.suffix = '秒'
			if (forgetData.currentTime <= 0) {
				clearInterval(interval)
				forgetData.currentTime = proxy.$basic.config_js.countDown
				forgetData.suffix = null
				forgetData.time = '重新发送'
			}
		}, 1000)
	}


	// 重置密码
	function resetPassword() {
		forgetRef.value.validate((valid) => {
			if (valid) {
				// forgetData.forgetLoading = proxy.$loading({
				// 	lock: true,
				// 	text: '正在重置密码...',
				// 	spinner: 'el-icon-loading',
				// 	background: 'rgba(0, 0, 0, 0.7)'
				// });
				forgetData.forgetLoading = true
				proxy.$instance.request_public.post('/mqttapi/v2/user/forget', {
					phone_number: forgetData.forgetForm.phone_number,
					new_password: proxy.$sha256(forgetData.forgetForm.password),
					sms_code: forgetData.forgetForm.code
				}).then(res => {
					if (res.data.code == 1) {
						forgetData.forgetLoading = false
						// forgetData.forgetLoading.close();
						proxy.$message.success('重置成功')
						proxy.$router.push({
							path: '/login',
						})
					} else {
						forgetData.forgetLoading = false
						// forgetData.forgetLoading.close();
						proxy.$message.error(proxy.$errorCode[res.data.code])
					}
				})
			}
		})
	}



	// ----------------------------------------------------------------------------------------
</script>

<style scoped>
	.forget-main {
		width: 100vw;
		min-height: 100vh;
		/* background-color: #C2E7B0; */
	}

	.forget-main-inner {
		display: flex;
		justify-content: center;
	}

	.forget-main-inner-from {
		margin-top: 2vw;
		margin-bottom: 4vw;
		width: 15vw;
	}

	.forget-main-inner-btn {
		margin-top: 4vw;
	}


	@media screen and (max-width:1600px) {
		.forget-main-inner-from {
			margin-top: 2vw;
			width: 20vw;
		}
	}

	@media screen and (max-width:1200px) {
		.forget-main-inner-from {
			margin-top: 4vw;
			width: 25vw;
		}
	}

	@media screen and (max-width:850px) {
		.forget-main-inner-from {
			margin-top: 5vw;
			width: 80vw;
		}

		.forget-main-inner-btn {
			margin-top: 15vw;
		}

	}
</style>
