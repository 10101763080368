<template>
  <div class="login-main">

    <div class="login-box">

      <div class="login-box-left">
        <img class="login-box-left-img" src="../../assets/image/login/illustration.png">
      </div>

      <div class="login-box-right">

        <div class="login-box-right-leftbox"></div>

        <div class="login-box-right-midbox">

          <div id="wxQrcCode" v-show="loginData.qrcLogin!=false">

          </div>

          <div class="login-box-right-midbox-main" v-show="loginData.qrcLogin==false">
            <div class="login-box-right-midbox-main-title">
              Lab Dawn
            </div>
            <div class="login-box-right-midbox-main-value">
              账号登录
            </div>
            <div>
              <el-form class="my-form"  :model="loginData.loginForm" :rules="loginData.loginFormRule"
                label-position="top" hide-required-asterisk>
                <el-form-item label="手机号/邮箱" prop="username">
                  <el-input class="my-input" v-model="loginData.loginForm.username"></el-input>
                </el-form-item>
                <el-form-item label="密码" prop="password">
                  <el-input class="my-input" type="password" v-model="loginData.loginForm.password" show-password>
                  </el-input>
                </el-form-item>
              </el-form>
            </div>


            <div class="login-box-right-midbox-main-agreement">
              <el-checkbox class="my-checkbox" v-model="loginData.agreement"></el-checkbox>
              <div class="login-box-right-midbox-main-agreement-text">
                <span>已阅读并同意</span>
                <span style="cursor: pointer;color: #409EFF;font-weight: bold;" @click="goAgreement()">《用户协议》</span>
                <span>&</span>
                <span style="cursor: pointer;color: #409EFF;font-weight: bold;" @click="goPrivacypolicy()">《隐私声明》</span>
              </div>
            </div>

            <div class="login-box-right-midbox-main-btn">
              <el-button id="loginbtn" class="my-btn" type="primary" :disabled="loginData.loginDisabled"
                v-loading.fullscreen.lock="loginData.loginLoading">登 录</el-button>
            </div>

            <div class="login-box-right-midbox-main-other">
              <div class="login-box-right-midbox-main-other-item" @click="loginData.mnaDialog=true">
                注册账号
              </div>

              <div class="login-box-right-midbox-main-other-item" @click="goForget()">
                忘记密码
              </div>
            </div>


          </div>
        </div>

        <div class="login-box-right-rightbox">
          <img class="login-box-right-rightbox-logintype" src="../../assets/image/login/pc.png" @click="changeLoginType()" v-show="loginData.qrcLogin!=false">
          <img class="login-box-right-rightbox-logintype" src="../../assets/image/login/qrc.png" @click="changeLoginType()" v-show="loginData.qrcLogin==false">
        </div>

      </div>
    </div>

    <div class="my-userDialog">

      <el-dialog title="LabDawn注册注意事项" v-model="loginData.mnaDialog">

          <div style="width: 100%;">
            <div style="font-size: 0.8vw;text-indent:1.6vw;">
              <b>【请您注意】</b>如果您不同意以下协议全部或任何条款约定，请您停止注册。您停止注册后将无法享受我们完整的产品或服务。
              继续注册流程并点击“同意并继续”即表示您已充分阅读、理解并接受协议的全部内容，并表明您同意我们可以依据协议的内容来处理您的个人信息。
            </div>



            <div style="text-align: center;margin-top: 1vw;font-size: 0.8vw;">
              <span style="cursor: pointer;color: #409EFF;font-weight: bold;" @click="goAgreement()">《用户协议》</span>
              <span>&</span>
              <span style="cursor: pointer;color: #409EFF;font-weight: bold;" @click="goPrivacypolicy()">《隐私声明》</span>
            </div>
          </div>

          <template #footer>
            <span class="dialog-footer" style="">
              <el-button class="my-btn" type="primary" @click="goRegister()">同意并继续
              </el-button>

            </span>
          </template>

      </el-dialog>


      <el-dialog title="微信登录注意事项" v-model="loginData.qrcCheckDialog">

          <div style="width: 100%;">
            <div style="font-size: 0.8vw;text-indent:1.6vw;">
              <b>【请您注意】</b>如果您不同意以下协议全部或任何条款约定，请您取消微信登录。您停止微信登录后将无法享受我们完整的产品或服务。
              点击“同意并继续”即表示您已充分阅读、理解并接受协议的全部内容，并表明您同意我们可以依据协议的内容来处理您的个人信息。
            </div>



            <div style="text-align: center;margin-top: 1vw;font-size: 0.8vw;">
              <span style="cursor: pointer;color: #409EFF;font-weight: bold;" @click="goAgreement()">《用户协议》</span>
              <span>&</span>
              <span style="cursor: pointer;color: #409EFF;font-weight: bold;" @click="goPrivacypolicy()">《隐私声明》</span>
            </div>
          </div>

          <template #footer>
            <span class="dialog-footer" style="">
              <el-button class="my-btn" type="primary" @click="goWxLogin()">同意并继续
              </el-button>

            </span>
          </template>

      </el-dialog>


    </div>


  </div>

</template>



<script setup>
  // import { ElMessage } from 'element-plus'
  // import '../utils/js/gt.js'
  // ref方法
  // reactive可传对象数组
  import {
    reactive,
    ref,
    getCurrentInstance,
    onMounted,
    watch
  } from 'vue'


  // router引入
  const {
    proxy
  } = getCurrentInstance()

  onMounted(() => {
    geetestInitialize()
  })

  function goAgreement()
  {
    window.open('https://www.neodawn.cn/file/license/agreement.htm','_blank')
  }

  function goPrivacypolicy()
  {
    window.open('https://www.neodawn.cn/file/license/privacypolicy.htm','_blank')
  }



  function goRegister() {
    proxy.$router.push({
      path: '/register',
    })
  }

  function goForget() {
    proxy.$router.push({
      path: '/forget',
    })
  }

  function changeLoginType() {

    if(loginData.qrcLogin==true)
    {
      loginData.qrcLogin=false
    }
    else
    {
      loginData.qrcCheckDialog = true
    }

    // loginData.qrcLogin = !loginData.qrcLogin
    // setWxQrc()
  }

  function goWxLogin()
  {
    loginData.qrcCheckDialog = false
    loginData.qrcLogin=true
    setWxQrc()
  }

  // 微信登录
  function setWxQrc() {
    const s = document.createElement('script')
    s.type = 'text/javascript'
    s.src = 'https://res.wx.qq.com/connect/zh_CN/htmledition/js/wxLogin.js'
    const wxElement = document.body.appendChild(s)
    wxElement.onload = function() {
      var obj = new WxLogin({
        id: 'wxQrcCode', // 需要显示的容器id

        appid: 'wxdd8d857472bfe814', // 公众号appid wx*******
        scope: 'snsapi_login', // 网页默认即可
        redirect_uri: encodeURIComponent('https://iot.neodawn.cn/wxlogin'), // 授权成功后回调的url
        response_type: 'code',
        state: Math.ceil(Math.random() * 1000), // 可设置为简单的随机数加session用来校验
        style: 'black', // 提供"black"、"white"可选。二维码的样式
        href: '' // 外部css文件url，需要https
      })
    }
  }


  // 登录相关
  const loginData = reactive({
    agreement: false,
    loginDisabled: true,
    loginLoading: false,
    mnaDialog:false,

    qrcCheckDialog:false,
    qrcLogin: false,

    loginForm: {
      username: null,
      password: null,
    },

    loginFormRule: {
      username: [{
        required: true,
        message: '请输入手机号/邮箱',
        trigger: 'blur'
      }, ],
      password: [{
        required: true,
        message: '请输入密码',
        trigger: 'blur'
      }, ],
    },
  })

  function checkLoginBtnDisabled() {
    if (loginData.loginForm.username != null && loginData.loginForm.password != null && loginData.agreement == true) {
      loginData.loginDisabled = false
    } else {
      loginData.loginDisabled = true
    }

  }


  // 极验初始化
  const geetestData = reactive({
    gt: '',
    challenge: '',
    offline: '',
    new_captcha: '',
  });


  function geetestInitialize() {
    proxy.$instance.request_public.get('/mqttapi/v2/geetest/initialize').then(res => {
      if (res.data.code == 1) {
        let gtdata = proxy.$basic.dataDecode(res.data.data)
        initGeetest({
          gt: gtdata.gt,
          challenge: gtdata.challenge,
          offline: !gtdata.success,
          new_captcha: gtdata.new_captcha,
          width: '100%',
          product: 'bind',
        }, function(captchaObj) {
          captchaObj.onReady(function() {

          }).onSuccess(function() {
            let result = captchaObj.getValidate()
            // console.log('result', result)
            if (!result) {
              proxy.$message.error('人机验证失败')
              // console.log('error')
            } else {
              // const loginLoading = proxy.$loading({
              // 	lock: true,
              // 	text: '登录中...',
              // 	spinner: 'el-icon-loading',
              // 	background: 'rgba(0, 0, 0, 0.7)'
              // });
              loginData.loginLoading = true
              // 验证通过后进行登录
              proxy.$instance.request_public.post('/mqttapi/v2/user/login', {
                username: loginData.loginForm.username,
                password: proxy.$sha256(loginData.loginForm.password),
                validate: result.geetest_validate,
                seccode: result.geetest_seccode,
                challenge: result.geetest_challenge
              }).then(res => {
                if (res.data.code == 1) {
                  let data = proxy.$basic.dataDecode(res.data.data)
                  proxy.$store.commit('setJwt', data.access_token)
                  proxy.$store.commit('setRefreshToken', data
                    .refresh_token)

                  proxy.$basic.getCurrentUserInfo().then((
                    info_result) => {
                    if (info_result == 'success') {
                      loginData.loginLoading = false
                      // loginLoading.close();
                      proxy.$message.success('登录成功')
                      proxy.$router.push({
                        path: '/home',
                      })
                    } else {
                      loginData.loginLoading = false
                      // loginLoading.close();
                      proxy.$message.error('用户信息获取失败')
                      proxy.$router.push({
                        path: '/home',
                      })
                    }
                  })
                  // console.log('data',data)
                  // loginLoading.close();
                } else {
                  loginData.loginLoading = false
                  // loginLoading.close();
                  proxy.$message.error(proxy.$errorCode[res.data.code])
                }
              })

              // console.log('ok')
            }
          }).onError(function() {
            // console.log('error2')
            proxy.$message.error('人机验证未通过')
          }).onClose(function() {
            // console.log('close')
          })

          // document.getElementById('button1')

          if (document.getElementById('loginbtn') == null) {
            return false
          } else {
            document.getElementById('loginbtn').addEventListener('click', function() {
              // console.log('click',click)
              // console.log('loginbtn', loginbtn)
              if (loginbtn) { // 检查是否可以进行提交

                // if (loginData.loginForm.username != null && loginData.loginForm.password != null) {
                //   if (loginData.agreement == true) {
                //     captchaObj.verify();
                //   } else {
                //     proxy.$message.warning({
                //       message: '请先阅读并同意《用户协议》、《隐私权声明》',
                //     })
                //   }
                // } else {
                //   return false;
                // }

                captchaObj.verify();
              }
            });
          }

        })
      } else {
        proxy.$message.error('人机验证发生错误')
      }
    })
  }

  function wxLogin() {
    loginData.loginLoading = true
    proxy.$instance.request_public.post('/mqttapi/v2/user/wechat/wechat_login_web', {
      code: loginData.wxCode
    }).then(res => {
      if (res.data.code == 1) {
        let data = proxy.$basic.dataDecode(res.data.data)
        proxy.$store.commit('setJwt', data.access_token)
        proxy.$store.commit('setRefreshToken', data.refresh_token)
        proxy.$basic.getCurrentUserInfo().then((
          info_result) => {
          if (info_result == 'success') {
            loginData.loginLoading = false
            // loginLoading.close();
            proxy.$message.success('登录成功')
            proxy.$router.push({
              path: '/home',
            })
          } else {
            loginData.loginLoading = false
            // loginLoading.close();
            proxy.$message.error('用户信息获取失败')
            proxy.$router.push({
              path: '/home',
            })
          }
        })

      } else {
        loginData.loginLoading = false
        proxy.$router.push({
          path: '/login',
        })
        proxy.$message.error(proxy.$errorCode[res.data.code])
      }
    })
  }



  watch(() => loginData,
    (oldData, newData) => {
      checkLoginBtnDisabled()

      // console.log('wxCode', loginData.wxCode)
    }, {
      immediate: true,
      deep: true,
    }
  )
  // 极验初始化
  // geetestInitialize() {
  // 	this.instance.request_public('get', '/mqttapi/v2/geetest/initialize').then(res => {
  // 		if (res.data.code == 1) {
  // 			let data = this.basic.dataDecode(res.data.data)
  // 			this.gtObj.gt = data.gt
  // 			this.gtObj.loadCaptcha = data.new_captcha
  // 			this.gtObj.offline = !data.success
  // 			this.gtObj.challenge = data.challenge
  // 		} else {
  // 			uni.showToast({
  // 				title: '人机验证发生错误',
  // 				duration: 2000,
  // 				icon: 'none'
  // 			})
  // 		}
  // 	})
  // },






  // ----------------------------------------------------------------------------------------
</script>

<style scoped>
  .login-main {
    position: absolute;
    /* background-color: #3A8EE6; */
    background-color: rgba(240, 242, 251, 1);
    background-image: url(../../assets/image/login/background.png);
    background-size: 100% 100%;
    background-repeat: no-repeat;
    background-position: center;
    width: 100%;
    min-height: 100vh;
  }

  .login-box {
    width: 48vw;
    height: 24vw;
    margin-left: 26vw;
    margin-top: 20vh;
    margin-bottom: 10vh;
    /* background-color: #13CE66; */
    border-radius: 2vw;
    display: flex;
    justify-content: center;
    align-items: center;
  }

  .login-box-left {
    position: relative;
    width: 50%;
    height: 100%;
    background-color: rgba(62, 162, 249, 1);
    border-radius: 2vw 0 0 2vw;
    /* -moz-border-radius-topleft: 2vw; */
    /* -moz-border-radius-bottomleft: 2vw; */
  }

  .login-box-left-img {
    position: relative;
    width: 100%;
    height: 100%;
  }

  .login-box-right {
    position: relative;
    width: 50%;
    height: 100%;
    background-color: #FFFFFF;
    border-radius: 0 2vw 2vw 0;
    display: flex;
    justify-content: center;
    align-items: center;
  }

  .login-box-right-leftbox {
    position: relative;
    width: 3vw;
    height: 22vw;
    /* background-color: #A6A9AD; */
  }

  .login-box-right-midbox {
    position: relative;
    width: 16vw;
    height: 22vw;
    /* background-color: #A4DA89; */
    display: flex;
    justify-content: center;
    align-items: center;
  }

  .login-box-right-midbox-main {
    width: 14vw;
    height: 20vw;
    /* background-color: #F0C78A; */
  }

  .login-box-right-midbox-main-title {
    font-size: 1.1vw;
    font-weight: bold;
    margin-bottom: 1.6vw;
  }

  .login-box-right-midbox-main-value {
    font-size: 1.1vw;
    font-weight: bold;
    margin-bottom: 1.2vw;
  }

  .login-box-right-midbox-main-agreement {
    display: flex;
    margin-bottom: 0.6vw;
    align-items: center;
  }

  .login-box-right-midbox-main-agreement-text {
    font-size: 0.6vw;
    margin-left: 0.3vw;
    /* display: flex; */
    /* align-items: center; */
    /* line-height: a; */
    /* background-color: red; */
    /* margin-top: -0.2vw; */
  }


  .login-box-right-midbox-main-btn {
    display: flex;
    margin-bottom: 0.6vw;
  }

  .login-box-right-midbox-main-other {
    display: flex;
    justify-content: space-between;
  }

  .login-box-right-midbox-main-other-item {
    color: rgba(172, 172, 172, 1);
    font-size: 0.7vw;
    cursor: pointer;
  }

  .login-box-right-midbox-main-other-item:hover {
    color: rgba(62, 162, 249, 1);
  }



  .login-box-right-rightbox {
    position: relative;
    width: 3vw;
    height: 22vw;
    /* background-color: #A6A9AD; */
  }

  .login-box-right-rightbox-logintype {
    width: 3vw;
    height: 3vw;
    cursor: pointer;
  }


  @media screen and (max-width:1780px) {
    .login-box {
      width: 58vw;
      height: 29vw;
      margin-left: 21vw;
      margin-top: 20vh;
      margin-bottom: 10vh;
      border-radius: 2vw;
    }


    .login-box-right-leftbox {
      width: 3vw;
      height: 25vw;
    }

    .login-box-right-midbox {
      width: 18vw;
      height: 25vw;
    }

    .login-box-right-midbox-main {
      width: 16vw;
      height: 21vw;
    }

    .login-box-right-midbox-main-title {
      font-size: 1.1vw;
      margin-bottom: 1.6vw;
    }

    .login-box-right-midbox-main-value {
      font-size: 1vw;
      margin-bottom: 1.2vw;
    }

    .login-box-right-midbox-main-agreement {
      margin-bottom: 0.6vw;
    }

    .login-box-right-midbox-main-agreement-text {
      font-size: 0.6vw;
      margin-left: 0.3vw;
    }


    .login-box-right-midbox-main-btn {
      margin-bottom: 0.6vw;
    }


    .login-box-right-midbox-main-other-item {
      font-size: 0.7vw;
    }

    .login-box-right-rightbox {
      width: 3vw;
      height: 25vw;
    }
  }

  @media screen and (max-width:1600px) {
    .login-box {
      width: 70vw;
      height: 35vw;
      margin-left: 15vw;
      margin-top: 20vh;
      margin-bottom: 10vh;
      border-radius: 2vw;
    }


    .login-box-right-leftbox {
      width: 3vw;
      height: 31vw;
    }

    .login-box-right-midbox {
      width: 25vw;
      height: 31vw;
    }

    .login-box-right-midbox-main {
      width: 21vw;
      height: 27vw;
    }

    .login-box-right-midbox-main-title {
      font-size: 1.8vw;
      margin-bottom: 1.8vw;
    }

    .login-box-right-midbox-main-value {
      font-size: 1.5vw;
      margin-bottom: 1.4vw;
    }

    .login-box-right-midbox-main-agreement {
      margin-bottom: 1.5vw;
    }

    .login-box-right-midbox-main-agreement-text {
      font-size: 0.9vw;
      margin-left: 0.3vw;
    }


    .login-box-right-midbox-main-btn {
      margin-bottom: 1.5vw;
    }


    .login-box-right-midbox-main-other-item {
      font-size: 1vw;
    }

    .login-box-right-rightbox {
      width: 3vw;
      height: 31vw;
    }
  }

  @media screen and (max-width:1200px) {
    .login-box {
      width: 90vw;
      height: 45vw;
      margin-left: 5vw;
      margin-top: 20vh;
      margin-bottom: 10vh;
      border-radius: 2vw;
    }


    .login-box-right-leftbox {
      width: 3vw;
      height: 41vw;
    }

    .login-box-right-midbox {
      width: 35vw;
      height: 41vw;
    }

    .login-box-right-midbox-main {
      width: 31vw;
      height: 37vw;
    }

    .login-box-right-midbox-main-title {
      font-size: 2.2vw;
      margin-bottom: 3vw;
    }

    .login-box-right-midbox-main-value {
      font-size: 1.8vw;
      margin-bottom: 2.5vw;
    }

    .login-box-right-midbox-main-agreement {
      margin-bottom: 4vw;

    }

    .login-box-right-midbox-main-agreement-text {
      font-size: 0.9vw;
      margin-left: 0.3vw;
    }


    .login-box-right-midbox-main-btn {
      margin-bottom: 2vw;
    }


    .login-box-right-midbox-main-other-item {
      font-size: 1.2vw;
    }

    .login-box-right-rightbox {
      width: 3vw;
      height: 41vw;
    }
  }

  @media screen and (max-width:850px) {
    .login-box {
      width: 100%;
      height: auto;
      min-height: 100vh;
      margin-left: 0;
      margin-top: 0;
      margin-bottom: 0;
      border-radius: 0;
      align-items: initial;

    }

    .login-box-left {
      display: none;
    }

    .login-box-right {
      width: 100%;
      height: 100%;
      background-color: #FFFFFF;
      border-radius: 0 0 0 0;
      display: flex;
      justify-content: center;
      /* align-items: center; */
    }

    .login-box-right-leftbox {
      width: 10vw;
      height: auto;
      min-height: 100vh;
    }

    .login-box-right-midbox {
      width: 80vw;
      height: auto;
      min-height: 100vh;
      align-items: initial;
    }

    .login-box-right-midbox-main {
      width: 76vw;
      height: calc(100vh - 20vw);
      margin-top: 10vw;
    }

    .login-box-right-midbox-main-title {
      font-size: 6vw;
      margin-bottom: 10vw;
    }

    .login-box-right-midbox-main-value {
      font-size: 5vw;
      margin-bottom: 8vw;
    }

    .login-box-right-midbox-main-agreement {
      margin-bottom: 12vw;

    }

    .login-box-right-midbox-main-agreement-text {
      font-size: 3.5vw;
      margin-left: 1vw;
    }


    .login-box-right-midbox-main-btn {
      margin-bottom: 10vw;
    }


    .login-box-right-midbox-main-other-item {
      font-size: 5vw;
    }

    .login-box-right-rightbox {
      width: 10vw;
      height: auto;
      min-height: 100vh;
    }

    .login-box-right-rightbox-logintype {
      width: 10vw;
      height: 10vw;
    }

  }
</style>
