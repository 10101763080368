<template>

  <div class="dd-d1-basicinfo">
    <div style="display: flex;">
      <!-- 设备名备注 -->
      <div class="dd-d1-basicinfo-NameAndRemark">
        <div class="dd-d1-basicinfo-NameAndRemark-text">
          <div class="dd-d1-name">{{detailsData.info.name}}</div>
          <div class="dd-d1-remark">{{detailsData.info.remark==null?' ':detailsData.info.remark}}</div>
        </div>
      </div>

      <!-- 权级设备组 -->
      <div class="dd-d1-basicinfo-GroupAndPermission">
        <!-- 权限组 -->
        <div class="dd-d1-basicinfo-Group">
          <div class="dd-d1-group">

            <div class="dd-d1-iconbox">
              <div>
                <div class="dd-d1-iconalign">
                  <el-icon class="dd-d1-icon">
                    <Files />
                  </el-icon>
                </div>
                <div class="dd-d1-icontext">
                  <!-- 所属设备组 -->
                  设备组
                </div>
              </div>
            </div>

            <div class="dd-d1-grouptext">
              {{detailsData.info.device_group==undefined?'无':detailsData.info.device_group.name}}
              <!-- {{detailsData.info.device_group.name===undefined?'无':detailsData.info.device_group.name}} -->
            </div>
          </div>

        </div>

        <!-- 权级 -->
        <div class="dd-d1-basicinfo-Permission">
          <div class="dd-d1-permission">

            <div class="dd-d1-iconbox">
              <div>
                <div class="dd-d1-iconalign">
                  <el-icon class="dd-d1-icon">
                    <Stamp />
                  </el-icon>
                </div>
                <div class="dd-d1-icontext">
                  当前权级
                </div>
              </div>
            </div>

            <div class="dd-d1-permissiontext">
              {{$basic.permissionType[detailsData.info.permission]}}
              <!-- {{detailsData.info.device_group.name===undefined?'无':detailsData.info.device_group.name}} -->
            </div>
          </div>
        </div>
      </div>


      <!-- 各种状态 -->
      <div class="dd-d1-basicinfo-Status">
        <div class="dd-d1-basicinfo-Status-lin1">
          <!-- 锁定状态 -->
          <div class="dd-d1-basicinfo-HoldStatus">
            <div class="dd-d1-iconbox">
              <div>
                <div class="dd-d1-iconalign">
                  <el-icon
                    :class="'dd-d1-icon' + ' ' + (detailsData.info.mqtt_online==true?(detailsData.info.lock_state==true?'dd-d1-online':'dd-d1-offline'):'dd-d1-offline')">
                    <Unlock v-show="detailsData.info.lock_state!=true"></Unlock>
                    <Lock v-show="detailsData.info.lock_state==true"></Lock>
                    <!-- Lock -->
                    <!-- Unlock -->
                  </el-icon>
                </div>
                <div class="dd-d1-icontext">
                  {{detailsData.info.mqtt_online==false?'设备未锁定':(detailsData.info.lock_state==true?'设备已锁定':'设备未锁定')}}
                </div>
              </div>
            </div>
          </div>

          <!-- mqtt在线状态 -->
          <div class="dd-d1-basicinfo-HoldStatus">
            <div class="dd-d1-iconbox">
              <div>
                <div class="dd-d1-iconalign">
                  <el-icon
                    :class="'dd-d1-icon' + ' ' + (detailsData.info.mqtt_online==true?'dd-d1-online':'dd-d1-offline')">
                    <UploadFilled />
                  </el-icon>
                </div>
                <div class="dd-d1-icontext">
                  {{detailsData.info.mqtt_online==true?'设备在线':'设备离线'}}
                </div>
              </div>
            </div>
          </div>

          <!-- 设备连接状态 -->
          <div class="dd-d1-basicinfo-HoldStatus">
            <div class="dd-d1-iconbox">
              <div>
                <div class="dd-d1-iconalign">
                  <el-icon
                    :class="'dd-d1-icon' + ' ' + (detailsData.info.mqtt_online==true?(detailsData.info.device_online==true?'dd-d1-online':'dd-d1-offline'):'dd-d1-offline')">
                    <Link />
                  </el-icon>
                </div>
                <div class="dd-d1-icontext">
                  {{detailsData.info.mqtt_online==true?(detailsData.info.device_online==false?'设备未连接':'设备已连接'):'设备未连接'}}
                </div>
              </div>
            </div>
          </div>

        </div>

        <div class="dd-d1-basicinfo-AlarmStatus">
          <div class="dd-d1-alarm">

            <div class="dd-d1-iconbox">
              <div>
                <div class="dd-d1-iconalign">
                  <el-icon
                    :class="'dd-d1-icon' + ' ' + (detailsData.info.mqtt_online==true?(detailsData.info.alarm_switch==true?'dd-d1-online':'dd-d1-offline'):'dd-d1-offline')">
                    <MuteNotification
                      v-show="detailsData.info.alarm_switch==false||detailsData.info.alarm_switch==undefined">
                    </MuteNotification>
                    <BellFilled v-show="detailsData.info.alarm_switch!=false&&detailsData.info.alarm_switch!=undefined">
                    </BellFilled>
                  </el-icon>
                </div>
                <div class="dd-d1-icontext">
                  报警:<b>{{detailsData.info.mqtt_online==undefined?'关':(detailsData.info.mqtt_online==false?'关':(detailsData.info.alarm_switch==false?'关':'开'))}}</b>
                </div>
              </div>
            </div>

            <div class="dd-d1-alarmdata">

              <div class="dd-d1-alarmdata-block">
                <div>
                  <div class="dd-d1-alarmdata-block-data" style="color: rgba(62,159,229,1);">
                    {{detailsData.info.mqtt_online==undefined?'—':(detailsData.info.mqtt_online==false?'—':(detailsData.info.lower_temperature_limit==-999?'-':detailsData.info.lower_temperature_limit+'°C'))}}
                  </div>
                  <div class="dd-d1-alarmdata-block-value">
                    温度下限
                  </div>
                </div>
              </div>

              <div class="dd-d1-alarmdata-block">
                <div>
                  <div class="dd-d1-alarmdata-block-data" style="color: rgba(255,177,42,1);">
                    {{detailsData.info.mqtt_online==undefined?'—':(detailsData.info.mqtt_online==false?'—':(detailsData.info.upper_temperature_limit==-999?'-':detailsData.info.upper_temperature_limit+'°C'))}}
                  </div>
                  <div class="dd-d1-alarmdata-block-value">
                    温度上限
                  </div>
                </div>
              </div>

              <div class="dd-d1-alarmdata-block">
                <div>
                  <div class="dd-d1-alarmdata-block-data" style="color: rgba(255,75,70,1);">
                    {{detailsData.info.mqtt_online==undefined?'—':(detailsData.info.mqtt_online==false?'—':(detailsData.info.shutdown_temperature==-999?'-':detailsData.info.shutdown_temperature+'°C'))}}
                  </div>
                  <div class="dd-d1-alarmdata-block-value">
                    关机温度
                  </div>
                </div>
              </div>


            </div>


          </div>
        </div>
      </div>

      <!-- 图片 -->
      <div class="dd-d1-basicinfo-IMG">
        <div style="text-align: center;">
          <img class="dd-d1-img" :src="detailsData.info.imn==undefined?'':detailsData.info.imn.image">
          <div class="dd-d1-imn">
            {{detailsData.info.imn==undefined?'':detailsData.info.imn.instrument_name}}
          </div>
        </div>

      </div>

    </div>

    <div class="dd-d1-basicinfo-TemperatureSpeed">
      <div class="dd-d1-basicinfo-Temperature">

        <div class="dd-d1-basicinfo-TS-DataBox">
          <span>
            <div class="dd-d1-tsdata">
              {{detailsData.info.mqtt_online==undefined?'—':(detailsData.info.mqtt_online==false?'—':(detailsData.info.set_temperature==-999?'-':detailsData.info.set_temperature+'°C'))}}
            </div>
            <div class="dd-d1-tsvalue">设置温度</div>
          </span>
        </div>
        <div class="dd-d1-basicinfo-TS-DataBox">
          <span>
            <div class="dd-d1-tsdata">
              {{detailsData.info.mqtt_online==undefined?'—':(detailsData.info.mqtt_online==false?'—':(detailsData.info.current_temperature==-999?'-':detailsData.info.current_temperature+'°C'))}}
            </div>
            <div class="dd-d1-tsvalue">当前温度</div>
          </span>
        </div>
        <div class="dd-d1-basicinfo-TS-DataBox">
          <span>
            <div
              :class="'dd-d1-tsdata' + ' ' + (detailsData.info.mqtt_online==true?(detailsData.info.heating_switch==true?'dd-d1-online':'dd-d1-offline'):'dd-d1-offline')">
              <b>{{detailsData.info.mqtt_online==true?(detailsData.info.heating_switch==true?'开':'关'):'关'}}</b>
            </div>
            <div class="dd-d1-tsvalue">加热开关</div>
          </span>
        </div>
      </div>

      <div class="dd-d1-basicinfo-Speed">

        <div class="dd-d1-basicinfo-TS-DataBox">
          <span>
            <div class="dd-d1-tsdata">
              {{detailsData.info.mqtt_online==undefined?'—':(detailsData.info.mqtt_online==false?'—':(detailsData.info.set_speed==-999?'-':detailsData.info.set_speed+ ' ' +'RPM'))}}
            </div>
            <div class="dd-d1-tsvalue">设置转速</div>
          </span>
        </div>
        <div class="dd-d1-basicinfo-TS-DataBox">
          <span>
            <div class="dd-d1-tsdata">
              {{detailsData.info.mqtt_online==undefined?'—':(detailsData.info.mqtt_online==false?'—':(detailsData.info.current_speed==-999?'-':detailsData.info.current_speed+ ' ' +'RPM'))}}
            </div>
            <div class="dd-d1-tsvalue">当前转速</div>
          </span>
        </div>
        <div class="dd-d1-basicinfo-TS-DataBox">
          <span>
            <div
              :class="'dd-d1-tsdata' + ' ' + (detailsData.info.mqtt_online==true?(detailsData.info.speed_switch==true?'dd-d1-online':'dd-d1-offline'):'dd-d1-offline')">
              <b>{{detailsData.info.mqtt_online==true?(detailsData.info.speed_switch==true?'开':'关'):'关'}}</b>
            </div>
            <div class="dd-d1-tsvalue">搅拌开关</div>
          </span>
        </div>
      </div>

      <!-- <div style="height: 100%;width: 44.5vw;background-color: #A4DA89;margin-left: 1vw;"></div> -->
    </div>

  </div>


  <!-- <div id="main" style="height: 500px;width: 50%;background-color: #FFFFFF;"></div> -->
  <!-- 图表部分 -->
  <TemperatureSpeedChart :chartDid="$route.query.ZGlk"></TemperatureSpeedChart>
  <!-- <TemperatureChart :chartDid="$route.query.ZGlk"></TemperatureChart> -->
  <!-- <SpeedChart :chartDid="$route.query.ZGlk"></SpeedChart> -->

  <!-- 日志部分 -->
  <div class="dd-d1-LogMain">
    <div class="dd-d1-LogInner">
      <div class="dd-d1-LogTitle">
        设备日志
      </div>
      <el-timeline class="my-timeline">
        <el-timeline-item :timestamp="$basic.conversionTime(new Date(item.time))" placement="top"
          v-for="(item,index) in deviceLogData.list" :key="index">
          <div class="dd-d1-LogCard">
            <div class="dd-d1-LogInfo">日志信息：{{item.content}}</div>
            <div class="dd-d1-LogInfo">信息类型：{{deviceLogData.logType[item.log_type]}}</div>
            <div class="dd-d1-LogInfo">操作用户：{{item.name}}</div>
          </div>
        </el-timeline-item>
      </el-timeline>
    </div>

    <div class="dd-d1-LogMore">
      <div :class="deviceLogData.canMore==true?'dd-d1-morebtn-true':'dd-d1-morebtn-false'" @click="moreLog()">
        {{deviceLogData.canMore==true?'点击查看更多':'已无更多日志信息'}}</div>
    </div>

  </div>





</template>

<script setup>
  import * as echarts from 'echarts';
  import TemperatureSpeedChart from '../chart/TemperatureSpeedChart.vue'
  // import TemperatureChart from '../chart/TemperatureChart.vue'
  // import SpeedChart from '../chart/SpeedChart.vue'

  import {
    Files,
    Stamp,
    Lock,
    Unlock,
    UploadFilled,
    Link,
    BellFilled,
    MuteNotification,
  } from '@element-plus/icons'

  // ref方法
  // reactive可传对象数组
  import {
    reactive,
    ref,
    getCurrentInstance,
    onMounted,
  } from 'vue'
  // router、接口引入
  const {
    proxy
  } = getCurrentInstance()

  onMounted(() => {
    getDeviceInfo()
    getDeviceLog()
    // chart()
    // getEventGraph_temperature()
    // getEventGraph_speed()
  })



  const detailsData = reactive({
    info: {},
    image: '',
    loading: false
  })

  function getDeviceInfo() {
    detailsData.loading = true
    // console.log('111',proxy.$base64.decode(proxy.$route.query.ZGlk))
    proxy.$instance.request_private.get('/mqttapi/v2/device/info', {
      params: {
        did: proxy.$base64.decode(proxy.$route.query.ZGlk)
      }
    }).then(res => {
      if (res.data.code == 1) {
        let data = proxy.$basic.dataDecode(res.data.data)
        detailsData.info = data
        // detailsData.image = data.imn.image

        getEventInfo()
        // let eventList = proxy.$deviceConfig.deviceEventListForList[data.imn.dmid.dmid]
        // console.log('eventList',eventList)


      } else {
        proxy.$message.error('设备信息获取失败|' + res.data.code)
      }
    })
  }


  function getEventInfo() {
    proxy.$deviceHandler.handlerForList(detailsData.info.imn.dmid.dmid, detailsData.info.did).then(
      // proxy.$deviceHandler.handlerForList(proxy.$base64.decode(proxy.$route.query.ZG1pZA), proxy.$base64.decode(proxy.$route.query.ZGlk)).then(
      resHD => {
        for (let emid_for_res in resHD) {
          for (let emid_for_key in proxy.$deviceConfig.deviceEventObjectKey) {
            if (emid_for_res === emid_for_key) {
              proxy.$deviceConfig.deviceEventObjectKey[emid_for_key].forEach((event_key, kindex) => {
                resHD[emid_for_res].forEach((event_data, eindex) => {
                  if (kindex === eindex) {
                    detailsData.info[event_key] = event_data
                    // handlerData[event_key] = event_data
                  }
                })
              })
            }
          }
        }

        detailsData.loading = false
        // console.log('detailsData.info', detailsData.info)

        // getMqttPush()
        getMqttInfo()

      })
  }

  function getMqttInfo() {

    proxy.$instance.request_private.get('/mqttapi/v2/user/mqtt').then(res => {
      if (res.data.code == 1) {
        proxy.$mqttGlobalData.client = null
        let mqttInfo = proxy.$basic.dataDecode(res.data.data)
        MqttInitSocket(mqttInfo)
      } else {
        proxy.$mqttGlobalData.client = null
        DLNI_basic_data.loading = false
        proxy.$message.error('MQTT信息获取失败|' + res.data.code)
      }

    })
  }

  function MqttInitSocket(mqttInfo) {
    proxy.$mqttGlobalData.client = proxy.$mqtt.connect(proxy.$mqttGlobalData.mqttUrl, {
      keepalive: 30,
      clientId: mqttInfo.client_id,
      protocolId: 'MQTT',
      protocolVersion: 4,
      username: proxy.$store.getters.ucUid,
      password: mqttInfo.password
    })

    getMqttPush()

  }


  function getMqttPush() {
    let mqttSubscribeList = JSON.parse(proxy.$base64.decode(proxy.$store.getters.mqttSubscribeList))
    mqttSubscribeList.forEach(item => {
      proxy.$mqttGlobalData.client.subscribe('v2/' + item + '/get/#', {
        qos: 1
      }, () => {})
    })

    proxy.$mqttGlobalData.client.on('message', (topic, message, packet) => {

      let result = []
      let result_str = ''
      let result_obj = {}

      message.forEach(item => {
        let asciiCode = parseInt(item, 16)
        let charValue = String.fromCharCode(item)
        result.push(charValue)
      })

      result.forEach(ritem => {
        result_str = result_str + ritem
      })

      result_obj = JSON.parse(result_str)
      let reg = /v2[/\\](.*)[/\\]get/
      let topic_did = topic.match(reg)[1]

      // console.log('topic_did', topic_did)

      if (detailsData.info.did === topic_did) {
        for (let emid_for_key in proxy.$deviceConfig.deviceEventObjectKey) {
          if (emid_for_key === result_obj.emid) {
            proxy.$deviceConfig.deviceEventObjectKey[emid_for_key].forEach((key_name, kindex) => {
              result_obj.arguments.forEach((res_data, dindex) => {
                if (kindex === dindex) {
                  detailsData.info[key_name] = res_data
                }
              })
            })
          }
        }
      }

    })
  }

  const deviceLogData = reactive({
    type: 'all',
    offset: 0,
    count: 10,
    list: [],

    canMore: true,

    logType: {
      'all': '全部',
      'settings': '设置',
      'device_group': '设备组权限相关',
    }

  })

  function getDeviceLog() {
    proxy.$instance.request_private.get('/mqttapi/v2/log/device/device_list', {
      params: {
        type: deviceLogData.type,
        did: proxy.$base64.decode(proxy.$route.query.ZGlk),
        offset: deviceLogData.offset,
        count: deviceLogData.count,
      }
    }).then(res => {
      if (res.data.code == 1) {
        let data = proxy.$basic.dataDecode(res.data.data)
        if (data.log_list.length == 0) {
          deviceLogData.canMore = false
        } else {
          deviceLogData.canMore = true

          data.log_list.forEach(item => {
            deviceLogData.list.push(item)
          })

        }

      } else {
        
        proxy.$message.error('日志信息获取失败|' + res.data.code)
      }
    })
  }

  function moreLog() {
    if (deviceLogData.canMore == true) {
      deviceLogData.offset = (deviceLogData.offset + 1) * deviceLogData.count
      getDeviceLog()
    }

  }


  // ----------------------------------------------------------------------------------------
</script>

<style scoped>
  .dd-d1-online {
    color: #67C23A;
  }

  .dd-d1-offline {
    color: rgba(187, 187, 187, 1);
  }

  /* 第一块基础信息 */
  .dd-d1-basicinfo {
    width: 100%;
    height: 22vw;
    background-color: rgba(225, 228, 235, 1);
    margin-bottom: 1vw;
    border-radius: 0.5vw;
    /* display: flex; */
  }

  /* 布局相关 */
  .dd-d1-basicinfo-NameAndRemark {
    background-color: rgba(255, 255, 255, 1);
    height: 13vw;
    width: 31vw;
    margin-top: 1vw;
    margin-left: 1vw;
    border-radius: 0.5vw;
    display: flex;
    align-items: center;
    justify-content: center;
    box-shadow: 0 0.1vw 0.6vw 0 rgba(0, 0, 0, 0.1)
      /* box-shadow: 0 0.2vw 0.4vw rgba(0,0,0,0.12),0 0 6vw rgba(0,0,0,0.04) */
  }

  .dd-d1-basicinfo-GroupAndPermission {
    /* background-color: rgba(54, 200, 64, 0.5); */
    height: 13vw;
    width: 16vw;
    margin-top: 1vw;
    margin-left: 1vw;
    /* border-radius: 0.5vw; */
  }

  .dd-d1-basicinfo-Group {
    background-color: rgba(255, 255, 255, 1);
    width: 100%;
    height: 6vw;
    border-radius: 0.5vw;
    box-shadow: 0 0.1vw 0.6vw 0 rgba(0, 0, 0, 0.1)
  }

  .dd-d1-basicinfo-Permission {
    margin-top: 1vw;
    background-color: rgba(255, 255, 255, 1);
    width: 100%;
    height: 6vw;
    border-radius: 0.5vw;
    box-shadow: 0 0.1vw 0.6vw 0 rgba(0, 0, 0, 0.1)
  }


  .dd-d1-basicinfo-Status {
    /* background-color: rgba(54, 200, 64, 0.5); */
    height: 13vw;
    width: 20vw;
    margin-top: 1vw;
    margin-left: 1vw;
  }

  .dd-d1-basicinfo-Status-lin1 {
    /* background-color: rgba(180, 180, 80, 0.5); */
    display: flex;
    justify-content: space-between;
  }

  .dd-d1-basicinfo-HoldStatus {
    height: 6vw;
    width: 6vw;
    background-color: rgba(255, 255, 255, 1);
    border-radius: 0.5vw;
    display: flex;
    justify-content: center;
    align-items: center;
    box-shadow: 0 0.1vw 0.6vw 0 rgba(0, 0, 0, 0.1)
  }

  .dd-d1-basicinfo-AlarmStatus {
    margin-top: 1vw;
    height: 6vw;
    width: 20vw;
    /* background-color: #DD6161; */
    background-color: rgba(255, 255, 255, 1);
    border-radius: 0.5vw;
    display: flex;
    align-items: center;
    justify-content: center;
    box-shadow: 0 0.1vw 0.6vw 0 rgba(0, 0, 0, 0.1)
  }

  .dd-d1-basicinfo-IMG {
    /* background-color: #13CE66; */
    background-color: rgba(255, 255, 255, 1);
    height: 13vw;
    width: 20vw;
    margin-top: 1vw;
    margin-left: 1vw;
    border-radius: 0.5vw;
    display: flex;
    align-items: center;
    justify-content: center;
    box-shadow: 0 0.1vw 0.6vw 0 rgba(0, 0, 0, 0.1)
  }

  .dd-d1-basicinfo-TemperatureSpeed {
    /* background-color: rgba(54, 200, 64, 0.5); */
    width: 100%;
    /* background-color: #3A8EE6; */
    margin-top: 1vw;
    display: flex;
  }

  .dd-d1-basicinfo-Temperature {
    height: 100%;
    width: 44.5vw;
    background-color: rgba(255, 255, 255, 1);
    margin-left: 1vw;
    display: flex;
    justify-content: space-between;
    align-items: center;
    border-radius: 0.5vw;
    box-shadow: 0 0.1vw 0.6vw 0 rgba(0, 0, 0, 0.1)
  }

  .dd-d1-basicinfo-Speed {
    height: 100%;
    width: 44.5vw;
    background-color: rgba(255, 255, 255, 1);
    margin-left: 1vw;
    display: flex;
    justify-content: space-between;
    align-items: center;
    border-radius: 0.5vw;
    box-shadow: 0 0.1vw 0.6vw 0 rgba(0, 0, 0, 0.1)
  }

  .dd-d1-basicinfo-TS-DataBox {
    height: 6vw;
    width: 12vw;
    display: flex;
    justify-content: center;
    align-items: center;
    /* background-color: #CF9236; */
  }

  .dd-d1-basicinfo-CurrentData {
    position: relative;
    width: 8vw;
    height: 6vw;
    background-color: rgba(255, 255, 255, 1);
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 0.5vw;
    box-shadow: 0 0.1vw 0.6vw 0 rgba(0, 0, 0, 0.1)
  }

  .dd-d1-basicinfo-SetData {
    width: 6vw;
    height: 6vw;
    background-color: rgba(255, 255, 255, 1);
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 0.5vw;
    box-shadow: 0 0.1vw 0.6vw 0 rgba(0, 0, 0, 0.1)
  }


  /* 第二块图标信息 */



  /* 字体相关 */
  .dd-d1-basicinfo-NameAndRemark-text {
    width: 90%;
    /* background-color: red; */
  }

  .dd-d1-name {
    font-size: 1.5vw;
    font-weight: bold;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
  }

  .dd-d1-remark {
    font-size: 0.8vw;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
  }


  .dd-d1-iconbox

  /* 图标部分 */
    {
    /* background-color: #3A8EE6; */
    width: 5vw;
    height: 5vw;
    display: flex;
    align-items: center;
    justify-content: center;
  }

  .dd-d1-iconalign {
    text-align: center;
    line-height: 0;
  }

  .dd-d1-icon {
    font-size: 1.3vw;
    /* line-height: 0; */
  }

  .dd-d1-icontext {
    margin-top: 0.2vw;
    font-size: 0.8vw;
    width: 100%;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
  }

  .dd-d1-group {
    /* background-color: #3A8EE6; */
    width: 16vw;
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
  }

  .dd-d1-grouptext {
    font-size: 0.8vw;
    width: 9.5vw;
    margin-left: 0.5vw;
    /* background-color: #13CE66; */
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;

    text-align: center;
    font-weight: bold;
  }

  .dd-d1-permission {
    width: 16vw;
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
  }

  .dd-d1-permissiontext {
    font-size: 0.8vw;
    width: 9.5vw;
    margin-left: 0.5vw;
    /* background-color: #13CE66; */
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;

    text-align: center;
    font-weight: bold;
  }

  .dd-d1-alarm {
    width: 19vw;
    display: flex;
  }

  .dd-d1-alarmdata {
    height: 5vw;
    width: 13vw;
    /* background-color: #3A8EE6; */
    margin-left: 1vw;
    display: flex;
    align-items: center;
    justify-content: space-between;
  }

  .dd-d1-alarmdata-block {
    width: 4vw;
    height: 5vw;
    /* background-color: #CF9236; */
    display: flex;
    align-items: center;
    justify-content: center;
  }

  .dd-d1-alarmdata-block-data {
    /* background-color: #13CE66; */
    font-size: 0.8vw;
    line-height: 1.5vw;
    /* font-size: 1.1vw; */
    font-weight: bold;
    text-align: center;
  }

  .dd-d1-alarmdata-block-value {
    font-size: 0.8vw;
    text-align: center;
  }

  .dd-d1-img {
    width: 9vw;
    height: 9vw;
  }

  .dd-d1-imn {
    /* background-color: #13CE66; */
    font-size: 0.8vw;
    width: 18vw;
    font-weight: bold;
    text-align: center;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
  }

  .dd-d1-tsdata {
    font-size: 0.8vw;
    line-height: 1.5vw;
    font-weight: bold;
    text-align: center;
    width: 11vw;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
  }


  .dd-d1-tsvalue {
    font-size: 0.8vw;
    text-align: center;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
  }


  /* 日志部分 */

  .dd-d1-morebtn-true {
    color: rgba(0, 42, 105, 1);
    /* background-color: #A0CFFF; */
    font-size: 0.8vw;
    line-height: 0;
    cursor: pointer;
  }

  .dd-d1-morebtn-true:hover {
    font-size: 0.8vw;
    line-height: 0;
    color: rgba(0, 42, 105, 0.7);
  }

  .dd-d1-morebtn-false {
    font-size: 0.8vw;
    line-height: 0;
    color: rgba(187, 187, 187, 1);
  }


  .dd-d1-LogMain {
    background-color: rgba(255, 255, 255, 1);
    border-radius: 0.5vw;
    box-shadow: 0 0.1vw 0.6vw 0 rgba(0, 0, 0, 0.1)
  }

  .dd-d1-LogInner {
    width: calc(100% - 2vw);
    margin-left: 1vw;
    padding-top: 1vw;
  }

  .dd-d1-LogTitle {
    font-size: 1.2vw;
    font-weight: bold;
    margin-bottom: 1vw;
  }

  .dd-d1-LogCard {
    box-shadow: 0 0.1vw 0.6vw 0 rgba(0, 0, 0, 0.2);
    background-color: rgba(255, 255, 255, 1);
    padding: 1vw;
    border-radius: 0.2vw;
  }

  .dd-d1-LogInfo {
    font-size: 0.8vw;
    margin-bottom: 0.5vw;
  }

  .dd-d1-LogMore {
    height: 5vw;
    display: flex;
    justify-content: center;
    align-items: center;
  }
</style>
