import instance from './http.js'
import basic from './basic.js'
import errorCode from './errorCode.js'
import deviceConfig from './deviceConfig.js'

export function handlerForList(dmid, did) {
  // console.log(dmid)
  // console.log(did)
  // console.log('did:',did)
  return new Promise((resolve, reject) => {
    let contentList = []
    let contentObj = {}
    let resList = []
    let resObj = {}
    let eventList = deviceConfig.deviceEventListForList[dmid]
    eventList.forEach((emid, index) => {
      // console.log('emid', emid)
      instance.request_private.post('/mqttapi/v2/device/event', {
        did: did,
        event_model_id: emid,
        offset: 0,
        count: 1
      }).then(res => {
        if (res.data.code == 1) {
          let data = basic.dataDecode(res.data.data)
          if (data.event_list.length === 0) {
            contentObj[emid] = deviceConfig.deviceEventReturnDefault[emid]
            // contentList[index]=deviceConfig.deviceEventReturnDefault[emid]
          } else {
            contentObj[emid] = data.event_list[0].content
            // contentList[index]=data.event_list[0].content
          }
        } else {
          contentObj[emid] = deviceConfig.deviceEventReturnDefault[emid]
        }
      }).then(() => {
        // resolve(contentObj)
        if (Object.keys(contentObj).length == eventList.length) {
          resolve(contentObj)
        }

      })
    })

  })
}

const deviceHandler = {
  handlerForList,
}

export default deviceHandler
