import axios from 'axios'
import router from '../../router/index.js'
import $store from '../../store/index.js'
import basic from './basic.js'
import {
	ElMessage
} from 'element-plus'

axios.default.retry = 1
axios.default.retry = 1000
axios.default.withCredentials = true
const $basic = basic


const httpConfig = {

  // 内网用
	// url: 'http://10.0.0.46'
  // 外网用
	url: 'https://iot.neodawn.cn'
}

const firstLayerErrorType = {
	'400': '语法出错，缺少字段，解析错误',
	'401': '缺少用户登录信息，用户登录信息过期',
	'403': '用户权限不足',
	'404': '请求的资源不存在',
	'405': '请求方法错误',
	'500': '服务器出错'
}

// 公开请求接口
const request_public = axios.create({
	headers: {
		'Content-Type': 'application/json',
	},
	baseURL: httpConfig.url,
	// timeout: 10000,
})

request_public.interceptors.request.use(
	function(config) {
		return config;
	},
	function(error) {
		return Promise.reject(error);
	}
)

request_public.interceptors.response.use(
	function(response) {
		if (response.status) {
			// console.log(response);
			// 不return返回res就没有信息
			return response
		}
	},
	function(error) {
		ElMessage.error(firstLayerErrorType[error.response.status]);
		return Promise.reject(error)
	}
)

// 私有请求接口【鉴权携带】
const request_private = axios.create({
	headers: {
		'Content-Type': 'application/json',
		'Authorization': $store.getters.jwt
	},
	baseURL: httpConfig.url,
	// timeout: 10000,
})

request_private.interceptors.request.use(
	function(config) {
		const jwt = $store.getters.jwt
		if (jwt) {
			config.headers.Authorization = jwt
		}
		return config;
	},
	function(error) {
		return Promise.reject(error);
	}
)

request_private.interceptors.response.use(
	function(response) {
		if (response.status) {
			// console.log(response);
			// 不return返回res就没有信息
			return response
		}
	},
	function(error) {
		let config = error.config
		if (error.response.status == '401') {
			refreshJwt().then((result) => {
				// console.log('result', result)
				$store.commit('setJwt', result.access_token)
				$store.commit('setRefreshToken', result.refresh_token)
				// location.reload();
				// console.log('config', config)
				return request_private(config)
				return false
			})
		} else {
			ElMessage.error(firstLayerErrorType[error.response.status]);
		}

		return Promise.reject(error)
	}
)

// 刷新jwt
function refreshJwt() {
	return new Promise((resolve, reject) => {
		axios.post(httpConfig.url + '/mqttapi/v2/token/refresh', {
			refresh: $store.getters.refreshToken
		}, {
			headers: {
				'Content-Type': 'application/json',
			}
		}).then(res => {
			if (res.status == 200) {
				if (res.data.code == 1) {
					let refreshData = $basic.dataDecode(res.data.data)
					$store.commit('setJwt', refreshData.access_token)
					$store.commit('setRefreshToken', refreshData.refresh_token)
					resolve(refreshData)
				} else {
					refreshTokenDie()
				}
			} else {
				refreshTokenDie()
			}
		}).catch(err => {

			refreshTokenDie()
			reject(err)
		})
	})
}

function refreshTokenDie() {
	ElMessage.error('登录已过期，请重新登录');

  $basic.loginOut()

	/* $store.commit('setJwt', null)
	$store.commit('setRefreshToken', null)
  $store.commit('setMqttDeviceKeyObject', null)
  $store.commit('setMqttSubscribeList', null)
	localStorage.clear()
	router.push({
		path: '/login',
	}) */
}





const instance = {
	request_public,
	request_private,
}

export default instance
