<template>
	<div class="register-main">
		<LoginModularHeader title="欢迎注册"></LoginModularHeader>
		<div class="register-main-inner">
			<div class="register-main-inner-from">
				<el-form class="my-form" label-position="top" ref="registerRef" :model="registerData.registerForm"
					:rules="registerData.registerFormRule">
					<el-form-item label="手机号" prop="phone_number">
						<el-input class="my-input" v-model.trim="registerData.registerForm.phone_number">
						</el-input>
					</el-form-item>
					<el-form-item label="验证码" prop="code">
						<div style="display: flex;justify-content: space-between;">
							<el-input class="my-input" v-model.trim="registerData.registerForm.code"
								style="width: 55%;"></el-input>
							<el-button id="getCodeBtn" type="primary" class="my-formbtn" style="" v-loading.fullscreen.lock="registerData.getCodeLoading">
								{{registerData.time}}{{registerData.suffix}}
							</el-button>
						</div>
					</el-form-item>
					<el-form-item label="用户名" prop="username">
						<el-input class="my-input" v-model.trim="registerData.registerForm.username"></el-input>
					</el-form-item>
					<el-form-item label="密码" prop="password">
						<el-input class="my-input" v-model.trim="registerData.registerForm.password"
							type="password" show-password></el-input>
					</el-form-item>
					<el-form-item label="确认密码" prop="checkpassword">
						<el-input class="my-input" v-model.trim="registerData.registerForm.checkpassword"
							type="password" show-password></el-input>
					</el-form-item>
				</el-form>

				<div class="register-main-inner-btn">
					<el-button class="my-btn" type="primary" style="width: 100%;" @click="register()"
						:disabled="registerData.registerDisabled" v-loading.fullscreen.lock="registerData.registerLoading">
						注 册
						<!-- {{registerData.registerLoading==false?'注 册':'提交中。。。'}} -->
					</el-button>
				</div>



			</div>



		</div>
	</div>

</template>

<script setup>
	// ref方法
	// reactive可传对象数组
	import {
		reactive,
		ref,
		getCurrentInstance,
		onMounted,
		watch,
	} from 'vue'
	import LoginModularHeader from '../../components/login_modular/LoginModularHeader.vue'
	// router、接口引入
	const {
		proxy
	} = getCurrentInstance()

	onMounted(() => {
		geetestInitialize()
	})

	// 表单用验证码规则
	const isCheckPassword = (rule, value, callback) => {
		if (value != registerData.registerForm.password) {
			callback(new Error('两次输入密码不一致'));
		} else {
			callback();
		}
	}

	// 极验参数
	const geetestData = reactive({
		gt: '',
		challenge: '',
		offline: '',
		new_captcha: '',
	});

	// 注册参数
  const registerRef = ref(null)
  
	const registerData = reactive({

		// 提交
		registerDisabled: true,
		registerLoading: false,

		// 验证码
		time: "获取验证码",
		currentTime: 0,
		suffix: null,
		getCodeLoading: false,

		registerForm: {
			phone_number: '',
			code: '',
			username: '',
			password: '',
			checkpassword: '',
		},

		registerFormRule: {
			phone_number: [{
					required: true,
					message: '请输入手机号',
					trigger: 'blur'
				},
				{
					validator: proxy.$basic.isPhoneNumber_el,
					trigger: 'blur'
				}

			],
			code: [{
					required: true,
					message: '请输入验证码',
					trigger: 'blur'
				},
				{
					validator: proxy.$basic.isVerificationCode_el,
					trigger: 'blur'
				}
			],
			username: [{
					required: true,
					message: '请输入用户名',
					trigger: 'blur'
				},
				{
					validator: proxy.$basic.isUserName_el,
					trigger: 'blur'
				},


			],
			password: [{
					required: true,
					message: '请输入密码',
					trigger: 'blur'
				},
				{
					min: 8,
					max: 16,
					message: '密码必须在8~16个字符',
					trigger: 'blur'
				}
			],
			checkpassword: [{
					required: true,
					message: '请再次输入密码',
					trigger: 'blur'
				},
				{
					validator: isCheckPassword,
					trigger: 'blur'
				},
			],
		}
	})


	function checkRegisterBtnDisabled() {
		if (
			proxy.$basic.isPhoneNumber(registerData.registerForm.phone_number) &&
			proxy.$basic.isVerificationCode(registerData.registerForm.code) &&
			proxy.$basic.isUserName(registerData.registerForm.username) &&
			registerData.registerForm.password.length >= 8 &&
			registerData.registerForm.password.length <= 16 &&
			registerData.registerForm.password == registerData.registerForm.checkpassword
		) {
			registerData.registerDisabled = false
		} else {
			registerData.registerDisabled = true
		}
	}
	
	


	// 极验初始化
	function geetestInitialize() {
		proxy.$instance.request_public.get('/mqttapi/v2/geetest/initialize').then(res => {
			if (res.data.code == 1) {
				let gtdata = proxy.$basic.dataDecode(res.data.data)
				initGeetest({
					gt: gtdata.gt,
					challenge: gtdata.challenge,
					offline: !gtdata.success,
					new_captcha: gtdata.new_captcha,
					width: '100%',
					product: 'bind',
				}, function(captchaObj) {
					captchaObj.onReady(function() {

					}).onSuccess(function() {
						let result = captchaObj.getValidate()
						// console.log('result', result)
						if (!result) {
							proxy.$message.error('人机验证失败')
							// console.log('error')
						} else {
							// registerData.getCodeLoading = proxy.$loading({
							// 	lock: true,
							// 	text: '正在获取验证码...',
							// 	spinner: 'el-icon-loading',
							// 	background: 'rgba(0, 0, 0, 0.7)'
							// });							
							registerData.getCodeLoading = true
							// 验证通过后进行获取验证码
							proxy.$instance.request_public.post('/mqttapi/v2/user/sms/send', {
								phone_number: registerData.registerForm.phone_number,
								type: 0,
								validate: result.geetest_validate,
								seccode: result.geetest_seccode,
								challenge: result.geetest_challenge
							}).then(res => {
								if (res.data.code == 1) {
									let data = proxy.$basic.dataDecode(res.data.data)
									checkTask(data.task_id, 0)


									// console.log('data',data)
									// loginLoading.close();
								} else {
									registerData.getCodeLoading = false
									// registerData.getCodeLoading.close();
									proxy.$message.error(proxy.$errorCode[res.data.code])
								}
							})

							// console.log('ok')
						}
					}).onError(function() {
						// console.log('error2')
						proxy.$message.error('人机验证未通过')
					}).onClose(function() {
						// console.log('close')
					})

					// document.getElementById('button1')

					document.getElementById('getCodeBtn').addEventListener('click', function() {
						// console.log('click',click)
						// console.log('loginbtn', loginbtn)
						if (getCodeBtn) { // 检查是否可以进行提交
							if (registerData.currentTime <= 0) {
								if (proxy.$basic.isPhoneNumber(registerData.registerForm
										.phone_number)) {
									captchaObj.verify();
								} else {
									proxy.$message.error('请输入正确的手机号')
								}
							} else {
								proxy.$message.error('倒计时结束后再请求')
							}

						}
					});

				})
			} else {
				proxy.$message.error('人机验证发生错误')
			}
		})
	}

	// 确认验证码发送情况
	function checkTask(task_id, die) {
		proxy.$instance.request_public.get('/mqttapi/v2/user/sms/task', {
			params: {
				task_id: task_id
			}
		}).then(res => {
			if (res.data.code == 1) {
				registerData.getCodeLoading = false
				// registerData.getCodeLoading.close();
				proxy.$message.success('验证码已发送')
				codeStart()
			} else if (res.data.code == 2000) {
				if (die < 20) {
					setTimeout(() => {
						checkTask(task_id, die++)
					}, 1000)
				} else {
					registerData.getCodeLoading = false
					// registerData.getCodeLoading.close();
					proxy.$message.error('验证码发送失败')
				}
			} else {
				registerData.getCodeLoading = false
				// registerData.getCodeLoading.close();
				proxy.$message.error(proxy.$errorCode[res.data.code])
			}
		})
	}


	// 倒计时
	function codeStart() {
		registerData.currentTime = proxy.$basic.config_js.countDown
		let interval = null;
		interval = setInterval(function() {
			registerData.currentTime--;
			registerData.time = registerData.currentTime
			registerData.suffix = '秒'
			if (registerData.currentTime <= 0) {
				clearInterval(interval)
				registerData.currentTime = proxy.$basic.config_js.countDown
				registerData.suffix = null
				registerData.time = '重新发送'

			}
		}, 1000)
	}

	// 注册
	function register() {
		registerRef.value.validate((valid) => {
			if (valid) {
				// registerData.registerLoading = proxy.$loading({
				// 	lock: true,
				// 	text: '正在注册...',
				// 	spinner: 'el-icon-loading',
				// 	background: 'rgba(0, 0, 0, 0.7)'
				// });
				
				registerData.registerLoading = true
				
				proxy.$instance.request_public.post('/mqttapi/v2/user/register', {
					sms_code: registerData.registerForm.code,
					phone_number: registerData.registerForm.phone_number,
					password: proxy.$sha256(registerData.registerForm.password),
					username: registerData.registerForm.username
				}).then(res => {
					if (res.data.code == 1) {
						// registerData.registerLoading.close();
						registerData.registerLoading = false
						proxy.$message.success('注册成功')
						proxy.$router.push({
							path: '/login',
						})
					} else {
						registerData.registerLoading = false
						// registerData.registerLoading.close();
						proxy.$message.error(proxy.$errorCode[res.data.code])
					}
				})
			}
		})
	}
	
	watch(() => registerData,
		(oldData, newData) => {
			checkRegisterBtnDisabled()
		}, {
			immediate: true,
			deep: true,
		}
	)


	// ----------------------------------------------------------------------------------------
</script>

<style scoped>
	.register-main {
		width: 100vw;
		min-height: 100vh;
	}

	.register-main-inner {
		display: flex;
		justify-content: center;
	}

	.register-main-inner-from {
		margin-top: 2vw;
		width: 15vw;
	}

	.register-main-inner-btn {
		margin-top: 4vw;
	}


	@media screen and (max-width:1600px) {
		.register-main-inner-from {
			margin-top: 2vw;
			width: 20vw;
		}
	}

	@media screen and (max-width:1200px) {
		.register-main-inner-from {
			margin-top: 4vw;
			width: 25vw;
		}
	}

	@media screen and (max-width:850px) {
		.register-main-inner-from {
			margin-top: 5vw;
			width: 80vw;
		}

		.register-main-inner-btn {
			margin-top: 15vw;
		}

	}
</style>
