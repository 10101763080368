<template>
	<div>

		<div :class="'DLAI-DL' + ' ' + (DLAI_basic_data.show==true?'DLAI-DL-part':'DLAI-DL-all')">

			<div class="DLAI-DL-inner">
				<el-scrollbar class="my-home-list-scrollbar" height="100%">

					<div class="DLAI-DL-inner-group">
						<!-- top -->
						<div class="DLAI-DL-inner-group-top" @click="DLAI_basic_data.gshow = !DLAI_basic_data.gshow">
							<div class="DLAI-DL-inner-group-top-title">
								全部
							</div>

							<div
								:class="'DLAI-DL-inner-group-top-icon' + ' ' + (DLAI_basic_data.gshow==false?'':'DLAI-gti-open')">
								<i class="el-icon-caret-left" style="font-size: 1vw;"></i>
							</div>
						</div>

						<!-- list -->
						<el-collapse-transition>
							<div v-show="DLAI_basic_data.gshow">
								<div v-for="(item,index) in 6" @click="DLAI_basic_data.show = !DLAI_basic_data.show"
									class="DLAI-DL-inner-item" :key="index">
									<div class="DLAI-DL-inner-item-main"></div>
								</div>
							</div>
						</el-collapse-transition>

						<!-- foot -->
						<div v-show="DLAI_basic_data.gshow"
							style="position: relative;height: 0.5vw;width: 100%;display: inline-block;"></div>

					</div>


					<!-- ******************************************************* -->
					<div class="DLAI-DL-inner-group">

						<!-- top -->
						<div class="DLAI-DL-inner-group-top" @click="DLAI_basic_data.gshow = !DLAI_basic_data.gshow">
							<div class="DLAI-DL-inner-group-top-title">
								全部
							</div>

							<div :class="'DLAI-DL-inner-group-top-icon' + ' ' + (DLAI_basic_data.gshow==false?'':'DLAI-gti-open')">
								<i class="el-icon-caret-left" style="font-size: 1vw;"></i>
							</div>
						</div>

						<!-- list -->
						<el-collapse-transition>
							<div v-show="DLAI_basic_data.gshow">
								<div v-for="(item,index) in 6" @click="DLAI_basic_data.show = !DLAI_basic_data.show"
									class="DLAI-DL-inner-item" :key="index">
									<div class="DLAI-DL-inner-item-main"></div>
								</div>
							</div>
						</el-collapse-transition>

						<!-- foot -->
						<div v-show="DLAI_basic_data.gshow"
							style="position: relative;height: 0.5vw;width: 100%;display: inline-block;"></div>

					</div>
					<!-- ******************************************************* -->

				</el-scrollbar>
			</div>
		</div>

		<transition name="slide-fade">
			<div class="DLAI-DI" v-if="DLAI_basic_data.show"></div>
		</transition>

	</div>

</template>





<script setup>

	// props {
	// 	title:String
	// }
	// ref方法
	// reactive可传对象数组
	import {
		reactive,
		ref,
		getCurrentInstance,
		onMounted,
	} from 'vue'
	// router、接口引入
	const {
		proxy
	} = getCurrentInstance()

	const DLAI_basic_data = reactive({
		url: '',
		gshow: false,

	});


</script>

<style scoped>

	.DLAI-gti-open {
		transform: rotate(-90deg);
	}


	.slide-fade-enter-active {
		transition: all 0.3s ease-out;
	}

	.slide-fade-leave-active {
		transition: all 0.3s cubic-bezier(1, 0.5, 0.8, 1);
	}

	.slide-fade-enter-from,
	.slide-fade-leave-to {
		transform: translateX(1vw);
		opacity: 0;
	}


	.DLAI-DL-all {
		width: 86vw;
		transition: all 0.3s cubic-bezier(1, 0.5, 0.8, 1);
	}

	.DLAI-DL-part {
		width: 58vw;
		transition: all 0.3s ease-out;
	}

	.DLAI-DL {
		background-color: rgba(225, 228, 235, 1);
		position: absolute;
		/* top: 6vw; */
		/* left: 7vw; */
		height: 36vw;
		border-radius: 0.5vw;
		/* transition: all 0.3s cubic-bezier(1, 0.5, 0.8, 1); */
		/* transition: all 0.3s; */
		/* overflow: auto; */
	}

	.DLAI-DL-inner {
		position: absolute;
		top: 1vw;
		left: 1vw;
		width: calc(100% - 2vw);
		height: calc(100% - 2vw);
		background-color: #DD6161;
		transition: all 0.3s cubic-bezier(1, 0.5, 0.8, 1);
		/* overflow: auto; */
	}

	.DLAI-DL-inner-group {
		background-color: #FFFFFF;
		border-radius: 0.3vw;
	}

	.DLAI-DL-inner-group+.DLAI-DL-inner-group {
		margin-top: 1vw;
	}

	.DLAI-DL-inner-group-top {
		background-color: #00FF00;
		height: 2vw;
		width: calc(100% - 1vw);
		margin-left: 0.5vw;
		display: flex;
		align-items: center;
	}

	.DLAI-DL-inner-group-top-title {
		font-size: 1vw;
		font-weight: bold;
	}


	.DLAI-DL-inner-group-top-icon {
		transition: all 0.3s;
		margin-left: 0.5vw;
		line-height: 0vw;
	}

	.DLAI-DL-inner-item {
		position: relative;
		display: inline-block;
		width: 14vw;
		height: 14vw;
		background-color: #82848A;

	}

	.DLAI-DL-inner-item:nth-child(2n) {
		background-color: #409EFF;
	}

	.DLAI-DL-inner-item-main {
		position: absolute;
		top: 0.5vw;
		left: 0.5vw;
		width: calc(100% - 1vw);
		height: calc(100% - 1vw);

		background-color: #67C23A;
		;
	}


	.DLAI-DI {
		background-color: rgba(225, 228, 235, 1);
		position: absolute;

		/* top: 6vw; */
		left: 59vw;

		width: 27vw;
		height: 36vw;
		border-radius: 0.5vw;
	}


</style>
