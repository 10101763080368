<template>
	<div v-loading="wxLoginData.loading" style="width: 100vw;height: 100vh;">

  </div>

</template>

<script setup>
	// ref方法
	// reactive可传对象数组
	import { reactive, ref, getCurrentInstance, onMounted, } from 'vue'
	// router、接口引入
	const { proxy } = getCurrentInstance()

  onMounted(() => {
    wxLogin()
  })

	const wxLoginData = reactive({
		url:'',
    loading:true
	});

  function wxLogin() {
    wxLoginData.loading = true
    proxy.$instance.request_public.post('/mqttapi/v2/user/wechat/wechat_login_web', {
      code: proxy.$route.query.code
    }).then(res => {
      if (res.data.code == 1) {
        let data = proxy.$basic.dataDecode(res.data.data)
        proxy.$store.commit('setJwt', data.access_token)
        proxy.$store.commit('setRefreshToken', data.refresh_token)
        proxy.$basic.getCurrentUserInfo().then((
          info_result) => {
          if (info_result == 'success') {
            wxLoginData.loading = false
            // loginLoading.close();
            proxy.$message.success('登录成功')
            proxy.$router.replace({
              path: '/home',
            })
            // history.pushState(null, null, document.URL);
            //禁止后退操作
            // window.addEventListener("popstate", function(e) {
            //     history.pushState(null, null, document.URL);
            //   },
            //   false);
          } else {
            wxLoginData.loading = false
            // loginLoading.close();
            proxy.$message.error('用户信息获取失败')
            proxy.$router.replace({
              path: '/home',
            })
          }
        })

      } else {
        wxLoginData.loading = false
        // proxy.$message.error(proxy.$errorCode[res.data.code])
        // proxy.$router.replace({
        //   path: '/login',
        // })

        proxy.$alert(proxy.$errorCode[res.data.code],'提示', {
          type: 'error',
          confirmButtonText: '确定',
          callback: action => {
            proxy.$router.go(-1)
          }
        });

        // history.pushState(null, null, document.URL);
        //禁止后退操作
        // window.addEventListener("popstate", function(e) {
        //     history.pushState(null, null, document.URL);
        //   },
        //   false);
        // proxy.$router.go(-1)
      }
    })
  }


	// ----------------------------------------------------------------------------------------



</script>

<style scoped>

</style>
