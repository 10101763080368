<template>
  <div :class="'d1-mian' + ' ' + (deviceInfo.mqtt_online==true?'d1-online-bg':'d1-offline-bg')">
    <div class="d1-inner">

      <div class="d1-line-1">
        <img class="d1-img" src="../../../assets/image/card/agitatoroffline.png">
        <!-- <img v-if="deviceInfo.device_online==true" class="d1-img" src="../../../assets/image/card/agitator.png"> -->
        <div class="d1-line-1-text">
          <div class="d1-iname">
            {{deviceInfo.imn.instrument_name}}
          </div>
          <div class="d1-imn">
            {{deviceInfo.imn.imn}}
          </div>
        </div>
      </div>

      <div>
        <div class="d1-name">
          {{deviceInfo.name}}
        </div>
        <div class="d1-permission">
          权级：{{$basic.permissionType[deviceInfo.permission]}}
        </div>
      </div>


      <div class="d1-line-2">
        <div style="display: flex;align-items: center;">
          <img class="d1-dataicon" src="../../../assets/image/card/thermometer.png" >
          <div class="d1-data">
            {{deviceInfo.mqtt_online==true?(deviceInfo.current_temperature!=-999?deviceInfo.current_temperature:'—'):'—'}}
          </div>
          <div class="d1-data" style="color: rgba(67,161,230,1);">
            {{deviceInfo.mqtt_online==true?(deviceInfo.current_temperature!=-999?'°C':''):''}}
          </div>
        </div>

        <div class="d1-statusiconbox">
          <el-icon :class="'d1-statusicon' + ' ' + (deviceInfo.mqtt_online==true?'d1-online-icon':'d1-offline-icon')">
            <UploadFilled />
          </el-icon>
        </div>

      </div>


      <div class="d1-line-2">
        <div style="display: flex;align-items: center;">
          <img class="d1-dataicon" src="../../../assets/image/card/timer-outline.png" >
          <div class="d1-data">
            {{deviceInfo.mqtt_online==true?(deviceInfo.current_speed!=-999?deviceInfo.current_speed:'—'):'—'}}
          </div>
          <div class="d1-data" style="color: rgba(255,122,29,1);">
            {{deviceInfo.mqtt_online==true?(deviceInfo.current_speed!=-999?'rpm':''):''}}
          </div>
        </div>

        <div class="d1-statusiconbox">
          <el-icon :class="'d1-statusicon' + ' ' + (deviceInfo.mqtt_online==true?(deviceInfo.device_online==true?'d1-online-icon':'d1-offline-icon'):'d1-offline-icon')">
            <Connection />
          </el-icon>
        </div>

      </div>



      <!-- <div style="display: flex;justify-content: space-between;">
        <div style="display: flex;align-items: center;">
          <img class="d1-dataicon" src="../../../assets/image/card/thermometer.png" >
          <div v-text="deviceInfo.current_temperature"></div>
        </div>
        <div style="display: flex;align-items: center;">
          <img class="d1-dataicon" src="../../../assets/image/card/timer-outline.png" >
          <div v-text="deviceInfo.current_speed"></div>
        </div>
      </div> -->

      <!-- <div style="display: flex;justify-content: space-between;">

        <el-icon class="d1-statusicon">
          <Unlock />
        </el-icon>

        <el-icon class="d1-statusicon">
          <Lock />
        </el-icon>

        <el-icon class="d1-statusicon">
          <MuteNotification />
        </el-icon>

        <el-icon class="d1-statusicon">
          <BellFilled />
        </el-icon>

        <el-icon class="d1-statusicon">
          <UploadFilled />
        </el-icon>

        <el-icon class="d1-statusicon">
          <Connection />
        </el-icon>


      </div> -->

      <div>

      </div>


    </div>
  </div>
</template>

<script>
  import {
    Unlock,
    Lock,
    MuteNotification,
    BellFilled,
    UploadFilled,
    Connection,

  } from '@element-plus/icons'
  // ref方法
  // reactive可传对象数组
  import {
    reactive,
    ref,
    getCurrentInstance,
    onMounted,
    computed,
  } from 'vue'


  export default {

    name: 'cardD1',
    props: {
      deviceInfo: Object
    },

    components: {
      // Unlock,
      // Lock,
      // MuteNotification,
      // BellFilled,
      UploadFilled,
      Connection,
    },


    setup(props) {
      const {
        proxy
      } = getCurrentInstance()










      return {
      };
    }

  }
</script>

<style scoped>
  .d1-online-bg{
    /* background-color: rgba(75, 97, 235, 1); */
    color: rgba(0,0,0,1);
  }
  .d1-offline-bg{
    color: rgba(187,187,187,0.8);
  }

  .d1-online-icon{
    color: #67C23A;
  }

  .d1-offline-icon{
    color: rgba(187,187,187,1);
  }




  .d1-mian {
    background-color: rgba(245,245,245,1);
    width: 100%;
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 0.5vw;
    box-shadow: 0 0.1vw 0.6vw 0 rgba(0,0,0,0.2);
    cursor: pointer;
  }

  .d1-mian:hover {
    box-shadow: 0 0.1vw 0.6vw 0 rgba(0,0,0,0.4);
  }

  .d1-inner {
    width: 90%;
    height: 90%;
    /* background-color: #B3E19D; */
  }

  .d1-line-1
  {
    display: flex;
  }

  .d1-line-1-text
  {
    margin-left: 0.5vw;

  }

  .d1-img {
    width: 1.6vw;
    height: 2vw;
  }


  .d1-iname{
    max-width: 7.9vw;
    /* width: 7.9vw; */
    /* background-color: #CF9236; */
    font-size: 0.6vw;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
  }

  .d1-imn{
    max-width: 7.9vw;
    /* background-color: #CF9236; */
    font-size: 0.6vw;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
  }

  .d1-name{
    /* background-color: #CF9236; */
    margin-top: 1vw;
    font-size: 0.8vw;
    font-weight: bold;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
  }

  .d1-permission
  {
    /* background-color: #CF9236; */
    margin-top: 0.5vw;
    font-size: 0.7vw;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
  }

  .d1-line-2
  {
    margin-top: 1vw;
    display: flex;
    justify-content: space-between;
  }

  .d1-line-2+.d1-line-2
  {
    margin-top: 0.3vw;
  }

  .d1-dataicon{
    width: 1.2vw;
    height: 1.2vw;
  }

  .d1-data{
    font-size: 0.9vw;
    margin-left: 0.5vw;
    font-weight: bold;
    transition: all;
  }

  .d1-statusiconbox
  {
    height: 1.6vw;
    display: flex;
    align-items: center;
  }

  .d1-statusicon
  {
    font-size: 1.2vw;
  }

  /* @media screen and (max-width:1600px) {
    .d1-name{
      margin-top: 0.5vw;
    }

    .d1-permission
    {
      margin-top: 0.2vw;
    }

    .d1-line-2
    {
      margin-top: 0.5vw;
    }
  } */

</style>
