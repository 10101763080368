<template>
  <div class="tsc-main">

    <div class="tsc-inner">

      <!-- <el-date-picker v-model="selectData.date" type="date" placeholder="选择日期" :locale="selectData.locale">
      </el-date-picker> -->




      <div class="tsc-title" style="border-left: 0.2vw solid rgba(255,122,29,1);">
        温度曲线
        <el-date-picker class="my-datepicker" v-model="temperatureEventData.date" type="date" placeholder="选择日期"
          :editable="false" :clearable="false" :change="temperatureDateChange()">
        </el-date-picker>
      </div>

      <div class="tsc-chart" style="display: flex;justify-content: center;align-items: center;"
        v-if="temperatureEventData.exist==false">
        <el-empty class="my-empty" description="暂无相关数据"></el-empty>
      </div>

      <div class="tsc-chart" id="temperatureChart" v-if="temperatureEventData.exist==true">

      </div>
    </div>

    <div class="tsc-inner">


      <div class="tsc-title" style="border-left: 0.2vw solid rgba(67,161,230,1);">
        转速曲线
        <el-date-picker class="my-datepicker" v-model="speedEventData.date" type="date" placeholder="选择日期"
          :editable="false" :clearable="false" :change="speedDateChange()">
        </el-date-picker>
      </div>



      <div class="tsc-chart" style="display: flex;justify-content: center;align-items: center;"
        v-if="speedEventData.exist==false">
        <el-empty class="my-empty" description="暂无相关数据"></el-empty>
      </div>

      <div class="tsc-chart" id="speedChart" v-if="speedEventData.exist==true">

      </div>
    </div>

  </div>
</template>

<script>
  import * as echarts from 'echarts';
  import zhCn from 'element-plus/lib/locale/lang/zh-cn'
  // ref方法
  // reactive可传对象数组
  import {
    reactive,
    ref,
    getCurrentInstance,
    onMounted,
  } from 'vue'


  export default {

    name: 'TemperatureSpeedChart',
    props: {
      chartDid: String
    },
    setup(props) {
      const {
        proxy
      } = getCurrentInstance()

      onMounted(() => {

        getEventGraph_temperature()
        getEventGraph_speed()
        // window.addEventListener('resize', function() {
        //   // console.log('window.onresize',window.onresize)
        //   // speed_chart()
        //   // temperature_chart()
        // })
      })

      const selectData = reactive({
        locale: zhCn,
        date: new Date(),
        time: [proxy.$basic.getLessTime(), proxy.$basic.getCurrentTime()],
      })


      // 获取温度图标数据

      const temperatureEventData = reactive({
        exist: true,
        list: [],
        date: new Date(),
        start: null,
        end: null
      })

      // temperatureEventData
      function temperatureDateChange() {
        getEventGraph_temperature()
      }

      function getEventGraph_temperature() {

        var y = temperatureEventData.date.getFullYear().toString()
        var m = (temperatureEventData.date.getMonth() + 1).toString()
        var d = temperatureEventData.date.getDate().toString()

        var start = y + '-' + m + '-' + d + ' ' + '00' + ':' + '00' + ':' + '00';
        var end = y + '-' + m + '-' + d + ' ' + '23' + ':' + '59' + ':' + '59';

        temperatureEventData.start = Date.parse(new Date(start)) / 1000
        temperatureEventData.end = Date.parse(new Date(end)) / 1000


        proxy.$instance.request_private.post('/mqttapi/v2/device/event_graph', {
          did: proxy.$base64.decode(proxy.chartDid),
          emid: 'e7',
          pmid: 'p10',
          start_time: proxy.$basic.conversionTime_ISO8601(new Date(start)),
          end_time: proxy.$basic.conversionTime_ISO8601(new Date(end)),
          simplify: false,
        }).then(res => {
          // console.log('res', res)
          if (res.data.code == 1) {
            let data = proxy.$basic.dataDecode(res.data.data)
            // console.log('data', data)
            if (data.event_list.length == 0) {
              temperatureEventData.exist = false
            } else {
              // console.log('1111', data)
              temperatureEventData.exist = true
              temperatureEventData.list = data.event_list
              // return false
              temperature_chart()
            }
          } else {
            proxy.$message.error('温度图表数据获取失败|' + res.data.code)
          }
        })
      }

      function temperature_chart() {
        // var chartDom = document.getElementById('temperatureChart');
        // var myChart = echarts.init(chartDom);
        var option;

        // var data = list
        var data = JSON.parse(JSON.stringify(temperatureEventData.list))

        // var dateList = data.map(function(item) {
        //   item[0] = Date.parse(item[0])
        //   // $basic.conversionTime(new Date(scope.row.time))
        //   // return proxy.$basic.conversionTime(new Date(item[0]));
        //   return item;
        //   // return item[0];
        // });

        // var dateList = data.map(function(item) {
        //   // $basic.conversionTime(new Date(scope.row.time))
        //   return proxy.$basic.conversionTime(new Date(item[0]));
        //   // return item[0];
        // });

        // var valueList = data.map(function(item) {
        //   return item[1];
        // });

        option = {
          visualMap: [{
            show: false,
            type: 'continuous',
            seriesIndex: 0,
            // min: 0,
            // max: 400
          }],

          xAxis: {
            // min: data[0][0],
            // max: data[data.length - 1][0],

            min: temperatureEventData.start,
            max: temperatureEventData.end,



            // type: 'value',
            axisLabel: {
              formatter: function(value, index) {
                // return proxy.$basic.conversionTime_NotDate(new Date(value))
                return proxy.$basic.conversionTime_NotDate(new Date(parseInt(value) * 1000))
              }
            }

          },
          yAxis: {
            axisLabel: {
              formatter: '{value} °C'
            }
          },
          tooltip: {
            trigger: 'axis',
            // type:'cross'

            formatter: function(params) {
              // console.log('params',params)
              var xRes = proxy.$basic.conversionTime_NotDate(new Date(parseInt(params[0].value[0]) * 1000))
              var yRes = params[0].value[1]
              var listRes = "温度：" + "<span style=\"float: right;font-weight: bold;\">" + yRes + "</span>" + "<br>" +
                "时间：" + xRes
              return listRes
              // var resulet = params[0].value[0]
              // return proxy.$basic.conversionTime_NotDate(new Date(resulet))
            }
          },

          dataZoom: [{
            type: "inside" //详细配置可见echarts官网
          }],

          series: [{
            symbolSize: 0,
            data: data,
            // type: 'scatter',
            type: 'line',
            // showSymbol: false,
          }]
        };

        if (document.getElementById('temperatureChart') == null) {
          return false
        } else {
          var temperatureCharttDom = document.getElementById('temperatureChart');
          var myTemperatureChartt = echarts.init(temperatureCharttDom);
          option && myTemperatureChartt.setOption(option, true);
          window.addEventListener('resize', function() {
            myTemperatureChartt.resize()
            // console.log('window.onresize',window.onresize)
            // speed_chart()
            // temperature_chart()
          })
          // myTemperatureChartt.style.width = '50vw'
          // myTemperatureChartt.style.height = '50vw'
          // window.onresize = myTemperatureChartt.resize;
          // window.onresize;
          // myTemperatureChartt.onresize = myTemperatureChartt.resize;
          // myTemperatureChartt.resize = window.onresize;
        }

      }




      // 获取转速图表数据
      const speedEventData = reactive({
        exist: true,
        list: [],
        date: new Date(),
        start: null,
        end: null,
      })

      function speedDateChange() {
        getEventGraph_speed()
      }

      function getEventGraph_speed() {

        var y = speedEventData.date.getFullYear().toString()
        var m = (speedEventData.date.getMonth() + 1).toString()
        var d = speedEventData.date.getDate().toString()

        var start = y + '-' + m + '-' + d + ' ' + '00' + ':' + '00' + ':' + '00';
        var end = y + '-' + m + '-' + d + ' ' + '23' + ':' + '59' + ':' + '59';

        speedEventData.start = Date.parse(new Date(start)) / 1000
        speedEventData.end = Date.parse(new Date(end)) / 1000


        proxy.$instance.request_private.post('/mqttapi/v2/device/event_graph', {
          did: proxy.$base64.decode(proxy.chartDid),
          emid: 'e7',
          pmid: 'p13',
          start_time: proxy.$basic.conversionTime_ISO8601(new Date(start)),
          end_time: proxy.$basic.conversionTime_ISO8601(new Date(end)),
          simplify: false,
        }).then(res => {
          if (res.data.code == 1) {
            let data = proxy.$basic.dataDecode(res.data.data)
            // console.log('data',data)
            if (data.event_list.length == 0) {
              speedEventData.exist = false
            } else {
              speedEventData.exist = true
              speedEventData.list = data.event_list
              speed_chart()
            }
          } else {
            proxy.$message.error('转速图标数据获取失败|' + res.data.code)
          }
        })
      }

      function speed_chart() {
        // var chartDom = document.getElementById('speedChart');
        // var myChart = echarts.init(chartDom);
        var option;

        // var data = list
        var data = JSON.parse(JSON.stringify(speedEventData.list))

        // var dateList = data.map(function(item) {
        //   item[0] = Date.parse(item[0])
        //   // $basic.conversionTime(new Date(scope.row.time))
        //   // return proxy.$basic.conversionTime(new Date(item[0]));
        //   return item;
        //   // return item[0];
        // });

        var timeList = data.map(function(item) {
          // return item[1];
          return proxy.$basic.conversionTime(new Date(item[0]));
        });

        var valueList = data.map(function(item) {
          return item[1];
        });

        option = {
          // visualMap: [{
          //   show: false,
          //   type: 'continuous',
          //   seriesIndex: 0,
          //   // min: 0,
          //   // max: 400
          // }],

          xAxis: {
            // min: data[0][0],
            // max: data[data.length - 1][0],
            min: speedEventData.start,
            max: speedEventData.end,



            // type: 'value',
            axisLabel: {
              formatter: function(value, index) {
                return proxy.$basic.conversionTime_NotDate(new Date(parseInt(value) * 1000))
              }
            }

          },
          yAxis: {
            axisLabel: {
              formatter: '{value} RPM'
            }
          },
          tooltip: {
            trigger: 'axis',
            // type:'cross'
            formatter: function(params) {
              // console.log('params',params)
              var xRes = proxy.$basic.conversionTime_NotDate(new Date(parseInt(params[0].value[0]) * 1000))
              var yRes = params[0].value[1]
              var listRes = "转速：" + "<span style=\"float: right;font-weight: bold;\">" + yRes + "</span>" + "<br>" +
                "时间：" + xRes
              return listRes
            }
          },
          dataZoom: [{
            type: "inside" //详细配置可见echarts官网
          }],
          series: [{
            symbolSize: 0,
            data: data,
            // type: 'scatter',
            type: 'line',
            // showSymbol: false,
          }]
        };

        if (document.getElementById('speedChart') == null) {
          return false
        } else {
          var chartDom = document.getElementById('speedChart');
          var myChart = echarts.init(chartDom);
          option && myChart.setOption(option, true);
          // window.onresize = myChart.resize;

          window.addEventListener('resize', function() {
            myChart.resize()
            // console.log('window.onresize',window.onresize)
            // speed_chart()
            // temperature_chart()
          })

        }

        // option && myChart.setOption(option);
      }







      return {
        selectData,

        temperatureDateChange,
        temperatureEventData,
        getEventGraph_temperature,
        temperature_chart,

        speedDateChange,
        speedEventData,
        getEventGraph_speed,
        speed_chart,
      };
    }

  }
</script>

<style scoped>
  .tsc-main {
    width: 100%;
    margin-bottom: 1vw;
    /* background-color: rgba(238,125,145,0.8) */
    ;
    display: flex;
    justify-content: space-between;

  }

  .tsc-inner {
    width: 45.5vw;
    height: 23vw;
    background-color: rgba(255, 255, 255, 1);
    border-radius: 0.5vw;
    padding-top: 1vw;
    padding-bottom: 1vw;
    box-shadow: 0 0.1vw 0.6vw 0 rgba(0, 0, 0, 0.1)
  }

  .tsc-title {
    background-color: rgba(255, 255, 255, 1);
    font-size: 1.2vw;
    font-weight: bold;
    line-height: 2vw;
    margin-left: 2vw;
    /* border-left: 0.3vw solid #13CE66; */
    padding-left: 0.5vw;
    padding-right: 4.5vw;
    /* margin-top: 1vw; */
    display: flex;
    align-items: center;
    justify-content: space-between;
  }

  .tsc-chart {
    width: 100%;
    height: 21vw;
    background-color: rgba(255, 255, 255, 1);
  }
</style>
