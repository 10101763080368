<template>
	<div>
		这是关于页面
		<div>
			<button @click="test()">test</button>
		</div>
		<div>
			<button @click="set()">vuexSet</button>
		</div>
		<div>
			<button @click="get()">vuexGet</button>
		</div>
		<div>
			<button @click="setWxerwma()">setWxerwma</button>
			<div id="code"></div>
		</div>
		<div>
			<button @click="routerSetId()">routerSetId</button>
		</div>

		<div>
			<button @click="open()">openDialog</button>
		</div>
    
    

		<el-dialog
		  title="提示"
		  v-model="aboutdata.dialog"
		  width="30%">
		  <span>这是一段信息</span>
		  <template #footer>
		    <span class="dialog-footer">
		      <el-button @click="aboutdata.dialog = false">取 消</el-button>
		      <el-button type="primary" @click="aboutdata.dialog = false">确 定</el-button>
		    </span>
		  </template>
		</el-dialog>

	</div>
</template>

<script setup>
	// ref方法
	// reactive可传对象数组
	import { reactive, ref, getCurrentInstance } from 'vue'
	// router引入


	const { proxy } = getCurrentInstance()

	const aboutdata = reactive({
		url:'',
		dialog:false,


	});

	function open()
	{
		aboutdata.dialog = true
	}

	function test()
	{
		proxy.$instance.get('/api/introduction/getintroduction').then(res=>{
			// console.log('res',res)
		}).catch(err=>{
			// console.log('err',err)
		})
	}

	function set()
	{
		// console.log('11111')
		proxy.$store.commit('handleDemoStatus',111111111)
	}

	function get()
	{
		let str = proxy.$store.getters.demoStatus
		// console.log('vuexGet',str)
	}

	function routerSetId()
	{
		proxy.$router.push({
			path:'/about',
			query:{id:'11111'}
		})
	}

	// ----------------------------------------------------------------------------------------

	function setWxerwma () {
	      const s = document.createElement('script')
	      s.type = 'text/javascript'
	      s.src = 'https://res.wx.qq.com/connect/zh_CN/htmledition/js/wxLogin.js'
	      const wxElement = document.body.appendChild(s)
	      wxElement.onload = function () {
	        var obj = new WxLogin({
	          id: 'code', // 需要显示的容器id
	          // appid: 'wxbdc5610cc59c1631', // 公众号appid wx*******
	          // scope: 'snsapi_login', // 网页默认即可

            appid: 'wxdd8d857472bfe814', // 公众号appid wx*******
            scope: 'snsapi_login', // 网页默认即可

            // redirect_uri: 'https%3A%2F%2Fiot.neodawn.cn%2Fmqttapi%2Fv2%2Fopen', // 授权成功后回调的url
            redirect_uri: encodeURIComponent('https://iot.neodawn.cn'), // 授权成功后回调的url
            response_type:'code',
	          // redirect_uri: encodeURIComponent(''), // 授权成功后回调的url
	          // redirect_uri: 'http%3A%2F%2F10.0.0.101%3A3000%2Fabout', // 授权成功后回调的url
	          // redirect_uri: 'https%3A%2F%2Fpassport.yhd.com%2Fwechat%2Fcallback.do', // 授权成功后回调的url
	          // state: Math.ceil(Math.random() * 1000), // 可设置为简单的随机数加session用来校验
	          state: Math.ceil(Math.random() * 1000), // 可设置为简单的随机数加session用来校验
	          style: 'black', // 提供"black"、"white"可选。二维码的样式
	          href: '' // 外部css文件url，需要https
	        })
	      }
	    }

</script>

<style scoped>
	.view{
		color: crimson;
		background-color: cornflowerblue;
	}
</style>
