const deviceEventListForList = {
  'd1': ['e2', 'e7', 'e9', 'e3'],
  'd2': ['e2', 'e7', 'e9', 'e3'],
  'd3': ['e2', 'e7', 'e9', 'e3'],
}

const deviceEventReturnDefault = {
  'e2': [false, -27080340529],
  'e3': [false, -27080340529],
  'e7': [false, -999, -999, false, -999, -999, false],
  'e8': [false, -27080340529],
  'e9': [-999, -999, -999, false],
}


const deviceEventObjectKey = {
  'e2': ['mqtt_online', 'time_stamp'],
  'e3': ['lock_state', 'lock_timeStamp'],
  'e7': ['device_online', 'current_temperature', 'set_temperature', 'heating_switch', 'current_speed', 'set_speed',
    'speed_switch'
  ],
  'e8': ['device_online', 'time_stamp'],
  'e9': ['lower_temperature_limit', 'upper_temperature_limit', 'shutdown_temperature', 'alarm_switch'],
}


const deviceConfig = {
  deviceEventListForList,
  deviceEventReturnDefault,
  deviceEventObjectKey,
}

export default deviceConfig
