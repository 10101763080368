export default {
	demoStatus: (state) => state.demoStatus,

	jwt: (state) => state.jwt,

	refreshToken: (state) => state.refreshToken,

  mqttDeviceKeyObject:(state)=> state.mqttDeviceKeyObject,

  mqttSubscribeList:(state)=> state.mqttSubscribeList,

	// 基础信息
	ucUserInfo: (state) => state.ucUserInfo,
	ucUserName: (state) => state.ucUserName,
	ucNickName: (state) => state.ucNickName,
	ucUid: (state) => state.ucUid,
	ucPhoneNumber: (state) => state.ucPhoneNumber,
	ucStatus: (state) => state.ucStatus,
	ucAvatar: (state) => state.ucAvatar,
	ucAvatarImg: (state) => state.ucAvatarImg,
	ucEmail: (state) => state.ucEmail,
  ucWxStatus: (state) => state.ucWxStatus,
  ucWxName: (state) => state.ucWxName,
  ucSaStatus: (state) => state.ucSaStatus,



}
