import { createStore } from 'vuex'
import mutations from './mutations'
import state from './state'
import getters from './getters'
import actions from './actions'


export default createStore({
	mutations,
	state,
	getters,
	actions,
	modules:{}
})