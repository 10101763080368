<template>
  <div class="home-main">
    <div class="home-sub">

      <!-- 菜单 -->
      <div class="home-sub-menu">
        <div>
          <img class="home-sub-menu-logo" src="../../assets/image/puclic/logo_square.png" @click="goHome()">
        </div>

        <div class="home-sub-menu-box">

          <div :class="'home-sub-menu-box-item' + ' ' + (homeData.menuCurrentSelection==1?'hsmbi-now':'hsmbi-not')"
            @click="menuChange(1)">
            <div class="home-sub-menu-box-item-inner">
              <div>
                <div class="home-sub-menu-box-item-inner-iconbox">

                  <!-- 迁移为svg图标 -->
                  <el-icon class="home-sub-menu-box-item-inner-icon">
                    <Menu />
                  </el-icon>

                  <!-- <i class="el-icon-menu home-sub-menu-box-item-inner-icon"></i> -->
                </div>
                <div class="home-sub-menu-box-item-inner-value">
                  设备列表
                </div>
              </div>
            </div>
          </div>

          <!-- <div :class="'home-sub-menu-box-item' + ' ' + (homeData.menuCurrentSelection==2?'hsmbi-now':'hsmbi-not')"
            @click="menuChange(2)">
            <div class="home-sub-menu-box-item-inner">
              <div>
                <div class="home-sub-menu-box-item-inner-iconbox">
                  <el-icon class="home-sub-menu-box-item-inner-icon">
                    <Stamp />
                  </el-icon>
                </div>
                <div class="home-sub-menu-box-item-inner-value">
                  权限管理
                </div>
              </div>
            </div>
          </div> -->


          <!-- <div class="home-sub-menu-box-item"></div> -->
        </div>

      </div>

      <!-- 头部 -->
      <div class="home-sub-header">
        <UserInfoHearder :title="homeData.titleValue[homeData.menuCurrentSelection]"></UserInfoHearder>
      </div>

      <!-- 主体 -->
      <div class="home-sub-body">
        <!-- <DeviceListAddInfo></DeviceListAddInfo> -->
        <DeviceListNotInfo v-if="homeData.menuCurrentSelection==1"></DeviceListNotInfo>

      </div>

      <!-- 右侧装饰 -->

      <div class="home-sub-decorate">
      </div>



    </div>
  </div>

</template>

<script setup>
  import {
    Menu,
    Stamp
  } from '@element-plus/icons'

  import UserInfoHearder from '../../components/public_modular/UserInfoHearder.vue'
  import DeviceListAddInfo from '../../components/device_modular/home/DeviceListAddInfo.vue'
  import DeviceListNotInfo from '../../components/device_modular/home/DeviceListNotInfo.vue'

  // ref方法
  // reactive可传对象数组
  import {
    reactive,
    ref,
    getCurrentInstance,
    onMounted,
  } from 'vue'
  // router、接口引入
  const {
    proxy
  } = getCurrentInstance()

  onMounted(()=>{
    // proxy.$basic.getCurrentUserInfo()
  })

  const aboutdata = reactive({
    url: '',
    show: false,
    gshow: false,
    title: 'aaaaaaaaaa',
  });

  function goHome()
  {
    proxy.$router.go(0)
    // proxy.$router.push({
    //   path: '/home',
    // })

  }


  const homeData = reactive({
    menuCurrentSelection: 1,
    titleValue: {
      '1': '设备列表',
      '2': '权限管理'
    }
  })

  function menuChange(index) {
    homeData.menuCurrentSelection = index
  }






  // ----------------------------------------------------------------------------------------
</script>

<style scoped>
  .home-main {
    position: absolute;
    background-color: rgba(240, 242, 251, 1);
    background-image: url(../../assets/image/login/background.png);
    background-size: 100% 100%;
    background-repeat: no-repeat;
    background-position: center;
    width: 100%;
    min-height: 100vh;
  }

  .home-sub {
    background-color: rgba(235, 240, 249, 1);
    box-shadow: 0 0.1vw 0.2vw rgba(0, 0, 0, 0.24), 0 0 0.3vw rgba(0, 0, 0, 0.08);
    position: relative;
    width: 94vw;
    height: 43vw;
    margin-left: 3vw;
    margin-top: 3vw;
    border-radius: 0.9vw;
  }

  .home-sub-menu {
    position: absolute;
    top: 0;
    left: 0;
    background-color: rgba(255, 255, 255, 1);
    width: 6vw;
    height: 43vw;
    border-radius: 0.9vw 0 0 0.9vw;
  }

  .home-sub-menu-logo {
    /* width: 60px; */
    /* height: 60px; */
    width: 3vw;
    height: 3vw;
    margin-left: 1.5vw;
    margin-top: 1.5vw;
    cursor: pointer;
  }

  .home-sub-menu-box {
    margin-top: 2vw;
  }

  .hsmbi-now {
    background-color: rgba(0, 42, 105, 1);
    color: rgba(255, 255, 255, 1);
  }

  .hsmbi-not {
    background-color: rgba(0, 42, 105, 0);
    color: rgba(0, 42, 105, 1);
  }

  .home-sub-menu-box-item {
    position: relative;
    width: 4vw;
    height: 4vw;
    border-radius: 0.5vw;
    /* background-color: #3A8EE6; */
    margin-left: 1vw;
    display: inline-block;
    cursor: pointer;
  }

  .home-sub-menu-box-item:hover {
    box-shadow: 0 0.2vw 0.6vw 0 rgba(0, 0, 0, 0.2)
  }

  .home-sub-menu-box-item+.home-sub-menu-box-item {
    margin-top: 1vw;
  }

  .home-sub-menu-box-item-inner {
    position: relative;
    width: 3vw;
    height: 3vw;
    margin-left: 0.5vw;
    margin-top: 0.5vw;
    /* background-color: #B3E19D; */
    display: flex;
    justify-content: center;
    align-items: center;
  }

  .home-sub-menu-box-item-inner-iconbox {
    text-align: center;
    line-height: 0;
  }

  .home-sub-menu-box-item-inner-icon {
    font-size: 1.2vw;
  }

  .home-sub-menu-box-item-inner-value {
    font-size: 0.7vw;
    text-align: center;
  }


  .home-sub-header {
    position: absolute;
    left: 6vw;
    /* width: 88vw; */
    width: 78vw;
    height: 5vw;
    /* background-color: #000000; */
    /* border-radius: 0 0.9vw 0 0; */
  }

  .home-sub-body {
    position: absolute;
    /* width: 86vw; */
    width: 77vw;
    height: 36vw;
    left: 7vw;
    top: 6vw;
    /* background-color: #53A8FF; */
  }

  .home-sub-decorate {
    position: absolute;
    right: 0;
    height: 43vw;
    width: 9vw;
    background-image: url(../../assets/image/puclic/right.gif);
    background-size: 100% 100%;
    background-repeat: no-repeat;
    background-position: center;
    /* background-color: rgba(28,47,63,1); */
    border-radius: 0.9vw;
  }

  @media screen and (max-width:1600px) {
    /* 右侧直接消失 */
    .home-sub-decorate
    {
      display: none;
    }

    /* 左侧加宽 */
    .home-sub-menu
    {
      width: 8vw;
      /* height: 43vw; */
    }

    .home-sub-menu-logo {
      width: 4vw;
      height: 4vw;
      margin-left: 2vw;
      margin-top: 1.5vw;
    }

    .home-sub-menu-box-item {
      position: relative;
      width: 5vw;
      height: 5vw;
      margin-left: 1.5vw;
    }



    .home-sub-menu-box-item-inner {
      width: 4vw;
      height: 4vw;
      margin-left: 0.5vw;
      margin-top: 0.5vw;
    }

    /* 顶部 */
    .home-sub-header {
      left: 8vw;
      width: 86vw;
      height: 5vw;
      /* background-color: #3A8EE6; */
    }

    .home-sub-body {
      /* position: absolute; */
      /* width: 86vw; */
      width: 86vw;
      height: 36vw;
      left: 8vw;
      top: 6vw;
      /* background-color: #53A8FF; */
    }


  }

</style>
