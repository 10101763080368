<template>
  <div>

    <!-- <div :class="'DLNI-DL' + ' ' + (DLNI_basic_data.show==true?'DLNI-DL-part':'DLNI-DL-all')"
      v-loading="DLNI_basic_data.loading"> -->
    <div :class="'DLNI-DL' + ' ' + 'DLNI-DL-all'"
      v-loading="DLNI_basic_data.loading">

      <div class="DLNI-DL-inner">

        <el-scrollbar class="my-home-list-scrollbar" height="100%">

        <div class="DLNI-DL-search">
          <div style="width: 16vw;line-height: 0;">
            <!-- placeholder="请输入内容" -->
            <el-input v-model.trim="deviceData.search" class="my-search" placeholder="请输入搜索内容" suffix-icon="el-icon-search"> </el-input>
          </div>
          <!-- <div style="line-height: 0;margin-left: 0.5vw;">
            <div class="my-search-btn" @click="getDeviceList()">
              <el-icon class="my-search-btn-icon">
                <search />
              </el-icon>
            </div>
          </div> -->
        </div>







          <div class="DLNI-DL-inner-group" v-for="(gitem,gindex) in groupData.groupList" :key="gindex">
            <!-- top -->
            <div class="DLNI-DL-inner-group-top" @click="gitem.openStatus = !gitem.openStatus">
              <div class="DLNI-DL-inner-group-top-title">
                {{gitem.groupName}}
              </div>

              <div :class="'DLNI-DL-inner-group-top-icon' + ' ' + (gitem.openStatus==false?'':'DLNI-gti-open')">
                <i class="el-icon-caret-left" style="font-size: 1vw;"></i>
              </div>
            </div>

            <!-- list -->

            <el-collapse-transition>
              <div v-show="gitem.openStatus">
                <el-empty class="my-empty" description="暂无相关设备" v-show="gitem.deviceList.length==0"></el-empty>
                <div v-for="(ditem,dindex) in gitem.deviceList" class="DLNI-DL-inner-item" :key="dindex" v-show="gitem.deviceList.length!=0&&ditem.name.indexOf(deviceData.search)!=-1">
                  <div class="DLNI-DL-inner-item-main">
                    <cardD1 :deviceInfo="ditem" @click="goDeviceDetails(ditem)"></cardD1>
                  </div>
                </div>
              </div>
            </el-collapse-transition>

            <!-- foot -->
            <div v-show="gitem.openStatus" style="height: 0.5vw;width: 100%;"></div>

          </div>


          <!-- ******************************************************* -->

          <!-- ******************************************************* -->

        </el-scrollbar>
      </div>


    </div>

    <!-- <transition name="slide-fade">
			<div class="DLNI-DI" v-if="DLNI_basic_data.show"></div>
		</transition> -->

  </div>

</template>





<script setup>
  import cardD1 from '../card/cardD1.vue'

  import {
    Search
  } from '@element-plus/icons'

  // ref方法
  // reactive可传对象数组
  import {
    reactive,
    ref,
    getCurrentInstance,
    onMounted,
  } from 'vue'
  // router、接口引入
  const {
    proxy
  } = getCurrentInstance()

  onMounted(() => {
    getMqttInfo()
  })

  const DLNI_basic_data = reactive({
    url: '',
    gshow: false,
    loading: true,
  });

  function goDeviceDetails(row) {
    proxy.$router.push({
      path: '/device_details',
      query:{
        ZGlk:proxy.$base64.encode(row.did),
        }
    })
  }


  function getMqttInfo() {

    DLNI_basic_data.loading = true

    proxy.$instance.request_private.get('/mqttapi/v2/user/mqtt').then(res => {
      if (res.data.code == 1) {
        proxy.$mqttGlobalData.client = null
        let mqttInfo = proxy.$basic.dataDecode(res.data.data)
        MqttInitSocket(mqttInfo)
      } else {
        proxy.$mqttGlobalData.client = null
        DLNI_basic_data.loading = false
        proxy.$message.error('MQTT信息获取失败')
      }

    })
  }

  function MqttInitSocket(mqttInfo) {
    proxy.$mqttGlobalData.client = proxy.$mqtt.connect(proxy.$mqttGlobalData.mqttUrl, {
      keepalive: 30,
      clientId: mqttInfo.client_id,
      protocolId: 'MQTT',
      protocolVersion: 4,
      username: proxy.$store.getters.ucUid,
      password: mqttInfo.password
    })


    // getGroupList()
    getDeviceList()

  }

  const deviceData = reactive({
    deviceList: [],
    search: '',
  })

  function getDeviceList() {
    DLNI_basic_data.loading = true
    proxy.$instance.request_private.get('/mqttapi/v2/device/device_list').then(res => {
      if (res.data.code == 1) {
        let data = proxy.$basic.dataDecode(res.data.data)
        // 每次获取列表做列表置空
        deviceData.deviceList = []
        let keyObj = {}
        let normalDeviceList = []
        let handlerDataList = []

        // console.log('deviceData.search', deviceData.search)

        // data.device_list.forEach((ditem, dindex) => {
        //   if (ditem.status == 1) {
        //     if (deviceData.search == '') {
        //       normalDeviceList.push(ditem)
        //     } else {
        //       if (ditem.name.indexOf(deviceData.search) != -1) {
        //         normalDeviceList.push(ditem)
        //       }
        //     }
        //   }
        // })

        data.device_list.forEach((ditem, dindex) => {

          if (ditem.status == 1) {
            normalDeviceList.push(ditem)

            // did对应设备名存储
            keyObj[ditem.did] = ditem.name
          }
        })

        // console.log('normalDeviceList', normalDeviceList)

        let devcie_len = normalDeviceList.length

        if (devcie_len == 0) {
          DLNI_basic_data.loading = false
          proxy.$message.error('暂无可用设备')
          // 无设备待处理
          deviceData.deviceList = []
          getGroupList()
        } else {
          normalDeviceList.forEach((nitem, nindex) => {
            proxy.$deviceHandler.handlerForList(nitem.imn.dmid.dmid, nitem.did).then(resHD => {
              let eventList = proxy.$deviceConfig.deviceEventListForList[nitem.imn.dmid.dmid]
              for (let emid_for_res in resHD) {
                for (let emid_for_key in proxy.$deviceConfig.deviceEventObjectKey) {
                  if (emid_for_res === emid_for_key) {
                    proxy.$deviceConfig.deviceEventObjectKey[emid_for_key].forEach((event_key, kindex) => {
                      resHD[emid_for_res].forEach((event_data, eindex) => {
                        if (kindex === eindex) {
                          nitem[event_key] = event_data
                          handlerDataList[nindex] = nitem
                        }
                      })
                    })
                  }
                }
              }
            }).then(() => {
              deviceData.deviceList = []
              if (handlerDataList.length === devcie_len) {
                handlerDataList.forEach(hitem => {
                  deviceData.deviceList.push(hitem)
                })

                // 存储did对应name
                // console.log('AAAAAAAAA',keyObj)
                proxy.$store.commit('setMqttDeviceKeyObject',proxy.$base64.encode(JSON.stringify(keyObj)))

                // **************
                // 紧接下一步
                getGroupList()
                // **************


              }
            })

          })
        }

      } else {
        DLNI_basic_data.loading = false
        proxy.$message.error('仪器列表获取失败|error:' + res.data.code)

      }
    })
  }

  const groupData = reactive({
    groupList: [{
      groupName: '全部',
      openStatus: true,
      deviceList: [],
      id:-1
    }]
  })


  function getGroupList() {
    groupData.groupList = [{
      groupName: '全部',
      openStatus: true,
      deviceList: [],
      id:-1
    }]
    proxy.$instance.request_private.get('/mqttapi/v2/device/lab/group_list').then(res => {
      if (res.data.code == 1) {
        let data = proxy.$basic.dataDecode(res.data.data)
        if (data.lab_group_list.length == 0) {


          if(deviceData.deviceList.length!=0)
          {
            deviceData.deviceList.forEach((ditem, dindex) => {
              groupData.groupList[0].deviceList.push(ditem)
            })
          }

          getMqttPush()

        } else {

          deviceData.deviceList.forEach((ditem1, dindex1) => {
            groupData.groupList[0].deviceList.push(ditem1)
          })


          data.lab_group_list.forEach((lgitem1, lgindex1) => {
            if(groupData.groupList.length-1<data.lab_group_list.length)
            {
              groupData.groupList.push({
                groupName: lgitem1.name,
                openStatus: false,
                deviceList: [],
                id:lgitem1.dlgid
              })
            }

          })


          data.lab_group_list.forEach((lgitem2,lgindex2)=>{
            groupData.groupList.forEach((gitem1,gindex1)=>{
              deviceData.deviceList.forEach((ditem2,dindex2)=>{
                lgitem2.did.forEach((diditem1,didindex1)=>{
                  if(diditem1==ditem2.did&&lgitem2.dlgid==gitem1.id)
                  {
                    gitem1.deviceList.push(ditem2)
                  }
                })
              })
            })
          })

          getMqttPush()

        }

        DLNI_basic_data.loading = false


        // getDeviceList()
      } else {
        // 获取个性化分组出错

        deviceData.deviceList.forEach((ditem, dindex) => {
          groupData.groupList[0].deviceList.push(ditem)
        })

        // groupData.groupList = [{
        //   groupName: '全部',
        //   openStatus: false,
        //   deviceList: []
        // }]

        DLNI_basic_data.loading = false
        proxy.$message.error('个性化分组获取失败|' + res.data.code)

        // getDeviceList()
      }
    })
  }


  function getMqttPush() {

    // 处理一份订阅的list给其他页面共享
    let mqttSubscribeList = []

    // 订阅
    deviceData.deviceList.forEach(ditem => {
      mqttSubscribeList.push(ditem.did)
      proxy.$mqttGlobalData.client.subscribe('v2/' + ditem.did + '/get/#', {
        qos: 1
      }, () => {})
    })

    // 存储订阅list
    proxy.$store.commit('setMqttSubscribeList',proxy.$base64.encode(JSON.stringify(mqttSubscribeList)))



    proxy.$mqttGlobalData.client.on('message', (topic, message, packet) => {
      let result = []
      let result_str = ''
      let result_obj = {}
      // console.log('msg',message)
      message.forEach(msgitem => {
        let asciiCode = parseInt(msgitem, 16)
        let charValue = String.fromCharCode(msgitem)
        result.push(charValue)
      })

      result.forEach(ritem => {
        result_str = result_str + ritem
      })

      result_obj = JSON.parse(result_str)
      let reg = /v2[/\\](.*)[/\\]get/
      let topic_did = topic.match(reg)[1]

      groupData.groupList.forEach((gitem, gindex) => {
        gitem.deviceList.forEach((ditem, dindex) => {
          if (ditem.did === topic_did) {
            for (let emid_for_key in proxy.$deviceConfig.deviceEventObjectKey) {
              if (emid_for_key === result_obj.emid) {
                proxy.$deviceConfig.deviceEventObjectKey[emid_for_key].forEach((key_name, kindex) => {
                  result_obj.arguments
                  result_obj.arguments.forEach((res_data, aindex) => {
                    if (kindex === aindex) {
                      // console.log('res',)
                      // console.log('ditem',ditem)
                      ditem[key_name] = res_data
                    }
                  })
                })
              }
            }
          }
        })
      })



      // console.log('result_obj', result_obj)
      // console.log('topic', topic_did)
      // console.log('dl',groupData.groupList)


    })




  }
</script>

<style scoped>
  .DLNI-gti-open {
    transform: rotate(-90deg);
  }


  .slide-fade-enter-active {
    transition: all 0.3s ease-out;
  }

  .slide-fade-leave-active {
    transition: all 0.3s cubic-bezier(1, 0.5, 0.8, 1);
  }

  .slide-fade-enter-from,
  .slide-fade-leave-to {
    transform: translateX(1vw);
    opacity: 0;
  }


  .DLNI-DL-all {
    width: 77vw;
    /* width: 86vw; */
    transition: all 0.3s cubic-bezier(1, 0.5, 0.8, 1);
  }

  .DLNI-DL-part {
    width: 58vw;
    transition: all 0.3s ease-out;
  }

  .DLNI-DL {
    background-color: rgba(225, 228, 235, 1);
    position: absolute;
    /* top: 6vw; */
    /* left: 7vw; */
    height: 36vw;
    border-radius: 0.5vw;
    /* transition: all 0.3s cubic-bezier(1, 0.5, 0.8, 1); */
    /* transition: all 0.3s; */
    /* overflow: auto; */
  }

  .DLNI-DL-inner {
    position: absolute;
    top: 1vw;
    left: 1vw;
    width: calc(100% - 2vw);
    height: calc(100% - 2vw);
    /* background-color: #DD6161; */
    transition: all 0.3s cubic-bezier(1, 0.5, 0.8, 1);
    /* overflow: auto; */
  }

  .DLNI-DL-search{
    display: flex;
    margin-bottom: 1vw;
  }

  .DLNI-DL-inner-group {
    /* background-color: #3A8EE6; */
    background-color: rgba(255, 255, 255, 1);
    border-radius: 0.3vw;
  }

  .DLNI-DL-inner-group+.DLNI-DL-inner-group {
    margin-top: 1vw;
  }

  .DLNI-DL-inner-group-top {
    /* background-color: #00FF00; */
    height: 2vw;
    width: calc(100% - 1vw);
    margin-left: 0.5vw;
    display: flex;
    align-items: center;
  }

  .DLNI-DL-inner-group-top-title {
    font-size: 0.9vw;
    font-weight: bold;
  }


  .DLNI-DL-inner-group-top-icon {
    transition: all 0.3s;
    margin-left: 0.5vw;
    line-height: 0vw;
  }

  .DLNI-DL-inner-item {
    position: relative;
    display: inline-block;
    width: 12.5vw;
    height: 12.5vw;
    /* background-color: #82848A; */

  }

  .DLNI-DL-inner-item:nth-child(2n) {
    /* background-color: #409EFF; */
  }

  .DLNI-DL-inner-item-main {
    position: absolute;
    top: 0.7vw;
    left: 0.7vw;
    width: calc(100% - 1.4vw);
    height: calc(100% - 1.4vw);

    /* background-color: #67C23A; */
    ;
  }


  .DLNI-DI {
    background-color: rgba(225, 228, 235, 1);
    position: absolute;

    /* top: 6vw; */
    left: 59vw;

    width: 27vw;
    height: 36vw;
    border-radius: 0.5vw;
  }


  @media screen and (max-width:1600px) {
    /* 外框 */
    .DLNI-DL-all {
      width: 86vw;
    }
    
    /* 卡片外框 */
    .DLNI-DL-inner-item {
      width: 14vw;
      height: 14vw;
      /* background-color: #82848A; */

    }

  }

</style>
