<template>
  <div v-loading="wxBinDingData.loading" style="width: 100vw;height: 100vh;">

  </div>

</template>

<script setup>
  // ref方法
  // reactive可传对象数组
  import {
    reactive,
    ref,
    getCurrentInstance,
    onMounted,
  } from 'vue'
  // router、接口引入
  const {
    proxy
  } = getCurrentInstance()

  onMounted(() => {
    wxBinDing()
  })

  const wxBinDingData = reactive({
    url: '',
    loading: true
  });

  function wxBinDing() {
    wxBinDingData.loading = true
    proxy.$instance.request_private.post('/mqttapi/v2/user/wechat/add_wechat_web', {
      code: proxy.$route.query.code
    }).then(res => {
      if (res.data.code == 1) {
        getWxStatus()
        getWxSaStatus()
        wxBinDingData.loading = false
        proxy.$message.success('绑定成功')
        // alert('绑定成功')

        // proxy.$alert('提示', '微信绑定成功', {
        //   type:'success',
        //   confirmButtonText: '确定',
        //   callback: action => {
        //     proxy.$router.go(-1)
        //   }
        // });

        setTimeout(() => {
          proxy.$router.go(-1)
        }, 1000)


        // proxy.$router.replace({
        //   path: '/personal_center',
        // })
      } else {
        getWxStatus()
        getWxSaStatus()
        wxBinDingData.loading = false
        // proxy.$message.error('绑定失败')

        // alert('绑定失败')

        proxy.$alert(proxy.$errorCode[res.data.code],'提示', {
          type: 'error',
          confirmButtonText: '确定',
          callback: action => {
            proxy.$router.go(-1)
          }
        });

        // setTimeout(()=>{
        // },1500)

        // proxy.$router.go(-1)

        // proxy.$router.replace({
        //   path: '/personal_center',
        // })
      }
    })
  }


  function getWxStatus() {
    proxy.$instance.request_private.get('/mqttapi/v2/user/wechat/check_wechat').then(res => {
      if (res.data.code == 1) {
        let data = proxy.$basic.dataDecode(res.data.data)
        proxy.$store.commit('setUcWxName',data.wechat_username)
        proxy.$store.commit('setUcWxStatus', 1)
      } else {
        proxy.$store.commit('setUcWxName',null)
        proxy.$store.commit('setUcWxStatus', 0)
        // proxy.$message.error('微信绑定状态获取失败|' + res.data.code)
      }
    })
  }

  function getWxSaStatus() {
    // 公众号状态
    // 0:'未绑定微信'
    // 1:'已绑定微信未关注公众号'
    // 2:'已绑定微信并关注公众号'
    proxy.$instance.request_private.get('/mqttapi/v2/user/wechat/check_wechat_sa').then(res => {
      if (res.data.code == 1) {
        proxy.$store.commit('setUcSaStatus', 2)
      } else if (res.data.code == 6240) {
        proxy.$store.commit('setUcSaStatus', 0)
      } else if (res.data.code == 6250) {
        proxy.$store.commit('setUcSaStatus', 1)
      }
    })
  }


  // ----------------------------------------------------------------------------------------
</script>

<style scoped>

</style>
