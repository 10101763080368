<template>
	<div class="dd-main">
		<div class="public_background"></div>
		<div class="dd-sub">

			<!-- 头部 -->
			<div class="dd-sub-header">
				<UserInfoHearder title="设备详情"></UserInfoHearder>
			</div>

			<div class="dd-sub-body">
        <DetailsD1></DetailsD1>
				<!-- <div class="dd-sub-body-inner">



				</div> -->
			</div>

		</div>





	</div>

</template>

<script setup>
	import UserInfoHearder from '../../components/public_modular/UserInfoHearder.vue'
	import DetailsD1 from '../../components/device_modular/details/DetailsD1.vue'
	// import VuePictureCropper, { cropper } from 'vue-picture-cropper'
	// import { VuePictureCropper, cropper } from 'vue-picture-cropper'


	// ref方法
	// reactive可传对象数组
	import {
		reactive,
		ref,
		getCurrentInstance,
		onMounted,
		watch,
	} from 'vue'



	// router、接口引入
	const {
		proxy
	} = getCurrentInstance()


	onMounted(() => {
    // getDeviceInfo()
		// safetyDetectionGeetestInitialize()

		// console.log('av',proxy.$basic.hideUcPhoneNumber(),null)
	})





</script>

<style scoped>
	.dd-main {
		position: absolute;
		/* background-color: rgba(240, 242, 251, 1); */
		/* background-image: url(../../assets/image/login/background.png); */
		background-size: 100% 100%;
		background-repeat: no-repeat;
		background-position: center;
		width: 100%;
		min-height: 100vh;
	}

	.dd-sub {
    background-color: rgba(235, 240, 249, 1);
    box-shadow: 0 0.1vw 0.2vw rgba(0, 0, 0, 0.24), 0 0 0.3vw rgba(0, 0, 0, 0.08);
		/* background-color: #DC143C; */
		position: relative;
		width: 94vw;
		height: auto;
		/* height: 43vw; */
		min-height: 43vw;
		margin-left: 3vw;
		margin-top: 3vw;
		padding-bottom: 1vw;
		border-radius: 0.9vw;
	}

	.dd-sub-header {
		position: relative;
		/* left: 6vw; */
		/* width: 88vw; */
		width: 94vw;
		height: 5vw;
		/* background-color: #333333; */
		/* border-radius: 0 0.9vw 0 0; */
	}

	.dd-sub-body {
		position: relative;
		width: 92vw;
		/* height: 36vw; */
		min-height: 37vw;
		margin-left: 1vw;
		margin-top: 1vw;
		/* left: 1vw; */
		/* top: 6vw; */
		/* background-color: #53A8FF; */
    /* background-color: rgba(125,228,235,1); */
    /* display: flex;
    justify-content: center;
    align-items: center; */
    /* border-radius: 0.5vw; */

	}

  .dd-sub-body-inner {
    width: 90vw;
    /* height: calc(100% - 2vw); */
    min-height: 35vw;
    background-color: rgba(255,255,255,1);
    border-radius: 0.5vw;
  }


</style>
