export default {
	demoStatus: '' || localStorage.getItem('demoStatus'),

	jwt: null || localStorage.getItem('jwt'),

	refreshToken: null || localStorage.getItem('refreshToken'),
  
  mqttDeviceKeyObject:null || localStorage.getItem('mqttDeviceKeyObject'),
  
  mqttSubscribeList:null || localStorage.getItem('mqttSubscribeList'),

	// 用户基础信息

	ucUserName: null || JSON.parse(localStorage.getItem('ucUserName')),
	ucNickName: null || JSON.parse(localStorage.getItem('ucNickName')),
	ucUid: null || JSON.parse(localStorage.getItem('ucUid')),
	ucPhoneNumber: null || JSON.parse(localStorage.getItem('ucPhoneNumber')),
	ucStatus: null || JSON.parse(localStorage.getItem('ucStatus')),
	ucAvatar: null || JSON.parse(localStorage.getItem('ucAvatar')),
	ucAvatarImg: null || JSON.parse(localStorage.getItem('ucAvatarImg')),
	ucEmail: null || JSON.parse(localStorage.getItem('ucEmail')),
  ucWxStatus: null || JSON.parse(localStorage.getItem('ucWxStatus')),
  ucWxName: null || JSON.parse(localStorage.getItem('ucWxName')),
  ucSaStatus: null || JSON.parse(localStorage.getItem('ucSaStatus')),


}
