<template>
  <div class="PUIH">
    <div class="PUIH-left">
      <div class="PUIH-back" v-show="headerData.path.indexOf('/home')==-1">
        <div class="PUIH-back-btn" @click="goBack()">
          <i class="el-icon-back PUIH-back-icon"></i>
        </div>
      </div>
      <!-- <div class="PUIH-separate"></div> -->
      <div class="PUIH-title">{{title}}</div>

    </div>

    <div class="PUIH-rightbox">

      <!-- 消息中心 -->
      <el-dropdown class="my-PUIH-dropdown">
        <div class="PUIH-message-icon" v-show="headerData.path.indexOf('/message_center')==-1">
          <!-- <i class="el-icon-chat-dot-square" style="font-size: 1.6vw;"></i> -->
          <el-icon style="font-size: 1.6vw;color: rgba(182,185,191,1);" @click="headerGoMessageCenter()">
            <Comment />
          </el-icon>
        </div>

        <template #dropdown>
          <el-dropdown-menu>
            <el-dropdown-item>
              <div class="PUIH-message-card" v-show="headerMessageData.list.length==0">
                暂无新消息
              </div>
              <div v-show="headerMessageData.list.length!=0" class="PUIH-message-card"
                v-for="(item,index) in headerMessageData.list" :key="index">
                <div>{{item.content}}</div>
                <div>{{ $basic.conversionTime(new Date(item.time)) }}</div>
              </div>
            </el-dropdown-item>
            <el-dropdown-item @click="headerGoMessageCenter()">
              <div class="PUIH-message-goall">
                查看全部消息
              </div>
              <!-- <div style="display: flex;align-items: center;text-align: center;">
                <div style="display: inline-block;">
                  查看全部消息
                </div>
              </div> -->
            </el-dropdown-item>
          </el-dropdown-menu>
        </template>

      </el-dropdown>


      <!-- 头像部分 -->
      <el-dropdown class="my-PUIH-dropdown">

        <div style="display: flex;align-items: center;">
          <el-avatar class="my-PUIH-avatar" v-if="$store.getters.ucAvatar==null" :shape="$basic.config_js.avatarStyle">
            {{$store.getters.ucNickName!=null?$store.getters.ucNickName.substr(0, 1):''}}
          </el-avatar>
          <el-avatar class="my-PUIH-avatar" v-if="$store.getters.ucAvatar!=null" :shape="$basic.config_js.avatarStyle"
            :src="$store.getters.ucAvatar"></el-avatar>
          <div style="line-height: 0;margin-left: 0.2vw;">
            <i class="el-icon-caret-bottom" style="font-size: 1vw;"></i>
          </div>
        </div>


        <template #dropdown>
          <el-dropdown-menu>
            <el-dropdown-item @click="headerGoHome" v-show="headerData.path.indexOf('/home')==-1">
              <div style="display: flex;align-items: center;">
                <el-icon class="my-dropdown-menu-icon" style="margin-right: 0.5vw;line-height: 0;">
                  <House />
                </el-icon>
                回到首页
              </div>
            </el-dropdown-item>
            <el-dropdown-item @click="headerGoPersonalCenter" v-show="headerData.path.indexOf('/personal_center')==-1">
              <div style="display: flex;align-items: center;">
                <el-icon class="my-dropdown-menu-icon" style="margin-right: 0.5vw;line-height: 0;">
                  <User />
                </el-icon>
                个人中心
              </div>
            </el-dropdown-item>
            <el-dropdown-item @click="$basic.loginOut()">
              <div style="display: flex;align-items: center;">
                <el-icon class="my-dropdown-menu-icon" style="margin-right: 0.5vw;line-height: 0;">
                  <SwitchButton />
                </el-icon>
                退出登录
              </div>
            </el-dropdown-item>
          </el-dropdown-menu>
        </template>
      </el-dropdown>
    </div>
  </div>
</template>

<script>
  import {
    ChatDotSquare,
    User,
    SwitchButton,
    ChatDotRound,
    Comment,
    House,
  } from '@element-plus/icons'
  // ref方法
  // reactive可传对象数组
  import {
    reactive,
    ref,
    getCurrentInstance,
    onMounted,
  } from 'vue'

  // const { proxy } = getCurrentInstance()
  // router、接口引入


  export default {

    name: 'UserInfoHearderHaveBack',
    props: {
      title: String
    },
    components: {
      House,
      Comment,
      User,
      SwitchButton,
    },
    setup(props) {
      const {
        proxy
      } = getCurrentInstance()

      onMounted(() => {
        hearderGetUserInfo()
        headerGetMessageList()
      })

      const headerData = reactive({
        path: proxy.$route.path,
      })

      const headerMessageData = reactive({
        list: [],
        offset: 0,
        getCount: 4,
        type: 'all',
        is_read: 'all',
      })

      function hearderGetUserInfo()
      {
        if (proxy.$basic.isVuexHava(proxy.$store.getters.jwt)) {
          proxy.$basic.getCurrentUserInfo()
        }
      }

      function headerGetMessageList() {
        if (proxy.$basic.isVuexHava(proxy.$store.getters.jwt)) {
          proxy.$instance.request_private.get('/mqttapi/v2/user/message/message_list', {
            params: {
              type: headerMessageData.type,
              offset: headerMessageData.offset,
              count: headerMessageData.getCount,
              is_read: headerMessageData.is_read,
            }
          }).then(res => {
            if (res.data.code == 1) {
              let data = proxy.$basic.dataDecode(res.data.data)
              // console.log('hmd',data)
              headerMessageData.list = data.message_list
              setTimeout(() => {
                headerGetMessageList()
              }, 60000)
            } else {

            }
          })
        }

      }

      // 跳转部分
      function goBack() {
        proxy.$router.go(-1)
      }

      function headerGoHome() {
        proxy.$router.push({
          path: '/home',
        })
      }

      function headerGoLogin() {
        proxy.$router.push({
          path: '/login',
        })
      }

      function headerGoPersonalCenter() {
        proxy.$router.push({
          path: '/personal_center',
        })
      }

      function headerGoMessageCenter() {
        proxy.$router.push({
          path: '/message_center',
        })
      }


      return {
        // aboutdata,
        headerData,
        headerMessageData,
        goBack,
        headerGoHome,
        headerGoLogin,
        headerGoPersonalCenter,
        headerGoMessageCenter,
      };
    }

  }
</script>

<style scoped>
  .PUIH {
    position: relative;
    width: calc(100% - 2vw);
    margin-left: 1vw;
    height: 100%;
    /* background-color: #13CE66; */
    display: flex;
    justify-content: space-between;
    align-items: center;
  }

  .PUIH-left {
    display: flex;
    align-items: center;
  }

  .PUIH-back {
    /* background-color: #15A8D6; */
    padding-right: 0.8vw;
    /* margin-right: 0vw; */
    border-right: 0.2vw solid rgba(0, 42, 105, 1);
  }

  .PUIH-back-btn {
    width: 1.2vw;
    height: 1.2vw;
    display: flex;
    justify-content: center;
    align-items: center;
    border: 0.2vw solid rgba(0, 42, 105, 1);
    border-radius: 50%;
    color: rgba(0, 42, 105, 1);
    cursor: pointer;
  }

  .PUIH-back-btn:hover {
    background-color: rgba(0, 42, 105, 1);
    color: #FFFFFF;
  }

  .PUIH-back-icon {
    font-size: 1.2vw;
    font-weight: bold;
    line-height: 0;
  }


  .PUIH-title {
    margin-left: 0.8vw;
    font-size: 1.2vw;
    font-weight: bold;
    line-height: 1.2vw;
    height: 1.2vw;
    color: rgba(0, 42, 105, 1);
    /* border: 0.2vw solid #000000; */

  }

  .PUIH-rightbox {
    display: flex;
    align-items: center;
  }

  .PUIH-message-icon {
    display: flex;
    align-items: center;
    margin-right: 1vw;
    padding-top: 0.4vw;
  }

  .PUIH-message-card {
    background-color: rgba(248, 248, 248, 1);
    padding-left: 5%;
    padding-right: 5%;
    margin-bottom: 5%;
    box-shadow: 0 0.1vw 0.6vw 0 rgba(0, 0, 0, 0.2);
    cursor: default;
    color: #000000;
  }

  .PUIH-message-goall {
    text-align: center;
    color: #409EFF;
  }
</style>
