import { createRouter, createWebHistory } from 'vue-router'
import Test from '../views/Test02.vue'
import Home from '../views/device_modular/Home.vue'
import About from '../views/About.vue'
import Login from '../views/login_modular/Login.vue'
import WxLogin from '../views/login_modular/WxLogin.vue'
import Register from '../views/login_modular/Register.vue'
import Forget from '../views/login_modular/Forget.vue'
import PersonalCenter from '../views/user_modular/PersonalCenter.vue'
import WxBinDing from '../views/user_modular/WxBinDing.vue'
import MessageCenter from '../views/user_modular/MessageCenter.vue'
import DeviceDetails from '../views/device_modular/DeviceDetails.vue'

const routerPush = createRouter.prototype.push
createRouter.prototype.push = function push(location){
	return routerPush.call(this,location).catch(error => error)
}

const router = createRouter({
	history:createWebHistory(),
	routes:[
    {
    	path:'/device_details',
    	name:'DeviceDetails',
    	component:DeviceDetails,
    },
		{
			path:'/personal_center',
			name:'PersonalCenter',
			component:PersonalCenter,
		},
    {
    	path:'/wxbinding',
    	name:'WxBinDing',
    	component:WxBinDing,
    },
    
    
    
    {
    	path:'/message_center',
    	name:'MessageCenter',
    	component:MessageCenter,
    },
		// {
		// 	path:'/test',
		// 	name:'Test',
		// 	component:Test,
		// },
		{
			path:'/login',
			name:'Login',
			component:Login,
		},
    {
    	path:'/wxlogin',
    	name:'WxLogin',
    	component:WxLogin,
    },
		{
			path:'/register',
			name:'Register',
			component:Register,
		},
		{
			path:'/forget',
			name:'Forget',
			component:Forget,
		},
		{
			// router4.0必须使用带有自定义正则表达式的参数进行定义
			// 捕获所有路由请使用:/catchAll(.*)
			path:'/',
			redirect:'/home'
		},
		{
			path:'/home',
			name:'Home',
			component:Home,
		},
		// {
		// 	path:'/about',
		// 	name:'About',
		// 	component:About,
		// }
	]
})

export default router
