const errorCode = {

	'-1':'Action_failed',
	'0':'Action_pending',
	'1':'Action_success',
	'1010':'refreshToken无效|Error:1010',
	'1020':'refreshToken到期|Error:1020',
	'2000':'报警规则不存在|Error:2000',//Alarm_rule_not_existed
	'3000':'日志项无效|Error:3000',//Log_entry_invalid
	'4000':'设备无效|Error:4000',//Device_invalid


  '6000':'无效用户|Error:6000',//User_invalid
	'6010':'用户密码错误|Error:6010',//User_password_mismatch
	'6020':'用户未激活|Error:6020',//User_inactive
	'6030':'用户被禁止|Error:6030',//User_banned
	'6040':'用户密码无效|Error:6040',//User_password_invalid
	'6050':'用户操作错误|Error:6050',//User_bad_operation
	'6060':'邮箱已被绑定|Error:6060',//User_mail_existed
	'6070':'手机号已被使用|Error:6070',//User_phone_number_existed
	'6080':'用户名已被使用|Error:6080',//User_name_existed
	'6090':'手机号无效|Error:6090',//User_phone_number_invalid
	'6100':'用户名无效|Error:6100',//User_username_invalid
	'6110':'头像无效|Error:6110',//User_avatar_invalid
	'6120':'邮箱无效|Error:6120',//User_email_invalid
	'6130':'昵称无效|Error:6130',//User_nickname_invalid
	'6140':'手机号不匹配|Error:6140',//User_phone_number_mismatch
	'6150':'验证码错误|Error:6150',//User_phone_number_already_verified
	'6160':'验证码错误|Error:6160',//User_phone_number_verify_failed
	'6170':'用户被禁止|Error:6170',//User_forbidden
	'6180':'用户消息无效|Error:6180',//User_message_invalid
	'6200':'用户微信账号无效|Error:6200',//User_wechat_account_invalid
	'6210':'用户账号已被绑定了微信|Error:6210',//User_wechat_account_existed
	'6220':'用户微信服务器错误|Error:6220',//User_wechat_server_error
	'6230':'微信账号已绑定了用户|Error:6230',//User_wechat_account_already_linked
	'6240':'微信未绑定账号|Error:6240',//User_wechat_account_not_found
	'6250':'该用户微信没有关注公众号|Error:6250',//User_wechat_sa_account_not_found




	'7000':'请求验证码过于频繁，请稍后再试|Error:7000',//SMS_already_sent用户在限定时间内已经请求过了短信验证码
	'7010':'验证码错误|Error:7010',//SMS_code_invalid
	'7020':'获取验证码失败|Error:7020',//SMS_api_failed
	'7030':'短信类型不支持|Error:7030',//SMS_type_not_support
  '7040':'旧手机验证失效,请返回上一步|Error:7040',//SMS_token_invalid
	'7500':'请求过于频繁，请稍后再试|Error:7500',//Mail_already_sent
	'7510':'邮件验证码错误|Error:7510',//Mail_code_invalid
	'7520':'邮件接口出错|Error:7520',//Mail_api_failed
	'7900':'机器验证失败|Error:7900',//Captcha_invalid
	'7910':'机器验证服务失效|Error:7910',//Captcha_failed


	'8000':'设备无效|Error:8000',//Device_invalid
	'8010':'设备凭证无效|Error:8010',//Device_token_invalid
	'8020':'设备凭证不匹配|Error:8020',//Device_token_mismatch
	'8030':'设备D模型无效|Error:8030',//Device_dmn_invalid
	'8040':'设备I模型无效|Error:8040',//Device_imn_invalid
	'8050':'设备不存在|Error:8050',//Device_did_existed
	'8060':'设备无效|Error:8060',//Device_did_invalid
	'8070':'设备名无效|Error:8070',//Device_name_invalid
	'8080':'设备操作无效|Error:8080',//Device_amid_invalid
	'8090':'设备操作不正确|Error:8090',//Device_amid_improper
	'8100':'设备操作无效|Error:8100',//Device_amid_smid_mismatch
	'8110':'设备操作无效|Error:8110',//Device_pmid_invalid
	'8120':'设备参数长度错误|Error:8120',//Device_arguments_len_invalid
	'8130':'设备参数无效|Error:8130',//Device_arguments_invalid
	'8140':'设备组名称已存在|Error:8140',//Device_group_name_exists
	'8150':'设备组无效|Error:8150',//Device_group_invalid
	'8160':'设备不为空|Error:8160',//Device_group_no_empty
	'8170':'设备已在设备组|Error:8170',//Device_in_group
	'8180':'设备不在设备组|Error:8180',//Device_no_in_group
	'8190':'设备组名称无效|Error:8190',//Device_group_name_invalid
	'8200':'用户已存在设备组|Error:8200',//Device_group_user_existed
	'8210':'设备组用户无效|Error:8210',//Device_group_user_invalid
	'8220':'设备组用户为管理员|Error:8220',//Device_group_user_is_admin
	'8230':'设备炒作发送失败|Error:8230',//Device_action_send_failed
	'8240':'设备事件无效|Error:8240',//Device_emid_invalid
	'8250':'设备模型无效|Error:8250',//Device_dmid_invalid
	'8260':'设备状态无效|Error:8260',//Device_status_invalid
	'8270':'仪器和设备模型不匹配|Error:8270',//Device_dmn_imn_mismatch
	'8280':'设备实验室组名称无效|Error:8280',//Device_lab_group_name_invalid
	'8290':'设备实验室组无效|Error:8290',//Device_lab_group_dlgid_invalid
	'8300':'设备已存在|Error:8300',//Device_existed
	'8310':'这不是一个设备组|Error:8310',//Device_group_not_group
	'8320':'用户是设备所有者无法操作|Error:8320',//Device_group_user_itself
	'8321':'设备事件无通知|Error:8321',//Device_event_no_notification


  '9000':'实验操作列表无效|Error:9000',//Experiment_operation_list_invalid
  '9010':'实验TCP服务错误|Error:9010',//Experiment_tcp_server_error
  '9020':'实验无效|Error:9020',//Experiment_invalid
  '9030':'实验权限无效|Error:9030',//Experiment_permission_invalid
  '9040':'实验状态无效|Error:9040',//Experiment_status_invalid

}

export default errorCode
