export default {
	handleDemoStatus: (state, demoStatus) => {
		state.demoStatus = demoStatus;
		localStorage.setItem('demoStatus', state.demoStatus)
	},

	setJwt: (state, jwt) => {

		state.jwt = (jwt == null ? jwt : 'Bearer' + ' ' + jwt);
		localStorage.setItem('jwt', state.jwt)
	},

	setRefreshToken: (state, refreshToken) => {
		state.refreshToken = refreshToken;
		localStorage.setItem('refreshToken', state.refreshToken)
	},

  setMqttDeviceKeyObject: (state, mqttDeviceKeyObject) => {
  	state.mqttDeviceKeyObject = mqttDeviceKeyObject;
  	localStorage.setItem('mqttDeviceKeyObject', JSON.stringify(state.mqttDeviceKeyObject))
  },

  setMqttSubscribeList: (state, mqttSubscribeList) => {
  	state.mqttSubscribeList = mqttSubscribeList;
  	localStorage.setItem('mqttSubscribeList', JSON.stringify(state.mqttSubscribeList))
  },

	// 用户基础信息
	setUcUserName: (state, ucUserName) => {
		state.ucUserName = ucUserName;
		localStorage.setItem('ucUserName', JSON.stringify(state.ucUserName))
	},
	setUcNickName: (state, ucNickName) => {
		state.ucNickName = ucNickName;
		localStorage.setItem('ucNickName', JSON.stringify(state.ucNickName))
	},
	setUcUid: (state, ucUid) => {
		state.ucUid = ucUid;
		localStorage.setItem('ucUid', JSON.stringify(state.ucUid))
	},
	setUcPhoneNumber: (state, ucPhoneNumber) => {
		state.ucPhoneNumber = ucPhoneNumber;
		localStorage.setItem('ucPhoneNumber', JSON.stringify(state.ucPhoneNumber))
	},
	setUcStatus: (state, ucStatus) => {
		state.ucStatus = ucStatus;
		localStorage.setItem('ucStatus', JSON.stringify(state.ucStatus))
	},
	setUcAvatar: (state, ucAvatar) => {
		// state.ucAvatar = 'http://'+ucAvatar;
		state.ucAvatar = ucAvatar;
    // if(ucAvatar.indexOf('http')==-1)
    // {
    //   state.ucAvatar = 'http://' + ucAvatar;
    // }
    // else
    // {
    //   state.ucAvatar = ucAvatar;
    // }
		localStorage.setItem('ucAvatar', JSON.stringify(state.ucAvatar))
	},
	setUcAvatarImg: (state, ucAvatarImg) => {
		state.ucAvatarImg = ucAvatarImg;
		localStorage.setItem('ucAvatarImg', JSON.stringify(state.ucAvatarImg))
	},
	setUcEmail: (state, ucEmail) => {
		state.ucEmail = ucEmail;
		localStorage.setItem('ucEmail', JSON.stringify(state.ucEmail))
	},
  setUcWxStatus: (state, ucWxStatus) => {
  	state.ucWxStatus = ucWxStatus;
  	localStorage.setItem('ucWxStatus', JSON.stringify(state.ucWxStatus))
  },
  setUcWxName: (state, ucWxName) => {
  	state.ucWxName = ucWxName;
  	localStorage.setItem('ucWxName', JSON.stringify(state.ucWxName))
  },
  setUcSaStatus: (state, ucSaStatus) => {
  	state.ucSaStatus = ucSaStatus;
  	localStorage.setItem('ucSaStatus', JSON.stringify(state.ucSaStatus))
  },



}
